import React, { useEffect, useState } from 'react';
import missionPageBackground from '../../images/missionDashboardBackground.webm'
import missionPageBackgroundMobile from '../../images/missionDashboardBackgroundMobile.webm'
import missionPageBackgroundMobileMp4 from '../../images/missionDashboardBackgroundMobile.mp4'
import WalletNotConnectedIcon from '../../images/svg/walletNotConnected.svg';
//import missionPageBackgroundImage from '../../images/ClaimsPageBackground.png'
import useMobileDetect from 'use-mobile-detect-hook';
import moment from 'moment';
import { Program, AnchorProvider, web3, BN, utils } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { claimsProgramAdd } from '../../constants/constants';
import claimsIdl from '../../web3/idl/claims.json';

import "./ClaimsPage.scss";
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import ClaimCard from '../../Components/ClaimCard/ClaimCard';
import { connectWalletText, connectWalletTitle } from '../Vault/Vault';
import InfoCard from '../../Components/InfoCard/InfoCard';
import TrancheBox from '../../Components/TrancheBox/TrancheBox';
import { errorToast, successToast } from '../../Helpers/toastCSS';
import * as helpers from '../../Helpers/helper';
import { Buffer } from 'buffer';


const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,

  },
  out: {
    opacity: 0,
  },
}

function ClaimsPage() {

  const detectMobile = useMobileDetect();
  const { connection } = useConnection();
  const wallet = useWallet();

  const [claimRow, setClaimRow] = useState();
  const [claimRowDescription, setClaimRowDescription] = useState('No Description Available');
  const [isLoading, setIsLoading] = useState(true);
  const [claims, setClaims] = useState([]);
  const [claimInfo, setClaimInfo] = useState([]);

  const completedIDOs = useSelector((state) => state.idoData.completedIDOs);

  const { SystemProgram } = web3;
  const [provider, setProvider] = useState(null);
  const claimsProgramID = new web3.PublicKey(claimsProgramAdd);

  useEffect(() => {
    if (claimRow) {
        setClaimRowDescription(claimRow.description);
    }
  }, [claimRow])

  useEffect(() => {
    // if (wallet.publicKey) {  
    setProvider(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()));
    // }
  }, [wallet?.connected]);

  useEffect(() => {
    if (provider) {
      const API_URL = helpers.getApiUrl();
      fetch(API_URL + "/claims/", {
        "method": "POST",
        "headers": { "content-type": "application/json", "accept": "application/json" },
        "body": JSON.stringify({ wallet: provider.wallet.publicKey })
      }).then(res => res.json())
        .then((result) => {
          setClaims(result.data.claims);
        });
    }
  }, [provider]);

  useEffect(() => {
    if (claims.length > 0) {
      getAccountData();
    }
  }, [claims])

  const toBytes32Array = (b) => {
    const buf = Buffer.alloc(32);
    b.copy(buf, 32 - b.length);
    return Array.from(buf);
  };

  async function getAccountData() {
    const claimsProgram = new Program(claimsIdl, claimsProgramID, provider);
    // const mainClaimsNow = await claimsProgram.account.mainData.fetch(accts.mainClaims);
    let infoclaims = [];
    let curTime = moment().unix();
    for (let i = 0; i < claims.length; i++) {
      let infoClaim = claims[i];
      for (let j = 0; j < infoClaim.tranches.length; j++) {
        if (Object.keys(infoClaim.tranches[j]).includes('amount')) {
          let trancheBN = new BN(infoClaim.tranches[j].no);
          let [distributor] = await web3.PublicKey.findProgramAddress(
            [Buffer.from(infoClaim.poolName), trancheBN.toArrayLike(Buffer, "le", 8)],
            claimsProgramID
          );
          let distributorInfo = await claimsProgram.account.merkleDistributor.fetch(distributor);

          infoClaim.tranches[j].claimStatus = distributorInfo.claimStatus;
          infoClaim.tranches[j].mint = distributorInfo.mint.toBase58();
          let [claimant] = await web3.PublicKey.findProgramAddress(
            [provider.wallet.publicKey.toBuffer(),
            Buffer.from(infoClaim.poolName),
            trancheBN.toArrayLike(Buffer, "le", 8),
            Buffer.from("ClaimStatus")
            ],
            claimsProgramID
          );
          const claimantInit = await connection.getBalance(claimant);
          if (claimantInit > 0) { infoClaim.tranches[j].claimed = true; } else { infoClaim.tranches[j].claimed = false; }
          if (curTime > distributorInfo.claimDate.toNumber()) { infoClaim.tranches[j].timePassed = true; } else { infoClaim.tranches[j].timePassed = false; }
        }
      }

      const { host } = window.location;
      if (host.indexOf("app.starlaunch.com") !== -1) {
        if (Boolean(infoClaim?.production)) {
          infoclaims.push(infoClaim);
        }
      } else {
        infoclaims.push(infoClaim);
      }

    }
    setClaimRow(infoclaims[0]);
    setClaimInfo(infoclaims);
  }

  async function claimTokens(poolName, mint, tranche, amount, index, proof) {
    try {
      const claimsProgram = new Program(claimsIdl, claimsProgramID, provider);
      const trancheBN = new BN(tranche);
      const [tokenVault] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolName), trancheBN.toArrayLike(Buffer, "le", 8), Buffer.from("token_vault")],
        claimsProgramID
      );
      const tokenMint = new web3.PublicKey(mint);
      const userTokens = await helpers.findATA(provider.wallet.publicKey, tokenMint);
      const userTokensBal = new BN(await connection.getBalance(userTokens));
      if (userTokensBal.isZero()) {
        //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
        let tx = new web3.Transaction();
        tx.add(
          Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, tokenMint,
            userTokens, provider.wallet.publicKey, provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx, connection);
        await connection.confirmTransaction(sign, 'processed');
        toast("Token account created", successToast);
      }
      const [claimStatus] = await web3.PublicKey.findProgramAddress(
        [provider.wallet.publicKey.toBuffer(), Buffer.from(poolName), trancheBN.toArrayLike(Buffer, "le", 8), Buffer.from("ClaimStatus")],
        claimsProgramID
      );
      const [distributor] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolName), trancheBN.toArrayLike(Buffer, "le", 8)],
        claimsProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        claimsProgram.instruction.claim(
          new BN(index),
          new BN(amount),
          proof.map((p) => toBytes32Array(Buffer.from(p, "hex"))),
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              claimStatus: claimStatus,
              distributor: distributor,
              userToken: userTokens,
              tokenVault: tokenVault,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
              rent: web3.SYSVAR_RENT_PUBKEY
            },
          })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Tranche Claimed", successToast);
      window.location.reload();
    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    }
  }

  const claimsList = () => {
    return claimInfo.map((claim, i) => {
      return <div className={`claimRow${claimRow.name === claim.name ? "Active" : ""}`} onClick={() => setClaimRow(claim)} key={i}>{claim.name}</div>
    })
  }

  const claimContent = () => {  
    return (
      <ClaimCard
        title={claimRow.name}
        description={claimRowDescription}
        logo={claimRow.logo}
        tranches={claimRow.tranches.length}
      />
    )
  }

  const tranches = () => {
    return claimRow.tranches.map(tranch => {
      return <TrancheBox
        claimTokens={claimTokens}
        index={tranch.index}
        amount={tranch.amount}
        pool={claimRow.poolName}
        mint={tranch.mint}
        number={tranch.no}
        proof={tranch.proof}
        decimals={claimRow.decimals}
        claimMode={tranch.claimMode}
        claimStatus={tranch.claimStatus}
        claimDate={tranch.claimDate}
        timePassed={tranch.timePassed}
        claimed={tranch.claimed}
      />
    })
  }

  return (
    <main
      key="ClaimsPage"
      className='claimMainContainer'>
      <div className='missionOverlay'></div>
      {!wallet.publicKey &&
        <InfoCard
          text={connectWalletText()}
          title={connectWalletTitle()}
          icon={WalletNotConnectedIcon} />}
      {wallet.publicKey && claimRow && claimInfo && <div
        className='claimsPageContainer' >
        <div className='claimFlexRow'>
          <div className='claimsContainer'>
            <div className='claimsList'>
              {claimInfo.length > 0 && claimsList()}
            </div>
          </div>
          {claimContent()}
        </div>
        <div className='tranchesContainer'>
          {tranches()}
        </div>
      </div>}
      {!detectMobile.isMobile() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}
          muted >
          <source src={missionPageBackground} type='video/webm' />
        </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}
          muted >
          <source src={missionPageBackgroundMobile} type='video/webm' />
          <source src={missionPageBackgroundMobileMp4} type='video/mp4' />
        </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
        <img className='backgroundVideo' src={missionPageBackgroundMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {/*       {videoOff && <img className='backgroundVideo' src={missionPageBackgroundImage} alt="" />} */}
      {isLoading && <LoadingSpinner />}
    </main>
  );
}

export default ClaimsPage;
