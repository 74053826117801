import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import landingPageVideo from '../../images/landingPageBackground.webm';
import landingPageVideoMobile from '../../images/landingPageMobile.webm';
import landingPageVideoMobileMp4 from '../../images/landingPageMobile.mp4';
import {
  hover,
  setDisableHover,
  SHIP_PART_CLAIMS_PAGE,
  SHIP_PART_IDO_PAGE,
  SHIP_PART_PLAYER_DASHBOARD,
  SHIP_PART_REACTOR, SHIP_PART_SWAP_PAGE,
  unHover
} from './shipParts';

import '../../Components/Leftnav/Leftnav.scss';
import styles from "./Home.module.scss";
import {useHistory, useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import HomeWelcomeModal from './HomeWelcomeModal';
import { LeftNavClickAudio } from '../../Components/Leftnav/Leftnav';
import {
  PATH_CLAIMS_PAGE,
  PATH_HOME,
  PATH_IDO_PAGE,
  PATH_NODE_SALE_DETAILS,
  PATH_PLAYER_DASHBOARD,
  PATH_SWAP_PAGE,
  PATH_VAULT
} from '../../App';
import reactorHighlight from '../../images/Frontpage_Ship_Highlight_Reactor.png'
import playerDashboardHighlight from '../../images/Frontpage_Ship_Highlight_Crew_quarters.png'
import claimsPageHighlight from '../../images/Frontpage_Ship_Highlight_Hangar.png'
import swapPageHighlight from '../../images/Frontpage_Ship_Highlight_Hangar_2.png'
import idoPageHighlight from '../../images/Frontpage_Ship_Highlight_Command.png'
import useMobileDetect from 'use-mobile-detect-hook';
import { useState } from 'react';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { setInformationModalState } from '../../Components/Leftnav/modal';
import { PAGE_PROJECT_APPLY } from './HomeInformation';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transform: 'scale(1)',
  },
  out: {
    transform: 'scale(2)',
    opacity: 0,
  },
}

function Home(props) {
  const {
    openApplyForm = false,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const mobileDetect = useMobileDetect();

  const [isLoading, setIsLoading] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(localStorage.getItem('showWelcomeModal') !== 'false');

  const isMobile = mobileDetect.isMobile();

  const hoverDisabled = useSelector((state) => state.shipParts.disableHover);

  const reactorHover = useSelector((state) => state.shipParts[SHIP_PART_REACTOR])
  const claimsPageHover = useSelector((state) => state.shipParts[SHIP_PART_CLAIMS_PAGE])
  const playerDashboardHover = useSelector((state) => state.shipParts[SHIP_PART_PLAYER_DASHBOARD])
  const idoPageHover = useSelector((state) => state.shipParts[SHIP_PART_IDO_PAGE])
  const swapPageHover = useSelector((state) => state.shipParts[SHIP_PART_SWAP_PAGE])
  
  const isMuted = useSelector((state) => state.sound.mute);
  const videoOff = useSelector((state) => state.sound.videoOff);
  const showTutorial = useSelector((state) => state.modal.showTutorial);
  const showInformation = useSelector((state) => state.modal.informationState.show);
  const showApply = useSelector((state) => state.modal.showApplyForm);

  const hoverEnabled = !showInformation && !showTutorial && !hoverDisabled && !showApply && window.location.pathname === PATH_HOME;

  useEffect(() => {
    if (openApplyForm === true) {
      dispatch(setInformationModalState({ show: true, startPage: PAGE_PROJECT_APPLY }));
    }
  }, [openApplyForm])

  useEffect(() => {
    if (!hoverDisabled) {
      dispatch(setDisableHover(true))
    }
    setTimeout(() => {
      dispatch(setDisableHover(false));
    }, 1500)
  }, [])

  const hoverPart = (shipPart) => {
    dispatch(hover(shipPart))
  }

  const unhoverPart = (shipPart) => {
    dispatch(unHover(shipPart))
  }

  const handleRedirect = (url, shipPart) => {
    if (!isMuted) {
      LeftNavClickAudio.play()
    }
    unhoverPart(shipPart);
    history.push(url);
  };

  const reactorClassname = reactorHover ? styles.shipHighlight : styles.shipHighlightOpacity;
  const playerDashboardClassname = playerDashboardHover ? styles.shipHighlight : styles.shipHighlightOpacity;
  const claimsPageClassname = claimsPageHover  ? styles.shipHighlight : styles.shipHighlightOpacity;
  const idoPageClassname = idoPageHover  ? styles.shipHighlight : styles.shipHighlightOpacity;
  const swapPageClassname = swapPageHover  ? styles.shipHighlight : styles.shipHighlightOpacity;

  return (
    <main id="home" key="home">
      {showWelcomeModal && <HomeWelcomeModal setShowWelcomeModal={setShowWelcomeModal} />}
      {hoverEnabled && <div>
        <img className={reactorClassname} src={reactorHighlight} alt=""/>
        <img className={playerDashboardClassname} src={playerDashboardHighlight} alt=""/>
        <img className={claimsPageClassname} src={claimsPageHighlight} alt=""/>
        <img className={idoPageClassname} src={idoPageHighlight} alt=""/>
        <img className={swapPageClassname} src={swapPageHighlight} alt=""/>
      </div>}
      <div>
        {hoverEnabled && <div
            onClick={() => {
              handleRedirect(PATH_VAULT, SHIP_PART_REACTOR)
          }}
          onMouseEnter={() => {
            hoverPart(SHIP_PART_REACTOR)
          }}
          onMouseLeave={() => {
            unhoverPart(SHIP_PART_REACTOR)
          }}
          className={styles.reactor} />}

        {hoverEnabled && <div
          onClick={() => {
            handleRedirect(PATH_PLAYER_DASHBOARD, SHIP_PART_PLAYER_DASHBOARD)
          }}
          onMouseEnter={() => {
            hoverPart(SHIP_PART_PLAYER_DASHBOARD)
          }}
          onMouseLeave={() => {
            unhoverPart(SHIP_PART_PLAYER_DASHBOARD)
          }}
          className={styles.playerDashboard} /> }

        {hoverEnabled && <div
          onClick={() => {
            handleRedirect(PATH_CLAIMS_PAGE, SHIP_PART_CLAIMS_PAGE)
          }}
          onMouseEnter={() => {
            hoverPart(SHIP_PART_CLAIMS_PAGE)
          }}
          onMouseLeave={() => {
            unhoverPart(SHIP_PART_CLAIMS_PAGE)
          }}
          className={styles.claimsPage} />}
{/*        {hoverEnabled && <div
            onClick={() => {
              handleRedirect(PATH_SWAP_PAGE, SHIP_PART_SWAP_PAGE)
            }}
            onMouseEnter={() => {
              hoverPart(SHIP_PART_SWAP_PAGE)
            }}
            onMouseLeave={() => {
              unhoverPart(SHIP_PART_SWAP_PAGE)
            }}
            className={styles.swapPage} />}*/}

        {hoverEnabled && <div
          onClick={() => {
            handleRedirect(PATH_IDO_PAGE, SHIP_PART_IDO_PAGE)
          }}
          onMouseEnter={() => {
            hoverPart(SHIP_PART_IDO_PAGE)
          }}
          onMouseLeave={() => {
            unhoverPart(SHIP_PART_IDO_PAGE)
          }}
          className={styles.idoPage} /> }
         {!isMobile && <video
          className={styles.backgroundVideoHome}
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}
          muted={true}>
          <source src={landingPageVideo} type='video/webm' />
        </video> }
         {isMobile && !mobileDetect.isIos() && <video
          className={styles.backgroundVideoHomeMobile}
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}  
          muted={true}>
            <source src={landingPageVideoMobile} type='video/webm' /> 
            <source src={landingPageVideoMobileMp4} type='video/mp4' /> 
        </video> } 
        {isMobile && mobileDetect.isIos() && 
        <img className={styles.backgroundVideoHomeMobile} alt="" onLoad={() => setIsLoading(false)} src={landingPageVideoMobileMp4} /> }
          {isLoading && <LoadingSpinner />}
      </div>
    </main>

  );
}

export default Home;
