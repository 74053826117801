// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playdashboardOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.playdashboardOverlay .informationContainer {
  width: 40%;
  min-height: fit-content; }
`, "",{"version":3,"sources":["webpack://./src/screens/PlayerDashboard/PlayerDashboardTutorial.scss"],"names":[],"mappings":"AAAA;EACC,eAAe;EACf,WAAW;EACX,YAAY;EACZ,OAAO;EACP,SAAS;EACT,oCAAiC;EACjC,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA;;AASpB;EACC,UAAU;EACV,uBAAuB,EAAA","sourcesContent":[".playdashboardOverlay {\r\n\tposition: fixed;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tleft: 0;\r\n\tbottom: 0;\r\n\tbackground-color: rgba(0,0,0,0.8);\r\n\tz-index: 2;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tflex-direction: row;\r\n}\r\n\r\n@supports (((-webkit-backdrop-filter: none) or (backdrop-filter: none)) and (backdrop-filter: blur(30px)) ) {\r\n\t.playdashboardOverlay {\r\n\t\r\n\t}\r\n}\r\n\r\n.playdashboardOverlay .informationContainer {\r\n\twidth: 40%;\r\n\tmin-height: fit-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
