import React from "react";
import styles from "./RadioButtonGroup.module.scss";
import RadioButton from "./RadioButton";

function RadioButtonGroup(props) {
    const {
        name,
        options,
        value,
        setValue,
    } = props;

    return (
        <div className={styles.inputContainer}>            
            {options.map(option => {
                return(
                    <RadioButton name={name} 
                        value={option.id}
                        key={option.id}
                        label={option.label} 
                        setValue={setValue}
                        tooltip={option.tooltip}
                        tooltipId={option.tooltipId}
                        checked={option.id === value} />
                );
            })}
        </div>
    );
}

export default RadioButtonGroup;
