import React, { useState, useEffect, useCallback } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';

import { web3, BN } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import toast from 'react-hot-toast';
import { useConnection, useWallet, useAnchorWallet } from '@solana/wallet-adapter-react';
import LightningIcon from '../../images/svg/vault-lightning.svg';
import ArrowIcon from '../../images/svg/arrow.svg';
import CaretForward from '../../images/svg/caretForward.svg';
import AccountAddIcon from '../../images/svg/account-add.svg';
import WalletNotConnectedIcon from '../../images/svg/walletNotConnected.svg';
import ReactorVideo from '../../images/reactorBackground.webm';
import ReactorVideoMobile from '../../images/reactorBackgroundMobile.webm';
import ReactorVideoMobileMp4 from '../../images/reactorBackgroundMobile.mp4';
import styles from "./Vault.module.scss";
import './VaultWallet.scss';
import { Buffer } from 'buffer';

import CreateVault from "../../Components/Vault/CreateVault";
import RestakeVault from "../../Components/Vault/RestakeVault";
import HeaderVault from "../../Components/Vault/HeaderVault";
import InfoCard from "../../Components/InfoCard/InfoCard";
import CardVault from '../../Components/Vault/CardVault';
import { useSelector, useDispatch } from "react-redux";
import { ButtonClickAudio } from '../../Components/Button/Button';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PATH_PLAYER_DASHBOARD } from '../../App';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { errorToast, successToast } from '../../Helpers/toastCSS';
import {
  cadetProgramAdd,
  vaultProgramAdd,
  vaultProgramName,
  starsAdd,
  hydrazineAdd,
  CLAIM_ALL_MAX_PER_TRANSACTION
} from '../../constants/constants';
import * as helpers from '../../Helpers/helper';
import { getErrorMessage } from "../../utils/utils"
import { setRestakeConfirmation } from "../../Components/Header/statistics";
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';

function Vault(props) {
  const {
    vaultState,
    provider,
  } = props;

  const { connection } = useConnection();
  const wallet = useWallet();
  const detectMobile = useMobileDetect();
  const history = useHistory();
  const vaultProgramName = helpers.getVaultProgramName();
  const [isLoading, setIsLoading] = useState(true);
  const [tutorial, setTutorial] = useState(false);
  const [vaultBoxInfo, setVaultBoxInfo] = useState(null);
  const [claimingHydrazine, setClaimingHydrazine] = useState();
  const dispatch = useDispatch();
  
  const isMuted = useSelector((state) => state.sound.mute);
  const videoOff = useSelector((state) => state.sound.videoOff);
  const showWelcomeScreen = useSelector((state) => state.modal.showWelcomeFusion);

  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;

  const starsMint = helpers.getStarsMintAddress();
  const hydrazineMint = helpers.getHydrazineMintAddress();
  const vaultProgramID = helpers.getVaultProgramId();

  const mainVault = vaultState.mainVault;
  const newBoxId = vaultState.newBoxId;
  const vaultBoxes = vaultState.userBoxes;

  const personalCrypto = useSelector((state) => state.statistics.personalCryptoStats);
  const totalCrypto = useSelector((state) => state.statistics.totalCryptoStats);

  const DEFAULT_TIMEOUT = 31000;


  const [delegateHydrazine, setDelegateHydrazine] = useState(true);
  const showConnectWallet = !wallet.publicKey;
  const showCreateAccount = useSelector((state) => state.statistics.showCreateAccount);
  const showRestakeConfirmation = useSelector((state) => state.statistics.showRestakeConfirmation);
  const showCreateVaultAccount = useSelector((state) => state.statistics.showCreateVaultAccount);

  const handleTutorialDone = () => {
    setTutorial(false);
    setDelegateHydrazine(true);
  }; 

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);

  const handleNext = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    if (page + 1 === pages) {
      return;
    }
    setPage(page + 1);
  }, [page, isMuted, pages]);

  const handlePrevious = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    if (page === 0) {
      return;
    }
    setPage(page - 1);
  }, [page, isMuted]);

  const restakeConfirmationBox = useCallback(async (vaultBoxInfo) => {
    setVaultBoxInfo(vaultBoxInfo)
    dispatch(setRestakeConfirmation(!showRestakeConfirmation));
  }, [showRestakeConfirmation])

  const toggleStakeConfirmation = async (e) => {
    setVaultBoxInfo(null);
    dispatch(setRestakeConfirmation(!showRestakeConfirmation));
  }

  async function createVaultAccount() {
    const accts = await helpers.getAddys(provider);
    const vaultProgram = await helpers.getVaultProgram(provider);

    try {
      const transaction = new web3.Transaction();
      transaction.add(
        vaultProgram.instruction.createAccount({
          accounts: {
            userAuthority: provider.wallet.publicKey,
            boxOwner: accts.vaultAccount,
            mainVault: accts.mainVault,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY,
          },
        })
      );


      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Vault Account Created", successToast);
      window.location.reload();
      // setVaultAccount(accts.vaultAccount);
      // await getAccountData();
    } catch(e) {
      const errorMessage = getErrorMessage(e, vaultProgram.idl)
      toast(errorMessage, errorToast);
    }
  }

  // We dont support V1 creation anymore
  const createVault = useCallback(async (amount, lock, type) => {
    setIsLoading(true);
    if (!provider.wallet.publicKey) throw new WalletNotConnectedError();

    const accts = await helpers.getAddys(provider);
    const vaultProgram = helpers.getVaultProgram(provider);
    try {
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      const [vaultBox, vaultBoxBump] = await web3.PublicKey.findProgramAddress(
        [
          provider.wallet.publicKey.toBuffer(), 
          helpers.getVaultProgramNameBuffer(), 
          boxOwner.totalBoxes.toArrayLike(Buffer,'le',8),
          Buffer.from("vault_box")
        ],
        vaultProgramID
      );
      const userStars = await helpers.findATA(provider.wallet.publicKey,starsMint);
      const transaction = new web3.Transaction();

      const vaultAmount = new BN(Number(Number(amount).toFixed(6))*1000000);
      const lockStatus = lock !== "1" ? true : false;
      
      if(type === '1') {
        transaction.add(
          vaultProgram.instruction.createVault(
            vaultAmount,
            lockStatus,
            {
              accounts: {
                userAuthority: provider.wallet.publicKey,
                vaultBox: vaultBox,
                boxOwner: accts.vaultAccount,
                mainVault: accts.mainVault,
                starsMint: starsMint,
                hydrazineMint: hydrazineMint,
                userStars: userStars,
                vaultStars: accts.vaultStars,
                vaultHydrazine: accts.vaultHydrazine,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: SYSVAR_RENT_PUBKEY,
              },
          })
        );
      }  
      
      if(type == '2') {
        transaction.add(
          vaultProgram.instruction.createVaultV2(
            new BN(lock),
            vaultAmount,
            lockStatus,
            {
              accounts: {
                userAuthority: provider.wallet.publicKey,
                vaultBox: vaultBox,
                boxOwner: accts.vaultAccount,
                mainVault: accts.mainVault,
                starsMint: starsMint,
                hydrazineMint: hydrazineMint,
                userStars: userStars,
                vaultStars: accts.vaultStars,
                vaultHydrazine: accts.vaultHydrazine,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: SYSVAR_RENT_PUBKEY,
              },
          })
        );
      }
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("New Vault Created", successToast);
      window.location.reload();
    } catch(e) {
      console.log(e)
      const errorMessage = getErrorMessage(e, vaultProgram.idl)
      toast(errorMessage, errorToast);
    }

    setIsLoading(false);
  }, [SYSVAR_RENT_PUBKEY, SystemProgram.programId, hydrazineMint, starsMint, vaultProgramID])

  const claimVaultBox = useCallback(async (vaultBoxInfos, shouldReload = true) => {
    setIsLoading(true);

    if (!provider.wallet.publicKey) throw new WalletNotConnectedError();

    const accts = await helpers.getAddys(provider);
    const userHydrazine = await helpers.findATA(provider.wallet.publicKey, hydrazineMint);
    const vaultProgram = await helpers.getVaultProgram(provider);
    const userStars = await helpers.findATA(provider.wallet.publicKey, starsMint);

    try {
      const accts = await helpers.getAddys(provider);
      const vaultProgram = await helpers.getVaultProgram(provider);
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      const vaultBoxes = vaultBoxInfos.map(vault => {
        return { publicKey: vault.publicKey, stakingType: vault.account.stakingType }
      });
      const hydrazineAcctBal = new BN(await connection.getBalance(userHydrazine)); 

      if (hydrazineAcctBal.isZero()) {
          let token = new Token(connection,hydrazineMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(
                    ASSOCIATED_TOKEN_PROGRAM_ID,
                    TOKEN_PROGRAM_ID,
                    hydrazineMint,
                    userHydrazine,
                    provider.wallet.publicKey,
                    provider.wallet.publicKey
                  )
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast("Hydrazine token account created", successToast);
      }

      const transaction = new web3.Transaction();
      vaultBoxes.forEach(vaultBox => {
        if(vaultBox.stakingType) {
          transaction.add(
            vaultProgram.instruction.claimV2(
              {
                accounts: {
                  userAuthority: provider.wallet.publicKey,
                  vaultBox: vaultBox.publicKey,
                  boxOwner: accts.vaultAccount,
                  mainVault: accts.mainVault,
                  starsMint: starsMint,
                  hydrazineMint: hydrazineMint,
                  vaultStars: accts.vaultStars,
                  userHydrazine: userHydrazine,
                  userStars: userStars,
                  vaultHydrazine: accts.vaultHydrazine,
                  systemProgram:  web3.SystemProgram.programId,
                  tokenProgram: TOKEN_PROGRAM_ID,
                },
            })
          );
        } else {
          transaction.add(
            vaultProgram.claim(
              {
                accounts: {
                  userAuthority: provider.wallet.publicKey,
                  vaultBox: vaultBox.publicKey,
                  boxOwner: accts.vaultAccount,
                  mainVault: accts.mainVault,
                  starsMint: starsMint,
                  hydrazineMint: hydrazineMint,
                  vaultStars: accts.vaultStars,
                  userHydrazine: userHydrazine,
                  userStars: userStars,
                  vaultHydrazine: accts.vaultHydrazine,
                  systemProgram:  web3.SystemProgram.programId,
                  tokenProgram: TOKEN_PROGRAM_ID,
                },
            })
          );
        }
        
      })

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight }
    } = await connection.getLatestBlockhashAndContext();

      const signature = await provider.wallet.sendTransaction(transaction, connection,  { minContextSlot });
      await connection.confirmTransaction(signature, 'processed');
      toast("Hydrazine Claimed", successToast);

      // Update the fusion boxes
      
      if (shouldReload) {
        window.location.reload();
      }

    } catch(e) {
      const errorMessage = getErrorMessage(e, vaultProgram.idl);
      toast(errorMessage, errorToast);

      if (shouldReload) {
        window.location.reload();
      }
    }

    setIsLoading(false);
  }, [connection, hydrazineMint, provider, starsMint])

  async function restakeBox(lock) {
    setIsLoading(true);
    try {
      const accts = await helpers.getAddys(provider);
      const vaultProgram = await helpers.getVaultProgram(provider);
      const vaultBox = vaultBoxInfo.publicKey;
      const userStars = await helpers.findATA(provider.wallet.publicKey,starsMint);
      const userHydrazine = await helpers.findATA(provider.wallet.publicKey,hydrazineMint);

      const hydrazineAcctBal = new BN(await connection.getBalance(userHydrazine)); 
      if (hydrazineAcctBal.isZero()) {
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                hydrazineMint,
                userHydrazine,provider.wallet.publicKey,
                provider.wallet.publicKey
              )
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast("Hydrazine token account created", successToast);
      }

      const starsAcctBal = new BN(await connection.getBalance(userStars));
      if (starsAcctBal.isZero()) {
        let tx = new web3.Transaction();
        tx.add(
            Token.createAssociatedTokenAccountInstruction(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              starsMint,
              userStars,provider.wallet.publicKey,
              provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx,connection);
        await connection.confirmTransaction(sign,'processed');
        toast("Restaking successful", successToast);
      }

      const transaction = new web3.Transaction();
      const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
      const [vaultBox2] = await web3.PublicKey.findProgramAddress(
        [
          provider.wallet.publicKey.toBuffer(), 
          helpers.getVaultProgramNameBuffer(), 
          boxOwner.totalBoxes.toArrayLike(Buffer,'le',8),
          Buffer.from("vault_box")
        ],
        vaultProgramID
      );
      
      if(!vaultBoxInfo.account.stakingType) {
        transaction.add(
          vaultProgram.instruction.restake(
            new BN(lock),
            {
              accounts: {
                userAuthority: provider.wallet.publicKey,
                mainVault: accts.mainVault,
                boxOwner: accts.vaultAccount,
                vaultBox: vaultBox,
                vaultBox2: vaultBox2,
                starsMint: starsMint,
                hydrazineMint: hydrazineMint,
                userStars: userStars,
                userHydrazine: userHydrazine,
                vaultStars: accts.vaultStars,
                vaultHydrazine: accts.vaultHydrazine,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
              },
          })
        );
      } 

      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Fusion Box Restaked", successToast);
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    }

    setIsLoading(false);
  }
  
  const closeBox = useCallback(async (vaultBoxInfo) => {
    setIsLoading(true);

    try {
      const accts = await helpers.getAddys(provider);
      const vaultProgram = await helpers.getVaultProgram(provider);
      const vaultBox = vaultBoxInfo.publicKey;
      const userStars = await helpers.findATA(provider.wallet.publicKey,starsMint);
      const userHydrazine = await helpers.findATA(provider.wallet.publicKey,hydrazineMint);

      const hydrazineAcctBal = new BN(await connection.getBalance(userHydrazine)); 
      if (hydrazineAcctBal.isZero()) {
          let tx = new web3.Transaction();
          tx.add(
              Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                hydrazineMint,
                userHydrazine,provider.wallet.publicKey,
                provider.wallet.publicKey
              )
          );
          const sign = await provider.wallet.sendTransaction(tx,connection);
          await connection.confirmTransaction(sign,'processed');
          toast("Hydrazine token account created", successToast);
      }

      const starsAcctBal = new BN(await connection.getBalance(userStars));
      if (starsAcctBal.isZero()) {
        let tx = new web3.Transaction();
        tx.add(
            Token.createAssociatedTokenAccountInstruction(
              ASSOCIATED_TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              starsMint,
              userStars,provider.wallet.publicKey,
              provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx,connection);
        await connection.confirmTransaction(sign,'processed');
        toast("Stars token account created", successToast);
      }

      const transaction = new web3.Transaction();
      if(vaultBoxInfo.account.stakingType) {
        transaction.add(
          vaultProgram.instruction.closeBoxV2(
            {
              accounts: {
                userAuthority: provider.wallet.publicKey,
                vaultBox: vaultBox,
                boxOwner: accts.vaultAccount,
                mainVault: accts.mainVault,
                starsMint: starsMint,
                hydrazineMint: hydrazineMint,
                userStars: userStars,
                userHydrazine: userHydrazine,
                vaultStars: accts.vaultStars,
                vaultHydrazine: accts.vaultHydrazine,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
              },
          })
        );
      } else {
        transaction.add(
          vaultProgram.instruction.closeBox(
            {
              accounts: {
                userAuthority: provider.wallet.publicKey,
                vaultBox: vaultBox,
                boxOwner: accts.vaultAccount,
                mainVault: accts.mainVault,
                starsMint: starsMint,
                hydrazineMint: hydrazineMint,
                userStars: userStars,
                userHydrazine: userHydrazine,
                vaultStars: accts.vaultStars,
                vaultHydrazine: accts.vaultHydrazine,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
              },
          })
        );
      }

      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Fusion Box Closed", successToast);
      window.location.reload();
    } catch(e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    }

    setIsLoading(false);
  }, [SystemProgram.programId, hydrazineMint, provider, starsMint])

  const stakeHydrazine = () => {
      return (
          <>
              <p>
                  Staking is the method in which someone can mine Hydrazine in order to participate in an IDO.
              </p>
              <p>
                  There are two staking options in the Fusion Vault that users can use acquire Hydrazine.
                  The user starts by creating a Fusion Box.
                  Click the Create Fusion Box Icon. It is a large + symbol
                  Select how many stars you wish to put in the box.
                  Now the important choice
              </p>
          </>
      );
  }

const vaultAccountInfo = () => {
    return (
        <>
           <p>The last step is to create a Vault Account. Then you will be able to stake you STARS and earn Hydrazine!</p>
        </>
    );
}

const vaultAccountTitle = () => {
    return (
        <h5>
            Vault Account
        </h5>
    );
}


  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  
  const itemsPerPage = 4;
  const renderBoxes = useCallback( () => {
    if (detectMobile.isMobile()) {
      return vaultBoxes.map(function(d,idx) {      
        return (        
          <CardVault key={idx}
            mainVault={mainVault}
            vaultBox={d}
            loading={isLoading}
            claimVaultBox={claimVaultBox}
            restakeBox={restakeConfirmationBox}
            closeBox={closeBox} />
        )                    
      });
    }

    const currentBoxes = vaultBoxes.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)

    if (currentBoxes.length < itemsPerPage) {
      currentBoxes.push({key: 'createBox'})
    }

    const nunberOfPages = Math.ceil((vaultBoxes.length + 1) / itemsPerPage);
    if (pages !== nunberOfPages) {
      setPages(nunberOfPages);
    }

    return currentBoxes.map(function(d,idx) {
      if (d.key === 'createBox') {
        return (
          <CreateVault createVaultAction={createVault}
            key={newBoxId}
            card
            boxId={newBoxId}
            loading={isLoading}
          />     
        );
      } else {
        return (        
          <CardVault key={idx}
            mainVault={mainVault}
            vaultBox={d}
            loading={isLoading}
            restakeBox={restakeConfirmationBox}
            claimVaultBox={claimVaultBox}
            closeBox={closeBox} />
        )              
      }
    });
  }, [vaultBoxes, itemsPerPage, createVault, mainVault, claimVaultBox]);

  const claimAllHydrazine = async () => {
    const maxPerTransaction = CLAIM_ALL_MAX_PER_TRANSACTION;
    const numberOfTransactions = Math.ceil(vaultBoxes.length / maxPerTransaction);

    for (let i = 0; numberOfTransactions > i; i++) {

      const startIndex = i * maxPerTransaction;
      const endIndex = (i + 1) * maxPerTransaction;

      if (numberOfTransactions === 1) {
        setClaimingHydrazine('Claiming: ' + vaultBoxes.length + '/' + vaultBoxes.length)
      } else {
        
        const claiming = () => {
          const correctedIndex = i + 1;

          if (correctedIndex * maxPerTransaction > vaultBoxes.length) {
            return vaultBoxes.length + '/' + vaultBoxes.length;
          } else {
            return ((correctedIndex * maxPerTransaction) + '/' + vaultBoxes.length)
          }
        }

        setClaimingHydrazine('Claiming: ' + claiming())
      }

      await claimVaultBox(vaultBoxes.slice(startIndex, endIndex), i === numberOfTransactions - 1)
    }

  }

  return (
    <main 
    className={styles.vaultPage}>
        <div className={styles.overlay} />
        { showRestakeConfirmation && 
          <div className={styles.createVaultContainer}>
            <RestakeVault
                loading={isLoading}
                key={newBoxId}
                restakeVaultAction={restakeBox}
                button
                boxId={newBoxId}
                vaultBoxInfo={vaultBoxInfo}
                toggle={toggleStakeConfirmation}
            />   
          </div>
        }
        {
            delegateHydrazine && !showConnectWallet && !showCreateAccount && !showCreateVaultAccount &&

              <div className={styles.vaultContainer}>
                  {
                    !detectMobile.isMobile() &&
                    page !== 0 &&
                    pages !== 0 &&
                    <div className={styles.pagesButtonPrevious} onClick={handlePrevious}>
                      &lt;
                    </div>
                  }
                  {
                    !detectMobile.isMobile() &&
                    vaultBoxes.length !== 0 &&
                    pages !== 1 &&
                    page + 1 !== pages &&
                    <div className={styles.pagesButtonNext} onClick={handleNext}>
                      &gt;
                    </div>
                  }
                <div className={styles.innerVaultContainer}>
                <HeaderVault
                  totalStaked={totalCrypto.totalStarsStaked}
                  totalDecayed={totalCrypto.totalStarsDecayed}
                  totalHydrazine={totalCrypto.totalHydrazine}
                  totalPenalized={totalCrypto.totalStarsPenalized}
                  personallyStakedStars={personalCrypto.personallyStakedStars}
                  claimAllHydrazine={claimAllHydrazine}
                  claimingHydrazine={claimingHydrazine}
                  personallyPendingHydrazine={personalCrypto.personallyPendingHydrazine}/>
                  <div className={styles.createVaultContainer}>
                    <CreateVault createVaultAction={createVault}
                      button
                      boxId={newBoxId}
                      loading={isLoading}
                      key={newBoxId}
                    />
                  </div>
                 <div className={styles.createVaultContainer}>

                </div>
                <div className={styles.cardPagesButtonContainer}>
                  <div className={styles.cardVaultContainer}>
                    {renderBoxes()}
                    {!mainVault && <LoadingSpinner local />}

                  </div>

                </div>
                {
                  !detectMobile.isMobile()
                  &&
                  <div className={styles.pages}>
                    {
                      vaultBoxes.length !== 0
                      &&
                      <span>
                        Page {page + 1}/{pages}
                      </span>
                    }

                  </div>
                }
              </div>
            </div>
        }
        {
        !showConnectWallet && !showCreateAccount && showWelcomeScreen &&
        <InfoCard text={stakeHydrazine()}
                  onConfirm={handleTutorialDone}
                  buttonText="Stake Hydrazine"
                  buttonIcon={ArrowIcon}
                  icon={LightningIcon}/>
        }
         {
        !showConnectWallet && !showCreateAccount && showCreateVaultAccount &&
          <InfoCard text={vaultAccountInfo()}
                    title={vaultAccountTitle()}
                    buttonText="Vault Account"
                    buttonIcon={CaretForward}
                    onConfirm={createVaultAccount}
                    icon={AccountAddIcon}/>
      
        }
        {
        !showConnectWallet && showCreateAccount &&
          <InfoCard text={authInfo()}
                    title={authTitle()}
                    buttonText="Player Dashboard"
                    buttonIcon={CaretForward}
                    onConfirm={() => history.push(PATH_PLAYER_DASHBOARD)}
                    icon={AccountAddIcon}/>
        
        }
        {showConnectWallet &&
        <InfoCard text={connectWalletText()}
                  title={connectWalletTitle()}
                  icon={WalletNotConnectedIcon}/>}
       
      {!detectMobile.isMobile() && <video
          className={styles.backgroundVideo}
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}
          muted>
          <source src={ReactorVideo} type='video/webm' />
        </video>}
        {detectMobile.isMobile() && !detectMobile.isIos() &&< video
          className={styles.backgroundVideo}
          autoPlay
          loop
          playsInline
          onLoadedData={() => setIsLoading(false)}
          
          muted>
          <source src={ReactorVideoMobile} type='video/webm' />
          <source src={ReactorVideoMobileMp4} type='video/mp4' />
        </video>}
        {detectMobile.isIos() && detectMobile.isMobile() &&
        <img className={styles.backgroundVideo} src={ReactorVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {isLoading && <LoadingSpinner /> }
    </main>
  );
}

export default Vault;

export const authInfo = () => {
  return (
      <>
         <p>To be able to stake your stars you need to create cadet account in the Player Dashboard</p>
      </>
  );
}

export const authTitle = () => {
  return (
      <h5>
          You need to create Cadet account to begin staking!
      </h5>
  );
}

export const connectWalletTitle = () => {
  return (
      <h5>
          Connect wallet
      </h5>
  );
}

export const connectWalletText = () => {
return (
  <>
    <p>
      To enter this page you need to connect your Solana wallet. Please use the Select wallet button to connect.
    </p>
    <div className='connectWalletModalWrapper'>
      <WalletMultiButton />
    </div>
  </>

);
}

export const connectNotAuthorizedWalletText = () => {
  return (
    <>
      <p>
        This wallet is not authorized, please switch wallet or contact support
      </p>
      <div className='connectWalletModalWrapper'>
        <WalletMultiButton/>
      </div>
    </>

  );
}
