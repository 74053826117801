// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y9abrBw6tIr1s11pKdk3 {
  width: 100%;
  height: 15vh;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.YUDy0KGMGbIPVU95JFNh {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center; }

.hEH6pgPRvMfrzq5H95UA {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, serif;
  color: white; }

.GkkVj5ltDqc5xbmIaYlE {
  color: #62C370; }
`, "",{"version":3,"sources":["webpack://./src/screens/IdoDetails/TokenClaimTab.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,2BAA2B;EAC3B,YAAY,EAAA;;AAGd;EACE,cAAc,EAAA","sourcesContent":[".tokenClaimTabContainer {\r\n  width: 100%;\r\n  height: 15vh;\r\n  min-height: 150px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.claimContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 8px;\r\n  align-items: center;\r\n}\r\n\r\n.claimLabelRow {\r\n  display: flex;\r\n  flex-direction: row;\r\n  column-gap: 4px;\r\n  font-size: 20px;\r\n  font-weight: 500;\r\n  font-family: Poppins, serif;\r\n  color: white;\r\n}\r\n\r\n.claimValue {\r\n  color: #62C370;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenClaimTabContainer": `y9abrBw6tIr1s11pKdk3`,
	"claimContainer": `YUDy0KGMGbIPVU95JFNh`,
	"claimLabelRow": `hEH6pgPRvMfrzq5H95UA`,
	"claimValue": `GkkVj5ltDqc5xbmIaYlE`
};
export default ___CSS_LOADER_EXPORT___;
