import React, { useCallback, useEffect, useRef } from "react";
import Button, { BUTTON_TYPE_PRIMARY, BUTTON_TYPE_PRIMARY_BRIGHT } from "../Button/Button";
import RadioButton from "../RadioButton/RadioButton";
import TextAreaInput from "../TextAreaInput/TextAreaInput";
import TextInput from "../TextInput/TextInput";
import { getApiUrl } from '../../Helpers/helper';

import './Apply.scss';

function Apply(props) {
    const {
        close,
    } = props;

    const containerRef = useRef();
    const API_URL = getApiUrl();
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            close();
        }
    }, [containerRef])

    useEffect(() => {
        require('./validate.js');

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
        className='apply'>
            <div className='applyContainer' ref={containerRef}>
                <div className="closeButton" onClick={close}>X</div>
                <span className="applyTitle">Application to the StarLaunch launch pad</span>
                <span className="applyIngress">In order to be considered for our platform, all candidates and their projects will be thoroughly vetted. Please fill out the following application to the best of your ability and our team will contact you with further details.</span>
                <span className="applyIngress">For inquiries, please contact <a className="applyMailText" href="mailto:hello@starlaunch.io">hello@starlaunch.com</a></span>
                <form action={API_URL + '/ido/request'} method="POST" role="form" className="emailForm">
                    <div className="applySubTitle">
                        <span >Application Type *</span>
                    </div>
                    <div>
                        <RadioButton name="application-type" id="apply-ido" value="Standard IDO" required label="Standard IDO" />
                        <RadioButton name="application-type" id="apply-ido" value="Accelerated/Incubated" required label="Accelerated/Incubated" />
                    </div>
                    <div className="applySubTitle">
                        <span >Contact information</span>
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="name" id="name" placeholder="Your first and last name" required={false} label="Full name *" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="email" id="email" type="email" placeholder="Your contact email" required={false} label="Email *" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="applicant-title" id="applicant-title" placeholder="Your title" required={false} label="Title *" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="applicant-telegram" id="applicant-telegram" placeholder="Your contact telegram handle" label="Telegram username" />
                    </div>
 
                    <div className="applySubTitle">
                        <span >Project Information</span>
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="project-name" id="project-name" placeholder="The name of your project" required={false} label="Project name *" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="website" id="website" placeholder="Your project's website" label="Website URL" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="whitepaper" id="whitepaper" placeholder="A link to your whitepaper or documentation" label="Whitepaper URL" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="deck" id="deck" placeholder="A link to Deck" label="Link to Deck" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="project-twitter" id="project-twitter" placeholder="Your project's twitter handle" label="Project Twitter" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="project-telegram" id="project-telegram" placeholder="Your project's telegram handle" label="Project Telegram" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="project-github" id="project-github" placeholder="Your project's GitHub repo" label="Project GitHub" />
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                            name="project-description" 
                            id="project-description"
                            placeholder="Project description" 
                            label="Project description *" 
                            required={false} 
                            rows={8}/>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                            name="token-information" 
                            id="token-information"
                            placeholder="Token distribution, token sale metrics, etc." 
                            label="Token information *" 
                            required={false} 
                            rows={8}/>
                    </div>       
                    <div className="applySubTitle">
                        <span >Product details</span>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                            name="development-state" 
                            id="development-state"
                            placeholder="Describe your current product development state" 
                            label="Product development state *" 
                            required={false} 
                            rows={8}/>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                            name="development-roadmap" 
                            id="development-roadmap"
                            placeholder="Describe your development roadmap for the following six months or longer" 
                            label="Product roadmap *" 
                            required={false} 
                            rows={8}/>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                            name="unique-value-proposition" 
                            id="unique-value-proposition"
                            placeholder="What sets your product apart from your competitors" 
                            label="Unique value proposition *" 
                            required={false} 
                            rows={8}/>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                                name="solana-contribution" 
                                id="solana-contribution"
                                placeholder="How will your product contribute to the Solana ecosystem?" 
                                label="Solana contribution *" 
                                required={false} 
                                rows={8}/>
                    </div>
                    <div className="applyInputRow">
                        <TextAreaInput 
                                    name="financing" 
                                    id="financing"
                                    placeholder="Describe your financing structure and past raises" 
                                    label="Project financing structure *" 
                                    required={false} 
                                    rows={8}/>
                    </div>
                    <div className="applySubTitle">
                        <span>StarLaunch pool information</span>
                    </div>
                
                    <div className="applyInputRow">
                        <TextInput name="project-raise" id="project-raise" placeholder="How much are you looking to raise?" label="Raise target ($USD) *" />
                    </div>
                    <div className="applyInputRow">
                        <TextInput name="project-launch" id="project-launch" placeholder="When do you expect to launch?" label="Expected launch date *" />
                    </div>

                    <div className="my-3">
                        <div className="apply-loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <Button text="Submit Application" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} htmlType="submit" />
                </form>
            </div>
        </div>
    )
};

export default Apply;
