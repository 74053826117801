import React, {useMemo, useState} from "react";
import PlusIcon from "../../images/svg/plus.svg"
import InformationIcon from "../../images/svg/information2.svg"
import TextInput from "../TextInput/TextInput";
import Modal, { MODAL_TOOLTIP_ID } from "../Modal/Modal";
import styles from './CreateVault.module.scss';
import ArrowIcon from '../../images/svg/arrow.svg'
import RadioButtonGroup from "../RadioButton/RadioButtonGroup";
import { ProgressButtonClickAudio, ProgressButtonHoverAudio } from "../Button/Button";
import { useSelector } from "react-redux";
import useMobileDetect from 'use-mobile-detect-hook';
import VaultIcon from '../../images/svg/vaultCase.svg';
import * as constants from '../../constants/constants';
import moment from 'moment';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import BigNumber from "bignumber.js";
import ReactSlider from 'react-slider';
import Checkbox from "../Checkbox/Checkbox";

function CreateVault(props) {
    const {
        createVaultAction,
        card,
        button,
        boxId,
        loading
    } = props;

    const detectMobile = useMobileDetect();
    const [show, setShow] = useState(false);
    const [amount, setAmount] = useState(0)
    const [lock, setLock] = useState(2)
    const [type, setType] = useState(2)
    const isMuted = useSelector((state) => state.sound.mute);
    const starsInWallet = useSelector((state) => state.statistics.walletStats.starsInWallet);
    const [agreeCheck, setAgreeCheck] = useState(false);
    const [agreeError, setAgreeError] = useState(false);

    const maxSTARS = () => {
        setAmount(Math.floor(starsInWallet));
    }

    const handleShow = () => {
        if (!isMuted) {
            ProgressButtonClickAudio.play();
        }

        setShow(true);
    }

    const handleConfirm = () => {
        if (agreeCheck) {
            createVaultAction(amount, lock, type);
        } else {
            setAgreeError(true);
        }
    };

    const _onHover = () => {
        if (!isMuted) {
            ProgressButtonHoverAudio.play();
        }
    }

    // Calculate staked days
    const stakingType = lock;
    let stakingDays = null;
    let starApr = null;
  
    const DECIMALS = 1000000;
    switch (Number(stakingType)) {
        case 1:
            stakingDays = 0;
            starApr = 0;
            break;
        case 2:
            stakingDays = 90;
            starApr = 0;
            break;
        case 3:
            stakingDays = 180;
            starApr = 15;
            break;
        case 4:
            stakingDays = 360;
            starApr = 25;
            break;
        default:
    }
    
    const finalAmount = useMemo(() => {
        let i = (new BigNumber(1).plus(new BigNumber(starApr).dividedBy(new BigNumber(365)).dividedBy(100)).pow(stakingDays));
        return  new BigNumber(amount).times(i);
    }, [amount, stakingDays, starApr])
    
    const infoMessage = stakingType === 1 ? `I UNDERSTAND THAT UNLOCKED $STARS DECAY ~0.767% per day` : `I UNDERSTAND THAT MY $STARS WILL BE LOCKED FOR ${stakingDays} DAYS AND UNLOCKING EARLY COSTS A PENALTY`;

    const setAgree = (e) => {
        setAgreeCheck(e.target.checked);
    }

    return (
        <>
            <div className={[styles.container, card ? styles.card : null, button ? styles.button : null].join(' ')} onClick={handleShow} onMouseEnter={_onHover}>
                <PlusIcon />
                <h3>Create new fusion box</h3>
            </div>
            {
                detectMobile.isMobile()
                &&
                <Modal
                    show={show}
                    setShow={setShow}
                    exit
                    header="create fusion box"
                    confirmButtonText="Create"
                    confirmButtonIcon={ArrowIcon}
                    onConfirm={handleConfirm}
                    centered>
                    CreateVault
                    <div>
                        <div className={styles.vaultCaseContainer}>
                            <VaultIcon className={styles.vaultCase} />
                        </div>

                        <span className={styles.modalHelper}>
                            vault id
                            <InformationIcon className={styles.modalIcon}
                                data-for={MODAL_TOOLTIP_ID}
                                data-tip={'This is your vault ID, this cannot be edited as of now'} />
                        </span>
                        <div className="flex-row">
                            <TextInput style={{ height: '25%' }} id="box" value={boxId} disabled />
                        </div>
                        <span className={styles.modalHelper}>
                            stars amount
                            <InformationIcon className={styles.modalIcon}
                                data-for={MODAL_TOOLTIP_ID}
                                data-tip={'Enter the amount of Stars you would like to stake here'} />
                        </span>
                        <div className={styles.vaultAmountRow}>
                            <TextInput id="amount" label="Enter amount" value={amount} setValue={setAmount}
                                       type="number"/>
                            <div className={styles.maxButtonContainer} onClick={maxSTARS}>
                                <span className="maxButton">MAX</span>
                            </div>
                        </div>
                        <span className={styles.modalHelper}>
                            Lock Time
                            <InformationIcon className={styles.modalIcon}
                                data-for={MODAL_TOOLTIP_ID}
                                data-tip={'The time you lock your $STARS. The longer you stake, the bigger the rewards.'} />
                        </span>

                        <ReactSlider
                            className={styles.horizontalSlider}
                            marks={[2, 3, 4]}
                            markClassName={styles.sliderMarks}
                            min={2}
                            max={4}
                            defaultValue={lock}
                            renderTrack={(props, state) => {
                                return <div {...props} />
                            }}
                            renderMark={(props) => {
                                switch (props.key) {
                                    case 1:
                                        return <div {...props}>
                                            <div className={styles.sliderMarkFirst} >
                                                <div className={styles.sliderDot}></div>
                                                <div>Unlocked</div>
                                            </div>
                                        </div>
                                    case 2:
                                        return <div {...props}>
                                            <div className={styles.sliderMark} >
                                                <div className={styles.sliderDot}></div>
                                                <div>90 days</div>
                                            </div>
                                        </div>
                                    case 3:
                                        return <div {...props}>
                                            <div className={styles.sliderMark} >
                                                <div className={styles.sliderDot}></div>
                                                <div>180 days</div>
                                            </div>
                                        </div>
                                    case 4:
                                        return <div {...props}>
                                            <div className={styles.sliderMarkLast} >
                                                <div className={styles.sliderDot}></div>
                                                <div>360 days</div>
                                            </div>
                                        </div>
                                    default:
                                        break;
                                }
                            }}
                            onChange={(e) => {
                                setLock(e)
                            }}
                            renderThumb={(props, state) => {
                                switch (state.value) {
                                    case 1:
                                        return <div {...props}>
                                            <div className={styles.sliderThumbFirst}></div>
                                        </div>
                                    case 2:
                                        return <div {...props}>
                                            <div className={styles.sliderThumbNew} >
                                            </div>
                                        </div>
                                    case 3:
                                        return <div {...props}>
                                            <div className={styles.sliderThumbNew} >
                                            </div>
                                        </div>
                                    case 4:
                                        return <div {...props}>
                                            <div className={styles.sliderThumbLast} >
                                            </div>
                                        </div>
                                    default:
                                        break;
                                }
                            }}
                            thumbClassName={styles.sliderThumb}
                            trackClassName={styles.sliderTrack}
                        />
                        <div className={styles.stakeInfo}>
                            <div>
                                <div>You will generate Hydrazine (N2H4)</div>
                                {(lock === 1) && <div style={{ color: '#f77f00' }}>Stake without lock (decays stars 0.767% per day)</div>}
                                {(lock === 3 || lock === 4) && <div>You will generate {starApr}% STARS APR</div>}
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <span>Read All about Staking <a href="https://medium.com/@StarLaunch/announcing-variable-length-staking-643ad976f13e" target="_blank">Here</a></span>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            {
                                agreeError ?
                                    <Checkbox id="vaultAgree" className={styles.textRed} onChange={(e) => setAgree(e)} checked={agreeCheck} label={infoMessage} />
                                    :
                                    <Checkbox id="vaultAgree" onChange={(e) => setAgree(e)} checked={agreeCheck} label={infoMessage} />
                            }
                        </div>
                    </div>
                </Modal>
            }
            {
                !detectMobile.isMobile() &&
                show
                &&
                <Modal
                    show={show}
                    afterClose={() => { }}
                    setShow={setShow}
                    exit
                    confirmButtonText="Create fusion box"
                    confirmButtonIcon={ArrowIcon}
                    onConfirm={handleConfirm}
                    centered>
                    <h3 className={styles.modalHeader}>
                        create fusion box
                    </h3>
                    <div style={{ display: 'flex', flex: 1 }}>
                        {loading && <div className={styles.loaderOverLay}><LoadingSpinner local={true} /></div>}
                        <div style={{ display: 'flex', flex: 3 }} className="flex-column">
                            <span className={styles.modalHelper}>
                                vault id
                                <InformationIcon className={styles.modalIcon}
                                    data-for={MODAL_TOOLTIP_ID}
                                    data-tip={'This is your vault ID, this cannot be edited as of now'} />
                            </span>
                            <div className="flex-row">
                                <TextInput style={{ height: '25%' }} id="box" value={boxId} disabled />
                            </div>
                            <span className={styles.modalHelper}>
                                stars amount
                                <InformationIcon className={styles.modalIcon}
                                    data-for={MODAL_TOOLTIP_ID}
                                    data-tip={'Enter the amount of Stars you would like to stake here'} />
                            </span>
                            <div className={styles.vaultAmountRow}>
                                <TextInput id="amount" label="Enter amount" value={amount} setValue={setAmount}
                                           type="number"/>
                                <div className={styles.maxButtonContainer} onClick={maxSTARS}>
                                    <span className="maxButton">MAX</span>
                                </div>
                            </div>
                            <span className={styles.modalHelper}>
                                Lock Time
                                <InformationIcon className={styles.modalIcon}
                                    data-for={MODAL_TOOLTIP_ID}
                                    data-tip={'The time you lock your $STARS. The longer you stake, the bigger the rewards.'} />
                            </span>
                            <ReactSlider
                                className={styles.horizontalSlider}
                                marks={[2, 3, 4]}
                                markClassName={styles.sliderMarks}
                                min={2}
                                max={4}
                                defaultValue={lock}
                                renderTrack={(props, state) => {
                                    return <div {...props} />
                                }}
                                renderMark={(props) => {
                                    switch (props.key) {
                                        case 1:
                                            return <div {...props}>
                                                        <div className={styles.sliderMarkFirst} >
                                                            <div className={styles.sliderDot}></div>
                                                            <div>Unlocked</div>
                                                        </div>
                                                    </div>
                                        case 2:
                                            return <div {...props}>
                                                <div className={styles.sliderMark} >
                                                    <div className={styles.sliderDot}></div>
                                                    <div>90 days</div>
                                                </div>
                                            </div>
                                        case 3:
                                            return <div {...props}>
                                                <div className={styles.sliderMark} >
                                                    <div className={styles.sliderDot}></div>
                                                    <div>180 days</div>
                                                </div>
                                            </div>
                                        case 4:
                                            return <div {...props}>
                                                <div className={styles.sliderMarkLast} >
                                                    <div className={styles.sliderDot}></div>
                                                    <div>360 days</div>
                                                </div>
                                            </div>
                                        default:
                                            break;
                                    }
                                }}
                                onChange={(e) => {
                                    setLock(e)
                                }}
                                renderThumb={(props, state) => {
                                    switch (state.value) {
                                        case 1:
                                            return <div {...props}>
                                                        <div className={styles.sliderThumbFirst}></div>
                                                    </div>
                                        case 2:
                                            return <div {...props}>
                                                <div className={styles.sliderThumbNew} >
                                                </div>
                                            </div>
                                        case 3:
                                            return <div {...props}>
                                                <div className={styles.sliderThumbNew} >
                                                </div>
                                            </div>
                                        case 4:
                                            return <div {...props}>
                                                <div className={styles.sliderThumbLast} >
                                                </div>
                                            </div>
                                        default:
                                            break;
                                    }
                                }}
                                thumbClassName={styles.sliderThumb}
                                trackClassName={styles.sliderTrack}
                            />
                            {/* <RadioButtonGroup name="lock" 
                                value={lock}
                                setValue={setLock}
                                options={[
                                        {id: "1", label: "Stake without lock(decays stars 0.767% per day)", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "2", label: "Stake with 90 day lock", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "3", label: "Stake with 180 day lock", tooltip: constants.LOCKED_180BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "4", label: "Stake with 360 day lock", tooltip: constants.LOCKED_360BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                    ]} /> */}
                            <div className={styles.stakeInfo}>
                                <div>
                                    <div>You will generate Hydrazine (N2H4)</div>
                                    {(lock === '1') && <div style={{ color: '#f77f00' }}>Stake without lock (decays stars 0.767% per day)</div>}
                                    {(lock === 3 || lock === 4) && <div>You will generate {starApr}% STARS APR</div>}
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <span>Read All about Staking <a href="https://medium.com/@StarLaunch/announcing-variable-length-staking-643ad976f13e" target="_blank">Here</a></span>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                {
                                    agreeError ?
                                        <Checkbox id="vaultAgree" className={styles.textRed} onChange={(e) => setAgree(e)} checked={agreeCheck} label={infoMessage} />
                                        :
                                        <Checkbox id="vaultAgree" onChange={(e) => setAgree(e)} checked={agreeCheck} label={infoMessage} />
                                }
                            </div>
                            {/* <RadioButtonGroup name="type" 
                                value={type}
                                setValue={setType}
                                options={[
                                        {id: "1", label: "Old", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "2", label: "New", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                    ]} /> */}
                        </div>
                        <div className={styles.stakeSummary}>
                            <h2 className={styles.modalHelper}>
                                Stake Summary
                            </h2>
                            <div className={styles.stakeProperty}>
                                <span className={styles.propertyTitle}>
                                    Amount Stars
                                    <InformationIcon className={styles.modalIcon}
                                        data-for={MODAL_TOOLTIP_ID}
                                        data-tip={'This is the amount of STARS you will stake'} />
                                </span>
                                <span className={styles.propertyValue}>{amount}</span>
                            </div>
                            <div className={styles.stakeProperty}>
                                <span className={styles.propertyTitle}>
                                    Lock Time
                                    <InformationIcon className={styles.modalIcon}
                                        data-for={MODAL_TOOLTIP_ID}
                                        data-tip={'When this time has passed, you will be able to unlock your STARS (and potential $STARS rewards) without penalty'} />
                                </span>
                                <span className={styles.propertyValue}>{stakingDays} Days</span>
                            </div>
                            {(lock === 3 || lock === 4) &&
                                <div className={styles.stakeProperty}>
                                    <span className={styles.propertyTitle}>
                                        Stars Rewards (Expected)
                                        <InformationIcon className={styles.modalIcon}
                                            data-for={MODAL_TOOLTIP_ID}
                                            data-tip={'Expected amout of star rewards'} />
                                    </span>
                                    <span className={styles.propertyValue}>{finalAmount.minus(amount).toFixed(2)}</span>
                                </div>
                            }
                            {(lock === 3 || lock === 4) &&
                                <div className={styles.stakeProperty}>
                                    <span className={styles.propertyTitle}>
                                        Reward Method
                                        <InformationIcon className={styles.modalIcon}
                                            data-for={MODAL_TOOLTIP_ID}
                                            data-tip={'We autocompound the rewards on daily'} />
                                    </span>
                                    <span className={styles.propertyValue}>Auto Compound</span>
                                </div>
                            }
                            <div className={styles.stakeProperty}>
                                <span className={styles.propertyTitle}>
                                    Unlock Date
                                    <InformationIcon className={styles.modalIcon}
                                        data-for={MODAL_TOOLTIP_ID}
                                        data-tip={'This is the day your vault unlocks'} />
                                </span>
                                <span className={styles.propertyValue}>{moment(new Date()).add(Number(stakingDays), 'd').utc().format('LLL')}</span>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default CreateVault;
