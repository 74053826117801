
import {Connection, PublicKey, Signer, Transaction, TransactionInstruction,} from "@solana/web3.js";

import { LiquidityPoolKeys, Liquidity, TokenAmount, Token, Percent, TOKEN_PROGRAM_ID, SPL_ACCOUNT_LAYOUT,  TokenAccount } from '@raydium-io/raydium-sdk';

export async function getTokenAccountsByOwner(
    connection,
    owner,
) {
    const tokenResp = await connection.getTokenAccountsByOwner(
        owner,
        {
            programId: TOKEN_PROGRAM_ID
        },
    );

    const accounts = [];

    for (const { pubkey, account } of tokenResp.value) {
        accounts.push({
            pubkey,
            accountInfo:SPL_ACCOUNT_LAYOUT.decode(account.data)
        });
    }

    return accounts;
}

/**
 * swapInDirection: used to determine the direction of the swap
 * Eg: RAY_SOL_LP_V4_POOL_KEY is using SOL as quote token, RAY as base token
 * If the swapInDirection is true, currencyIn is RAY and currencyOut is SOL
 * vice versa
 */
export async function calcAmountOut({connection, tokenInObject, tokenOutObject, rawAmountIn, swapInDirection, slippageRaw, publicKey}) {
    const tokenInPoolInfo = tokenInObject.poolKeys
    const tokenOutPoolInfo = tokenOutObject?.poolKeys

    const tokenIn = tokenInObject.token;
    const tokenOut = tokenOutObject?.token;
    console.log(connection, tokenInPoolInfo)
    const poolInfoIn = await Liquidity.fetchInfo({ connection, poolKeys: tokenInPoolInfo });
    const poolInfoOut = tokenOutPoolInfo ? await Liquidity.fetchInfo({ connection, poolKeys: tokenOutPoolInfo }) : null;
    let currencyInMint = tokenInPoolInfo.quoteMint;
    let currencyInDecimals = poolInfoIn.baseDecimals;
    let currencyOutMint = tokenOutPoolInfo ? tokenOutPoolInfo.quoteMint : tokenInPoolInfo.baseMint;
    let currencyOutDecimals = poolInfoOut ? poolInfoOut.baseDecimals : poolInfoIn.quoteDecimals;

    if (!swapInDirection) {
        currencyInMint = tokenInPoolInfo.quoteMint;
        currencyInDecimals = poolInfoIn.quoteDecimals;
        currencyOutMint = tokenOutPoolInfo ? tokenOutPoolInfo.quoteMint : tokenInPoolInfo.baseMint;
        currencyOutDecimals = tokenOutPoolInfo ? poolInfoOut.quoteDecimals : poolInfoIn.baseDecimals;
    }

    const currencyIn = new Token(TOKEN_PROGRAM_ID ,currencyInMint, currencyInDecimals, tokenIn.symbol, tokenIn.name);
    const amountIn = new TokenAmount(currencyIn, rawAmountIn, false);
    console.log(tokenOut)
    console.log(amountIn)
    const currencyOut = tokenOut ?
        new Token(TOKEN_PROGRAM_ID, currencyOutMint, currencyOutDecimals, tokenOut.symbol, tokenOut.name) :
        new Token(TOKEN_PROGRAM_ID, currencyOutMint, currencyOutDecimals);
    const slippage = new Percent(slippageRaw, 100); // 5% slippage
    console.log(currencyOut.mint.toString(), currencyOut.programId.toString())
    const {
        amountOut,
        minAmountOut,
        currentPrice,
        executionPrice,
        priceImpact,
        fee,
    } = Liquidity.computeAmountOut({ poolKeys: tokenInPoolInfo, poolInfo: poolInfoIn, amountIn, currencyOut, slippage, });

    return {
        amountIn,
        amountOut,
        minAmountOut,
        currentPrice,
        executionPrice,
        priceImpact,
        fee,
    };
}

export const makeSwapTransaction = async (params) => {
    const { connection, poolKeys, userKeys, amountIn, amountOut, fixedSide, config } = params;
    const { tokenAccounts, owner, payer = owner } = userKeys;


    const { bypassAssociatedCheck } = {
        // default
        ...{ bypassAssociatedCheck: false },
        // custom
        ...config,
    };

    // handle currency in & out (convert SOL to WSOL)
    const tokenIn = amountIn ? amountIn.token : Token.WSOL;
    const tokenOut = amountOut ? amountOut.token : Token.WSOL;
    console.log(tokenIn, tokenOut)
    const tokenAccountIn = await Liquidity._selectTokenAccount({
        programId: TOKEN_PROGRAM_ID,
        tokenAccounts,
        mint: tokenIn.mint,
        owner,
        config: { associatedOnly: false },
    });
    const tokenAccountOut = await Liquidity._selectTokenAccount({
        programId: TOKEN_PROGRAM_ID,
        tokenAccounts,
        mint: tokenOut.mint,
        owner,
    });

    const [amountInRaw, amountOutRaw] = [amountIn.raw, amountOut.raw];
    console.log(amountInRaw, amountOutRaw)
    const frontInstructions = [];
    const endInstructions = [];
    const signers = [];

    const _tokenAccountIn = await Liquidity._handleTokenAccount({
        programId: TOKEN_PROGRAM_ID,
        connection,
        side: "in",
        amount: amountInRaw,
        mint: tokenIn.mint,
        tokenAccount: tokenAccountIn,
        owner,
        payer,
        frontInstructions,
        endInstructions,
        signers,
        bypassAssociatedCheck,
    });
    const _tokenAccountOut = await Liquidity._handleTokenAccount({
        programId: TOKEN_PROGRAM_ID,
        connection,
        side: "out",
        amount: amountOutRaw,
        mint: tokenOut.mint,
        tokenAccount: tokenAccountOut,
        owner,
        payer,
        frontInstructions,
        endInstructions,
        signers,
        bypassAssociatedCheck,
    });
    console.log(_tokenAccountIn, _tokenAccountOut)
    console.log(poolKeys)
    frontInstructions.push(
        Liquidity.makeSwapInstructionSimple({
            poolKeys,
            userKeys: {
                tokenAccountIn: _tokenAccountIn,
                tokenAccountOut: _tokenAccountOut,
                owner,
            },
            amountIn: tokenIn,
            amountOut: tokenOut,
            fixedSide,
        }),
    );

    const transaction = new Transaction();
    transaction.add(...[...frontInstructions, ...endInstructions]);

    return { transaction, signers };
}
