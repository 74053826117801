// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.idoTutorialOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.IDOTutorialChildren {
  margin-top: 1rem;
  display: flex;
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/screens/IdoPage/IdoPageTutorial.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,WAAW;EACX,YAAY;EACZ,OAAO;EACP,SAAS;EACT,oCAAiC;EACjC,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA;;AAGvB;EACI,gBAAgB;EAChB,aAAa;EACb,uBAAuB,EAAA","sourcesContent":[".idoTutorialOverlay {\r\n    position: fixed;\r\n    width: 100%;\r\n    height: 100%;\r\n    left: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0,0,0,0.8);\r\n    z-index: 2;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: row;    \r\n}\r\n\r\n.IDOTutorialChildren {\r\n    margin-top: 1rem;\r\n    display: flex;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
