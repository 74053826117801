import React, { useEffect, useState } from 'react';
import TelegramIcon from '../../images/svg/telegram.svg'
import MediumIcon from '../../images/svg/medium.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import InformationIcon from '../../images/svg/information.svg'
import HelpIcon from '../../images/svg/help-outline.svg'
import TutorialPopup from '../../Components/TutorialPopup/TutorialPopup';
import useMobileDetect from 'use-mobile-detect-hook';
import './HomeTutorial.scss';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonClickAudio } from '../../Components/Button/Button';
import { toggleShowInformation } from '../../Components/Leftnav/modal';
import { useCallback } from 'react';
import { TermsOfUseContent } from './HomeInformation';

function HomeTutorial(props) {
    const {
        closeTutorial,
        setShowTutorialIcon,
        setShowInformationOutline,
    } = props;

    const dispatch = useDispatch()
    const detectMobile = useMobileDetect();

    const [page, setPage] = useState(1);
    const isMuted = useSelector((state) => state.sound.mute);

    const isMobile = detectMobile.isMobile();

    useEffect(() => {
        if (page === 4 && !isMobile) {
            setShowInformationOutline(false)
        }

        if (page === 5 && !isMobile) {
            setShowInformationOutline(true)
            setShowTutorialIcon(false);
        }

        if (page === 6 && !isMobile) {
            setShowInformationOutline(false)
            setShowTutorialIcon(true)
        }

        if (page === 7 && !isMobile) {
            setShowTutorialIcon(false);
        }
    }, [page])

    const overlayClassname = () => {
        if (page === 3) {
            return 'justifyLeft';
        }

        if (page === 4) {
            return 'justifyRight';
        }

        if (page === 5 || page === 6 ) {
            return 'invertColumn';
        }

        if (page === 7) {
            return 'justifyCenter';
        }
    } 

    const openURL = (url) => {
        window.open(url);
    }

    const _toggleShowInformation = useCallback(() => {
        if (!isMuted) {
            ButtonClickAudio.play();
        }
    
        dispatch(toggleShowInformation())
      }, [isMuted, ButtonClickAudio, dispatch]);


    return (
    <div>
        {page === 1 && <TutorialPopup
            title="SS Starlaunch: Home page" 
            text="Welcome to the SS Starlaunch Commander! This is where you will begin your journey into space."
            containerStyle={{top: 0, left: isMobile ? 0 :'50vw'}}
            maxPages={8}
            currentPage={page}
            closeTutorial={closeTutorial}
            nextClick={() => setPage(page + 1)} />}
        {page === 2 && <TutorialPopup
            title="SS Starlaunch" 
            text="This is the main ship, here you will find the main functionality like staking, missions and IDO launches"
            containerStyle={{top: 0, left: isMobile ? 0 :'25vw'}}
            maxPages={8}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}

        {page >= 3 && page <= 7 && <div className={'homeTutorialOverlay ' + overlayClassname()}>
            {page === 3 && <TutorialPopup
                title="Social Media" 
                text="If you want to keep yourself updated when there are new missions or IDOs, please follow our social media"
                containerStyle={{position: 'relative', marginTop: '100px', marginLeft: '250px'}}
                maxPages={8}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                     <div className='mobileSocialMediaRowTutorial'>
                        <div className="socialMediaBoxMobile" onClick={() => openURL('https://t.me/StarLaunchANN')}>
                            <TelegramIcon className="socialMediaIcon" />
                        </div>
                        <div className="socialMediaBoxMobile" onClick={() => openURL('https://medium.com/@StarLaunch')}>
                            <MediumIcon className="socialMediaIcon" />
                        </div>
                        <div className="socialMediaBoxMobile" onClick={() => openURL('https://twitter.com/StarLaunchSOL')}>
                            <TwitterIcon className="socialMediaIcon" />
                        </div>
                    </div>
                </TutorialPopup>}
            {page === 4 && <TutorialPopup
                title="Wallet" 
                text="To get started you need to connect your wallet from one of our supported wallet providers. It's also important to make sure you complete KYC with our connected partner FRACTAL. "
                containerStyle={{position: 'relative', marginTop: '100px', marginRight: '50px', }}
                maxPages={8}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                    <div className='tutorialWalletWrapperMobile'>
                        <WalletMultiButton />
                    </div>
                </TutorialPopup>}
            {page === 5 && <TutorialPopup
                title="Information" 
                text="Here you can find out more about StarLaunch and also a detailed cadet handbook if you get stuck on any of the steps."
                containerStyle={{position: 'relative', marginBottom: '80px', marginLeft: '100px', }}
                maxPages={8}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                <div className='mobileMenuMiscOption' >
                    <div className='menuOptionCorner'>
                        <InformationIcon className='menuOptionIcon' onClick={_toggleShowInformation} />
                    </div>
                    <span className='menuMiscOptionText'>About</span>
                    <div className='menuOptionCorner' />
                </div>
                </TutorialPopup>}
            {page === 6 && <TutorialPopup
                title="Help" 
                text="If you want to revisit this tutorial just press the help button here. There are different tutorial for each page so please press here if you have any questions"
                containerStyle={{position: 'relative', marginBottom: '80px', marginLeft: '100px', }}
                maxPages={8}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                    <div className='mobileMenuMiscOption' >
                        <div className='menuOptionCorner'>
                            <HelpIcon className='menuOptionIcon' />
                        </div>
                        <span className='menuMiscOptionText'>Tutorial</span>
                        <div className='menuOptionCorner' />
                    </div>
                </TutorialPopup>}
              
        {page === 7 && <>
            <div className='homeInformationContainer TermsofUseTutorial'>
                {TermsOfUseContent()}
            </div>
            <TutorialPopup
            title="Compliance" 
            text={`Lastly, Starlaunch is a fully compliant platform and abides by all applicable laws and regulations. Users from certain countries and regions are restricted from using our platform.\n\r\n\r This includes countries such as the United States. The full list can be found in our Terms of Use on the information page`}
            containerStyle={{position: 'relative',}}
            maxPages={8}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />
        </>}

       
            </div>}
        {page === 8 && <TutorialPopup
            title="Are you ready commander?" 
            text="You are all finished with the inital tutorial, remember that there are unique tutorials for each page so please revisit if you have any questions."
            containerStyle={{top: '0', left: '50vw'}}
            maxPages={8}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => closeTutorial()} />}
            
            
    </div>
    )
};

export default HomeTutorial;
