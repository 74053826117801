import React, { useCallback, useState, useRef, useEffect } from "react";
import useMobileDetect from 'use-mobile-detect-hook';
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT } from "../Button/Button";
import Property from "../Property/Property";
import BigNumber from "bignumber.js";
import styles from './CardVault.module.scss';
import moment from 'moment';
import Checkbox from "../Checkbox/Checkbox";
import UnlockIcon from '../../images/svg/unlockVault.svg';
import UnlockActiveIcon from '../../images/svg/unlockVault_active.svg';
import ClaimIcon from '../../images/svg/claimVault.svg';
import ClaimActiveIcon from '../../images/svg/claimVault_active.svg';
import RewardIcon from '../../images/svg/rewardHydrazine.svg';
import StarIcon from '../../images/svg/star.svg';
import TypeIcon from '../../images/svg/type.svg';
import TimerIcon from '../../images/svg/timer.svg';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import InformationIcon from "../../images/svg/information2.svg"
import Modal, { MODAL_TOOLTIP_ID } from "../Modal/Modal";
import * as constants from '../../constants/constants';
import TextInput from "../TextInput/TextInput";
import {Buffer} from 'buffer';

function CardVault(props) {
    const {
        vaultBox,
        claimVaultBox,
        closeBox,
        loading,
        restakeBox
    } = props;

    const detectMobile = useMobileDetect();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [agreeCheck, setAgreeCheck] = useState(false);
    const [agreeError, setAgreeError] = useState(false);

    const vault = useRef(null);
    const boxId = true ? String(vaultBox.account.ownerBoxId.toNumber()).padStart(5, '0') : "0";
    const currTime = Math.round(Date.now() / 1000)
    const timeNow = moment.unix(currTime).format("MMMM DD YYYY, h:mm:ss A");
    const createdAt = moment.unix(props.vaultBox.account.createdAt).format("MMMM DD YYYY, h:mm:ss A");
    const createdAtDate = moment.unix(props.vaultBox.account.createdAt).format("MMMM DD YYYY, h:mm:ss A");
    const unlockTimeSmall = moment.unix(props.vaultBox.account.unlockTime).format("MMMM DD YYYY");
    const unlockTimeFull = moment.unix(props.vaultBox.account.unlockTime).format("MMMM DD YYYY, h:mm:ss A");
    const unlockTimeFullLS = moment.unix(props.vaultBox.account.unlockTime).format("MMMM DD YYYY");
    const noPenalty = !new BigNumber(props.vaultBox.account.unstakePenalty).gt(0);

    useEffect(() => {
        if (show === false) {
            setShowMessage(false);
        }
    }, [show])

    const executeScroll = useCallback(() => {
        if (detectMobile.isMobile()) {
            vault.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [detectMobile])

    const handleClose = useCallback(() => {
        setShow(false);
        setShowMessage(false);
    }, []);

    const handleShow = useCallback(() => setShow(true), []);

    const claimBox = useCallback(() => {
        claimVaultBox([vaultBox]);
        handleClose();
    }, [vault, claimVaultBox])

    const handleCloseBox = () => {
        closeBox(vaultBox);
    }

    const restakeVaultBox = useCallback(() => {
        restakeBox(vaultBox);
    }, [vaultBox])

    const forceCloseBox = () => {
        if (agreeCheck) {
            closeBox(vaultBox);
        }
    }

    const unstakeAgree = (e) => {
        setAgreeCheck(!agreeCheck);
        setAgreeError(!agreeError);
    }

    const confirmButtonText = useCallback(() => {
        if (vaultBox.account.locked) {
            if (showMessage) {
                return "Confirm force unstake and claim"
            } else {
                if (noPenalty) {
                    return "Unstake and claim"
                }

                return "Force unstake and claim";
            }
        } else {
            return "Unstake and claim";
        }
    }, [showMessage, vaultBox]);

    const confirmButtonAction = useCallback(() => {
        setShowMessage("Lock");
        // if (vaultBox.account.locked) {
        // if (noPenalty) {
        //     return handleCloseBox();
        // }
        if (agreeCheck) {
            return forceCloseBox();
        } else {
            setAgreeError(true);
            return setShowMessage("Lock");
        }
        // } else {
        //     return handleCloseBox();
        // }
    }, [vaultBox, agreeCheck])

    const confirmButtonDisabled = useCallback(() => {
        return false;
        if (showMessage && !agreeCheck) {
            return true;
        } else {
            return false;
        }
    }, [showMessage, agreeCheck]);

    const isMobile = detectMobile.isMobile();

    // Calculate staked days
    const stakingType = Number(vaultBox.account.stakingType?.toString());
    let stakingDays = null;
    let starApr = null;

    const DECIMALS = 1000000;

    switch (stakingType) {
        case 1:
            stakingDays = 0;
            starApr = 0;
            break;
        case 2:
            stakingDays = 90;
            starApr = 0;
            break;
        case 3:
            stakingDays = 180;
            starApr = 15;
            break;
        case 4:
            stakingDays = 360;
            starApr = 25;
            break;
        default:
            if (vaultBox.account.locked) {
                stakingDays = 90;
                starApr = 0;
            } else {
                stakingDays = 0;
                starApr = 0;
            }
            break;
    }

    let amount = vaultBox ? new BigNumber(vaultBox.account.curStaked) : 0;
    let startTime = moment(new Date().toISOString(), moment.ISO_8601);
    let endTime = moment(unlockTimeFull, "MMMM DD YYYY, h:mm:ss A");
    let duration = moment.duration(endTime.diff(startTime));
    let daysPending = Math.max(Math.ceil(duration.asDays()), 0);
    let daysPassed = Math.ceil(Math.abs(stakingDays - daysPending));
    let i = (new BigNumber(1).plus(new BigNumber(starApr).dividedBy(new BigNumber(365)).dividedBy(100)).pow(daysPassed));
    let iExp = (new BigNumber(1).plus(new BigNumber(starApr).dividedBy(new BigNumber(365)).dividedBy(100)).pow(stakingDays));

    const finalAmount = () => {
        let finalAmountVar = new BigNumber(0);
        finalAmountVar = new BigNumber(amount).times(i.toFixed(DECIMALS));
        return finalAmountVar;
    }

    const finalExpectedAmount = () => {
        let finalExpectedAmountVar = new BigNumber(0);
        finalExpectedAmountVar = new BigNumber(amount).times(iExp.toFixed(DECIMALS));

        return finalExpectedAmountVar;
    }

    // console.log(finalAmount, props.vaultBox.account.unstakePenalty, finalAmount.minus(new BigNumber(props.vaultBox.account.unstakePenalty)).toFixed(2))
    // console.log(stakingDays, startTime.toLocaleString(), endTime.toLocaleString(), daysPending, daysPassed, amount.toFixed(2));
    // parseFloat(new BigNumber(vaultBox.account.pendingHydrazine).div(new BigNumber(2)).toFixed(2)).toLocaleString(2)

    return (
        <>
            <div className={styles.card}
                onClick={executeScroll}
                key={vaultBox.key}
                ref={vault}>
                <h3 className={styles.header}>Box {boxId}</h3>
                <div className={styles.propertyContainer}>
                    <Property title="staked" value={new BigNumber(vaultBox.account.curStaked).toFixed(2)} Icon={!isMobile ? StarIcon : null} />
                    {false && <Property title="n2h4 reward old" value={parseFloat(new BigNumber(vaultBox.account.pendingHydrazineOld).div(new BigNumber(4)).toFixed(5)).toLocaleString(2)} Icon={!isMobile ? RewardIcon : null} />}
                    {true && <Property title="n2h4 reward" value={parseFloat(new BigNumber(vaultBox.account.pendingHydrazine).toFixed(5)).toLocaleString(2)} Icon={!isMobile ? RewardIcon : null} />}
                    {false && <Property title="Claimed" value={parseFloat(new BigNumber(vaultBox.account.claimed).div(1000000).toFixed(5)).toLocaleString(2)} Icon={!isMobile ? RewardIcon : null} />}
                    {
                        vaultBox.account.locked ?
                            stakingDays ?
                                <Property title="type" value={`${stakingDays} days`} Icon={!isMobile ? TypeIcon : null} />
                                :
                                <Property title="type" value={`90 days`} Icon={!isMobile ? TypeIcon : null} />
                            :
                            <Property title="type" value="no lock" Icon={!isMobile ? TypeIcon : null} />
                    }
                    {(stakingType === 3 || stakingType === 4) && <Property
                        title="Stars Rewards"
                        className={styles.infoIconAdjustment}
                        value={`${finalAmount().minus(new BigNumber(amount)).toFixed(2)}/${finalExpectedAmount().minus(new BigNumber(amount)).toFixed(2)}`}
                        helpTooltip={`${finalAmount().minus(new BigNumber(amount)).toFixed(2)}  Accumulated Stars, ${finalExpectedAmount().minus(new BigNumber(amount)).toFixed(2)} is expected total`}
                        dataTip={`HEADER_VAULT_ID`}
                        Icon={!isMobile ? TypeIcon : null} />}
                    {
                        vaultBox.account.locked ?
                            stakingDays && vaultBox.account.stakingType ?
                                <Property title="Stars Apr" value={`${starApr} %`} Icon={!isMobile ? TypeIcon : null} />
                                :
                                null
                        :
                        null
                    }                
                    {props.vaultBox.account.createdAt && <Property title="Created At" value={createdAtDate}  Icon={!isMobile ? TimerIcon : null} />}
                    {false  && <Property title="Unlock time" value={unlockTimeFull}  Icon={!isMobile ? TimerIcon : null} />} 
                    {true && vaultBox.account.locked  && <Property title="Expiry time" value={unlockTimeFullLS}  Icon={!isMobile ? TimerIcon : null} />} 
                    {false && <Property title="Current Time" value={timeNow}  Icon={!isMobile ? RewardIcon : null} />}
                </div>         
                <div className={styles.buttonContainer}>
                    {
                        isMobile
                        &&
                        <>
                            <Button text="enter"  onClick={handleShow} Icon={null} />
                            <Button text="claim n2h4"  onClick={claimBox} Icon={ null}  />
                            {
                               !vaultBox.account.stakingType && <Button text="Restake" onClick={restakeVaultBox} Icon={null} />
                            }
                        </>
                    }
                    {
                        !isMobile
                        &&
                        <>
                            <Button text="enter vault"  onClick={handleShow} Icon={UnlockIcon} IconActive={UnlockActiveIcon} />
                            <Button text="claim n2h4"  onClick={claimBox} Icon={ClaimIcon} IconActive={ClaimActiveIcon} />
                            {
                               !vaultBox.account.stakingType && <Button text="Restake"  onClick={restakeVaultBox} Icon={null} />
                            }
                        </>
                    }
                </div>   
            </div>
            <Modal
                show={show}
                setShow={setShow}
                exit
                header={isMobile ? `Box ${boxId}` : ''}
                confirmButtonText={confirmButtonText()}
                onConfirm={confirmButtonAction}
                confirmButtonDisabled={confirmButtonDisabled()}
                centered>
                {!isMobile && loading && <div className={styles.loaderOverLay}><LoadingSpinner local={true} /></div>}
                {
                    !isMobile
                    &&
                    <h3 className={styles.modalHeader}>
                        Fusion Box {boxId}
                    </h3>
                }

                <div>
                    {
                        !showMessage
                            ?
                            <div className={styles.propertysVaultContainer}>
                                <Property title="stars staked" value={new BigNumber(props.vaultBox.account.curStaked).toFixed(2)} />
                                <Property title="hydrazine to claim" value={new BigNumber(props.vaultBox.account.pendingHydrazine).toFixed(2)} />
                                {vaultBox.account.locked ?
                                    stakingDays ?
                                        <Property title="type" value={`${stakingDays} days`} Icon={!isMobile ? TypeIcon : null} />
                                        :
                                        <Property title="type" value={`90 days`} Icon={!isMobile ? TypeIcon : null} />
                                    :
                                    <Property title="type" value="no lock" />}
                                {vaultBox.account.locked && <Property title="Unlock time" value={unlockTimeFull} />}
                            </div>
                            :
                            <>
                                <div className={styles.forceCloseBoxSummary}>
                                    <div className={styles.info}>
                                        <div>
                                            <span className={styles.modalHelper}>
                                                vault id
                                                <InformationIcon className={styles.modalIcon}
                                                    data-for={MODAL_TOOLTIP_ID}
                                                    data-tip={constants.VAULT_ID_TOOLTIP} />
                                            </span>
                                            <TextInput id="box" value={boxId} disabled />
                                        </div>
                                        <div>
                                            <span className={styles.modalHelper}>
                                                $STARS AMOUNT
                                                <InformationIcon className={styles.modalIcon}
                                                    data-for={MODAL_TOOLTIP_ID}
                                                    data-tip={constants.VAULT_ID_TOOLTIP} />
                                            </span>
                                            <TextInput id="box" value={amount.toFixed(2)} disabled />
                                        </div>
                                        {vaultBox.account.locked &&
                                            <div>
                                                <span className={styles.modalHelper}>
                                                    Lock time
                                                    <InformationIcon className={styles.modalIcon}
                                                        data-for={MODAL_TOOLTIP_ID}
                                                        data-tip={constants.VAULT_ID_TOOLTIP} />
                                                </span>
                                                <TextInput id="box" value={`${stakingDays} days`} disabled />
                                            </div>
                                        }
                                        {vaultBox.account.locked &&
                                            <div>
                                                <span className={styles.modalHelper}>
                                                    Days locked
                                                    <InformationIcon className={styles.modalIcon}
                                                        data-for={MODAL_TOOLTIP_ID}
                                                        data-tip={constants.VAULT_ID_TOOLTIP} />
                                                </span>
                                                <TextInput id="box" value={daysPassed} disabled />
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.stakeSummary}>
                                        <h2>
                                            $STARS PENALTY
                                        </h2>
                                        <div className={styles.stakeProperty}>
                                            <span className={styles.propertyTitle}>
                                                Amount Stars staked
                                                <InformationIcon className={styles.modalIcon}
                                                    data-for={MODAL_TOOLTIP_ID}
                                                    data-tip={'This is the amount of STARS you staked'} />
                                            </span>
                                            <span className={styles.propertyValue}>{amount.toFixed(2)}</span>
                                        </div>
                                        {vaultBox.account.locked &&
                                            <div className={styles.stakeProperty}>
                                                <span className={styles.propertyTitle}>
                                                    Amount Stars Accumalated
                                                    <InformationIcon className={styles.modalIcon}
                                                        data-for={MODAL_TOOLTIP_ID}
                                                        data-tip={'This is the amount of STARS collected as rewards till date'} />
                                                </span>
                                                <span className={styles.propertyValue}>{finalAmount().minus(new BigNumber(amount)).toFixed(2)}</span>
                                            </div>
                                        }
                                        <div className={styles.stakeProperty}>
                                            <span className={styles.propertyTitle}>
                                                Unclaimed Hydrazine
                                                <InformationIcon className={styles.modalIcon}
                                                    data-for={MODAL_TOOLTIP_ID}
                                                    data-tip={'This is the amount of N2H4 available for you to claim'} />
                                            </span>
                                            <span className={styles.propertyValue}>{parseFloat(new BigNumber(vaultBox.account.pendingHydrazine).toFixed(2)).toLocaleString(2)}</span>
                                        </div>
                                        {vaultBox.account.locked &&
                                            <div className={styles.stakeProperty}>
                                                <span className={styles.propertyTitle}>
                                                    Early Unstake
                                                    <InformationIcon className={styles.modalIcon}
                                                        data-for={MODAL_TOOLTIP_ID}
                                                        data-tip={'Number of days pending till vault closure'} />
                                                </span>
                                                <span className={styles.propertyValueRed}>{`${daysPending} Days`}</span>
                                            </div>
                                        }
                                        {vaultBox.account.locked &&
                                            <div className={styles.stakeProperty}>
                                                <span className={styles.propertyTitle}>
                                                    Penalty for unstaking
                                                    <InformationIcon className={styles.modalIcon}
                                                        data-for={MODAL_TOOLTIP_ID}
                                                        data-tip={'If you unstake now, these stars will be burned as penalty'} />
                                                </span>
                                                <span className={styles.propertyValueRed}>{new BigNumber(props.vaultBox.account.unstakePenalty).gt(0) ? new BigNumber(props.vaultBox.account.unstakePenalty).toFixed(4) : 0}</span>
                                            </div>
                                        }
                                        <div className={styles.stakeProperty}>
                                            <span className={styles.propertyTitle}>
                                                Unstake now and receive:
                                                <InformationIcon className={styles.modalIcon}
                                                    data-for={MODAL_TOOLTIP_ID}
                                                    data-tip={'Amount of tokens you will receive if you unstake now'} />
                                            </span>
                                            <div className={styles.receiveProperty}>
                                                <StarIcon className={styles.modalIconOrange} /><span className={styles.propertyValueOrange}>{daysPending > 0 ? finalAmount().minus(new BigNumber(props.vaultBox.account.unstakePenalty)).toFixed(2) : finalAmount().toFixed(2)} STARS</span>
                                            </div>
                                            <div className={styles.receiveProperty}>
                                                <StarIcon className={styles.modalIconOrange} />
                                                <span className={styles.propertyValueOrange}>{parseFloat(new BigNumber(vaultBox.account.pendingHydrazine).toFixed(2)).toLocaleString(2)} N2H4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    daysPending <= 0 ?
                                        <Checkbox id="unstakeAgree" className={styles.textRed} onChange={unstakeAgree} checked={agreeCheck} label={`I FULLY UNDERSTAND AND WISH TO PROCEED`} />
                                        :
                                        agreeError ?
                                            <Checkbox id="unstakeAgree" className={styles.textRed} onChange={unstakeAgree} checked={agreeCheck} label={`I FULLY UNDERSTAND THAT FORCE UNSTAKING WILL INCUR ~` + new BigNumber(props.vaultBox.account.unstakePenalty).toFixed(4) + ` STARS AS PENALTY AND WISH TO PROCEED`} />
                                            :
                                            <Checkbox id="unstakeAgree" onChange={unstakeAgree} checked={agreeCheck} label={`I FULLY UNDERSTAND THAT FORCE UNSTAKING WILL INCUR ~` + new BigNumber(props.vaultBox.account.unstakePenalty).toFixed(4) + ` STARS AS PENALTY AND WISH TO PROCEED`} />
                                }
                            </>
                    }
                </div>
            </Modal>
        </>

    );
}

export default CardVault;
