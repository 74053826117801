import "./ProgressBar.scss";
import React from 'react';
function ProgressBar(props) {
  const {
    singleUser,
    percentage,
    className,
  } = props;

  return (
    <div className={`progress w-100 ${singleUser ? "single" : ""}`}>
      <div
        className={`progress-bar ${singleUser ? "single" : ""} ${className}`}
        role="progressbar"
        style={{ width: `${percentage}%` }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}

export default ProgressBar;
