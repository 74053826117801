import { createSlice } from '@reduxjs/toolkit'

export const SHIP_PART_REACTOR = 'reactor';
export const SHIP_PART_PLAYER_DASHBOARD = 'playerDashboard';
export const SHIP_PART_MISSION_DASHBOARD = 'missionDashboard';
export const SHIP_PART_IDO_PAGE = 'idoPage';

export const statistics = createSlice({
    name: 'statistics',
    initialState: {
        totalCryptoStats: {
            totalStarsStaked: 0,
            totalStarsDecayed: 0,
            totalHydrazine: 0,
            totalStarsPenalized: 0,
        },
        personalCryptoStats: {
            personallyStakedStars: null,
            personallyPendingHydrazine: 0,
            personallyPenalizedStars: 0,
            personallyDecayedStars: 0,
            personallyLockedStakedStars: null,
            personallyUnlockedStakedStars: null
        },
        walletStats: {
            starsInWallet: 0,
            hydrazineInWallet: 0,
        },
        cadetStats: {
            cadetLvl: 0,
            cadetMP: 0,
            cadetRank: 'CADET',
            cadetId: 0,
            cadetWallet: '',
            cadetEthereumWallet: '',
            cadetPolygonWallet: '',
            cadetArbitrumWallet: '',
            cadetSupraWallet: '',
            cadetSUIWallet: '',
            cadetAvalancheWallet: '',
            cadetDymensionWallet: '',
            cadetSkaleWallet: '',
            cadetBaseWallet: '',
        },
        userStats: {
            KYCStatus: 0,
            KYCCountry: '',
        },
        idoStats: {
            totalHydrazineBurnt: 0,
            totalRaised: 0,
            totalUSDCSpent: 0,
        },
        showCreateAccount: false,
        showCreateVaultAccount: false,
        showRestakeConfirmation: false,
        showCreateIdoAccount: false,
        showCreateNodeIdoAccount: false,
    },
    reducers: {
        setTotalCryptoStats: (state, object) => {
            state.totalCryptoStats.totalStarsPenalized = object.payload.totalStarsPenalized;
            state.totalCryptoStats.totalStarsStaked = object.payload.totalStarsStaked;
            state.totalCryptoStats.totalStarsDecayed = object.payload.totalStarsDecayed;
            state.totalCryptoStats.totalHydrazine = object.payload.totalHydrazine;
        },
        setPersonalCryptoStats: (state, object) => {
            state.personalCryptoStats.personallyStakedStars = object.payload.personallyStakedStars;
            state.personalCryptoStats.personallyLockedStakedStars = object.payload.personallyLockedStakedStars;
            state.personalCryptoStats.personallyUnlockedStakedStars = object.payload.personallyUnlockedStakedStars;
            state.personalCryptoStats.personallyPendingHydrazine = object.payload.personallyPendingHydrazine;
        },
        setVaults: (state, object) => {
            state.vault.vaultBoxes = object.payload.vaultBoxes;
            state.vault.newBoxId = object.payload.newBoxId;
            state.vault.mainVault = object.payload.mainVault;
        },
        setIDOStats: (state, object) => {
            state.idoStats.totalHydrazineBurnt = object.payload.totalHydrazineBurnt;
            state.idoStats.totalRaised = object.payload.totalRaised;
            state.idoStats.totalUSDCSpent = object.payload.totalUSDCSpent;
        },
        setShowCreateAccount: (state, bool) => {
            state.showCreateAccount = bool.payload;
        },
        setShowCreateVaultAccount: (state, bool) => {
            state.showCreateVaultAccount = bool.payload;
        },
        setRestakeConfirmation: (state, bool) => {
            state.showRestakeConfirmation = bool.payload;
        },
        setShowCreateIdoAccount: (state, bool) => {
            state.showCreateIdoAccount = bool.payload;
        },
        setShowCreateNodeIdoAccount: (state, bool) => {
            state.showCreateNodeIdoAccount = bool.payload;
        },
        setStatistics: (state, statistics) => {
            const totalStats = statistics.payload.totalCryptoStats;
            state.totalCryptoStats.totalStarsPenalized = totalStats.totalStarsPenalized;
            state.totalCryptoStats.totalStarsStaked = totalStats.totalStarsStaked;
            state.totalCryptoStats.totalStarsDecayed = totalStats.totalStarsDecayed;
            state.totalCryptoStats.totalHydrazine = totalStats.totalHydrazine;
            state.totalCryptoStats.totalStarsPenalized = totalStats.totalStarsPenalized;

            const personalStats = statistics.payload.personalCryptoStats;
            state.personalCryptoStats.personallyStakedStars = personalStats.personallyStakedStars;
            state.personalCryptoStats.personallyLockedStakedStars = personalStats.personallyLockedStakedStars;
            state.personalCryptoStats.personallyUnlockedStakedStars = personalStats.personallyUnlockedStakedStars;
            state.personalCryptoStats.personallyPendingHydrazine = personalStats.personallyPendingHydrazine > 100 ?  personalStats.personallyPendingHydrazine.toString().slice(0, 9) : personalStats.personallyPendingHydrazine.toString().slice(0, 5);
            state.personalCryptoStats.personallyPenalizedStars = personalStats.personallyPenalizedStars;
            state.personalCryptoStats.personallyDecayedStars = personalStats.personallyDecayedStars;

            const walletStats = statistics.payload.wallet;
            state.walletStats.starsInWallet = walletStats.starsInWallet;
            state.walletStats.hydrazineInWallet = walletStats.hydrazineInWallet;

            const cadetStats = statistics.payload.cadetStats;
            state.cadetStats.cadetLvl = cadetStats.cadetLvl;
            state.cadetStats.cadetMP = cadetStats.cadetMP;
            state.cadetStats.cadetRank = cadetStats.cadetRank;
            state.cadetStats.cadetId = cadetStats.cadetId;
            state.cadetStats.cadetWallet = cadetStats.cadetWallet;

        },

        setCadetWallets: (state, data) => {
            const wallets = data.payload
            state.cadetStats.cadetEthereumWallet = wallets.cadetEthereumWallet;
            state.cadetStats.cadetPolygonWallet = wallets.cadetPolygonWallet;
            state.cadetStats.cadetArbitrumWallet = wallets.cadetArbitrumWallet;
            state.cadetStats.cadetSupraWallet = wallets.cadetSupraWallet;
            state.cadetStats.cadetSUIWallet = wallets.cadetSUIWallet;
            state.cadetStats.cadetAvalancheWallet = wallets.cadetAvalancheWallet;
            state.cadetStats.cadetDymensionWallet = wallets.cadetDymensionWallet;
            state.cadetStats.cadetSkaleWallet = wallets.cadetSkaleWallet;
            state.cadetStats.cadetBaseWallet = wallets.cadetBaseWallet;
        },
        setKYCStats: (state, data) => {
            const stats = data.payload
            state.userStats.KYCStatus = stats.KYCStatus;
            state.userStats.KYCCountry = stats.KYCCountry;
            state.userStats.tokenExists = stats.tokenExists;
        }
    },
})

export const { 
    setShowCreateIdoAccount,
    setShowCreateNodeIdoAccount,
    setShowCreateVaultAccount,
    setRestakeConfirmation,
    setShowCreateAccount,
    setIDOStats,
    setVaults,
    setPersonalCryptoStats,
    setStatistics,
    setCadetWallets,
    setKYCStats,
    setTotalCryptoStats } = statistics.actions

export default statistics.reducer
