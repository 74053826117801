import TelegramIcon from '../../images/svg/telegram.svg'
import MediumIcon from '../../images/svg/medium.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import ProgressButtonHover from "../../Sounds/progress-button-hover.mp3"
import "./ClaimCard.scss";
import { useSelector } from 'react-redux';
import { ProgressButtonClickAudio } from '../Button/Button';
import React from 'react';

export const ProgressButtonHoverSound = new Audio(ProgressButtonHover);

function ClaimCard(props) {
    const { 
        title,
        description,
        logo,
        telegramLink,
        mediumLink,
        twitterLink,
        websiteLink,
        tranches,
        tokensDistributed,
        tokensClaimed,
        onClick,
    } = props;

    const isMuted = useSelector((state) => state.sound.mute);

    const openURL = (url) => {
        window.open(url);
    }

    return (
        <div className="claimCardContainer">
            <div className="claimCardHeader">
                <img className='idoLogo' src={logo} alt={title} />
                {title}
                <div className='flex-divider' />
                {telegramLink && <div className="idoSocialBox" onClick={() => openURL(telegramLink)} >
                    <TelegramIcon className='idoSocialIcon' />
                </div> }
                {mediumLink && <div className="idoSocialBox" onClick={() => openURL(mediumLink)}>
                    <MediumIcon className='idoSocialIcon' />
                </div> }
                {twitterLink && <div className="idoSocialBox" onClick={() => openURL(twitterLink)}>
                    <TwitterIcon className='idoSocialIcon' />
                </div> }
            </div>
            <div className="claimCardBody">
                {description}
            </div>
            <div className="claimCardFooter">
                <div className='flex-column'>
                  <div className='valuePair'>
                        <span className='greyTitle'>TOTAL TRANCHES</span>
                        <span   className='value'>{tranches}</span>
                    </div>
                    {tokensDistributed && <div className='valuePair'>
                        <span className='greyTitle'>TOKENS IN DISTRIBUTION</span>
                        <span   className='value'>{tokensDistributed}</span>
                    </div>}
                </div>
                <div className='flex-column'>
                {tokensClaimed && <div className='valuePair'>
                        <span className='greyTitle'>TOKENS CLAIMED</span>
                        <span className='value'>{tokensClaimed}</span>
                    </div> } 
                </div> 
            </div>
        </div>
    );
}

export default ClaimCard;
