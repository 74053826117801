// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RwcbrKThYqTBhj4rx5U1 {
  display: flex;
  flex-direction: column;
  height: 50%;
  position: relative;
  margin-top: 14px;
  margin-bottom: 26px;
  width: 100%; }

.UoMezNVKkgvzjVMIEKPm {
  color: #C4C4C4;
  font-size: 14px;
  font-family: Chakra Petch;
  position: absolute;
  text-transform: uppercase;
  top: -16px; }

.Kzrr5E4mLtC2UONCIy9n {
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: Chakra Petch;
  background: transparent;
  border: initial;
  border-bottom: 2px solid #C4C4C4; }
  .Kzrr5E4mLtC2UONCIy9n::placeholder {
    opacity: 0.5; }
  .Kzrr5E4mLtC2UONCIy9n:focus {
    outline: none; }
    .Kzrr5E4mLtC2UONCIy9n:focus + .UoMezNVKkgvzjVMIEKPm {
      color: white; }
  .Kzrr5E4mLtC2UONCIy9n:disabled {
    color: #C4C4C4; }
`, "",{"version":3,"sources":["webpack://./src/Components/Icon/Icon.module.scss"],"names":[],"mappings":"AACA;EACC,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW,EAAA;;AAGZ;EACC,cAAc;EACX,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,UAAU,EAAA;;AAGd;EACC,YAAY;EACT,eAAe;EAClB,iBAAiB;EACd,yBAAyB;EACzB,uBAAuB;EACvB,eAAe;EACf,gCAAgC,EAAA;EAPpC;IASE,YAAY,EAAA;EATd;IAaE,aAAa,EAAA;IAbf;MAgBG,YAAY,EAAA;EAhBf;IAqBE,cAAa,EAAA","sourcesContent":["\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\theight: 50%;\r\n\tposition: relative;\r\n\tmargin-top: 14px;\r\n\tmargin-bottom: 26px;\r\n\twidth: 100%;\r\n}\r\n\r\n.label {\r\n\tcolor: #C4C4C4;\r\n    font-size: 14px;\r\n    font-family: Chakra Petch;\r\n    position: absolute;\r\n    text-transform: uppercase;\r\n    top: -16px;\r\n}\r\n\r\n.input {\r\n\tcolor: white;\r\n    font-size: 16px;\r\n\tfont-weight: bold;\r\n    font-family: Chakra Petch;\r\n    background: transparent;\r\n    border: initial;\r\n    border-bottom: 2px solid #C4C4C4;\r\n\t&::placeholder {\r\n\t\topacity: 0.5;\r\n\t}\r\n\r\n\t&:focus {\r\n\t\toutline: none;\r\n\r\n\t\t& + .label {\r\n\t\t\tcolor: white;\r\n\t\t}\r\n\t}\r\n\r\n\t&:disabled {\r\n\t\tcolor:#C4C4C4; \r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `RwcbrKThYqTBhj4rx5U1`,
	"label": `UoMezNVKkgvzjVMIEKPm`,
	"input": `Kzrr5E4mLtC2UONCIy9n`
};
export default ___CSS_LOADER_EXPORT___;
