import React, { useEffect, useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { Provider } from 'react-redux'
import store from './store'
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import {
  Switch,
  Route
} from "react-router-dom";

import "./App.css";
import Header from "./Components/Header/Header.jsx";
import Home from "./screens/Home/Home.jsx";
import Vault, {connectNotAuthorizedWalletText, connectWalletText, connectWalletTitle} from "./screens/Vault/Vault.jsx";
import PlayerDashboard from "./screens/PlayerDashboard/PlayerDashboard"
import IdoPage from "./screens/IdoPage/IdoPage";
import IdoDetails from "./screens/IdoDetails/IdoDetails";
import WalletNotConnectedIcon from './images/svg/walletNotConnected.svg';

import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import ClaimsPage from './screens/ClaimsPage/ClaimsPage';
import {getApiUrl, getRpcUrl, doesURLInclude} from './Helpers/helper';
import { tokenData } from './constants/testTokenData';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import Swap from "./screens/Swap/Swap";
import SwapPage from "./screens/Swap/Swap";
import IdoNodeSaleDetails from "./screens/IdoDetails/IdoNodeSaleDetails";
import InfoCard from "./Components/InfoCard/InfoCard";
import KycAuthentication from './screens/Kyc/KycAuthentication.jsx';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export const PATH_HOME = '/';
export const PATH_VAULT = '/vault';
export const PATH_PLAYER_DASHBOARD = '/player-dashboard';
export const PATH_MISSION_DASHBOARD = '/mission-dashboard';
export const PATH_CLAIMS_PAGE = '/claims';
export const PATH_IDO_PAGE = '/sales';
export const PATH_IDO_DETAILS = '/sale-detail'
export const PATH_SWAP_PAGE = '/swap';
export const PATH_KYC_PAGE = '/kyc';
export const PATH_NODE_SALE_DETAILS = '/node-sale-detail';


function App() {
  const location = useLocation();
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = getRpcUrl();

  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new BackpackWalletAdapter(),
    new SolflareWalletAdapter()
  ], [network]);


  const [vaultState, setVaultState] = useState({
    userBoxes: [],
    newBoxId: 0,
    mainVault: null,
  });

  const [provider, setProvider] = useState();
  const [authorized, setAuthorized] = useState(doesURLInclude('app.starlaunch') || doesURLInclude('localhost') || doesURLInclude('dev-stars') || doesURLInclude('192.168'));

  return (
    <CacheBuster currentVersion={packageInfo.version} isEnabled>
      <ConnectionProvider
        endpoint={endpoint}
      >
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Provider store={store}>
              <Header
                setVaultState={setVaultState}
                setProvider={setProvider}
                provider={provider}
                authorized={authorized}
                setAuthorized={setAuthorized} />
              {!authorized && <InfoCard
                text={provider?.wallet?.publicKey == null ? connectWalletText() : connectNotAuthorizedWalletText() }
                title={connectWalletTitle()}
                icon={WalletNotConnectedIcon} />}
              <Switch location={location} key={location.pathname}>
                  <Route path={PATH_HOME} exact component={Home} />
                  <Route path={PATH_VAULT} exact>
                    <Vault vaultState={vaultState} provider={provider} />
                  </Route>
                  <Route path={PATH_PLAYER_DASHBOARD} exact>
                    <PlayerDashboard provider={provider} />
                  </Route>
                  <Route path={PATH_CLAIMS_PAGE} exact component={ClaimsPage} />
                  <Route path={PATH_KYC_PAGE} exact>
                    <KycAuthentication provider={provider} />
                  </Route>
                  <Route path={PATH_IDO_PAGE} exact>
                    <IdoPage provider={provider} />
                  </Route>
                  <Route path={PATH_IDO_PAGE + '/kyc'} exact>
                    <IdoPage provider={provider} />
                  </Route>
                 <Route path={PATH_NODE_SALE_DETAILS} exact>
                    <IdoNodeSaleDetails provider={provider} />
                  </Route>
                  <Route path={PATH_SWAP_PAGE} exact>
                    <SwapPage provider={provider} />
                  </Route>
                  <Route path={`${PATH_IDO_DETAILS}/:idoName`} exact >
                    <IdoDetails provider={provider} />
                  </Route>
                </Switch>
              <Toaster />
            </Provider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </CacheBuster>
  );
}

export default App;
