import React, {useState, useEffect, useMemo, useCallback} from 'react';

import "./IdoPage.scss";
import { useHistory } from "react-router-dom";
import IDOPageVideo from '../../images/idoPageBackground.webm';
import IDOPageVideoMobile from '../../images/idoPageBackgroundMobile.webm';
import IDOPageVideoMobileMp4 from '../../images/idoPageBackgroundMobile.mp4';
import PageSelector from '../../Components/PageSelector/PageSelector';
import IdoCard from '../../Components/IdoCard/IdoCard';
import { PATH_IDO_DETAILS } from '../../App';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import useMobileDetect from 'use-mobile-detect-hook';
import { useWallet } from '@solana/wallet-adapter-react';
import BigNumber from 'bignumber.js';
import {isAddress} from "web3-validator";
import {calculateBlockchain, hasValue, redirectToIdoDetails} from "../../Helpers/helperFunctions";
import * as helpers from "../../Helpers/helper";

export const IDO_TYPE_REFUND = 'IDO_TYPE_REFUND';
export const IDO_TYPE_ONGOING = 'IDO_TYPE_ONGOING';
export const IDO_TYPE_UPCOMING = 'IDO_TYPE_UPCOMING';
export const IDO_TYPE_COMPLETED = 'IDO_TYPE_COMPLETED';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}



function IdoPage() {
  const history = useHistory();
  const detectMobile = useMobileDetect();

  const [idoType, setIdoType] = useState(IDO_TYPE_ONGOING);
  const [isLoading, setIsLoading] = useState(true);

  const refundIDOs = useSelector((state) => state.idoData.refundIDOs);
  const upcomingIDOs = useSelector((state) => state.idoData.upcomingIDOs);
  const ongoingIDOs = useSelector((state) => state.idoData.ongoingIDOs);
  const completedIDOs = useSelector((state) => state.idoData.completedIDOs);

  useEffect(() => {
    if (ongoingIDOs.length === 0 && upcomingIDOs.length > 0) {
      setIdoType(IDO_TYPE_UPCOMING)
    }

    if (ongoingIDOs.length === 0 && upcomingIDOs.length === 0) {
      setIdoType(IDO_TYPE_COMPLETED)
    }
  }, [upcomingIDOs, ongoingIDOs])

  const mappedIDOs = useCallback((idoList) => {
    
    return idoList.map((ido, index) => {
          return (
            <IdoCard 
              idoInfo={ido}
              key={index}
              onClick={() => redirectToIdoDetails(ido, history)}
            />)
    })
  }, []);

  const options = [
    {
      key: IDO_TYPE_ONGOING,
      label: 'ONGOING',
      sideValue: ongoingIDOs.length,
    },
    {
      key: IDO_TYPE_UPCOMING,
      label: 'UPCOMING',
      sideValue: upcomingIDOs.length,
    },
    {
      key: IDO_TYPE_COMPLETED,
      label: 'COMPLETED',
      sideValue: completedIDOs.length,
    },
    {
      key: IDO_TYPE_REFUND,
      label: 'REFUND',
      sideValue: refundIDOs.length,
    }
  ];
 
  return (
    <main
    className='idoPageMain'>
      <div className='idoOverlay' />
      <div className='idoPageContainer'>
        <PageSelector options={options} value={idoType} setValue={setIdoType} disableIfEmpty />
        <div className='idoCardsContainer'>
          {idoType === IDO_TYPE_ONGOING && mappedIDOs(ongoingIDOs)}
          {idoType === IDO_TYPE_UPCOMING && mappedIDOs(upcomingIDOs)}
          {idoType === IDO_TYPE_COMPLETED && mappedIDOs(completedIDOs)}
          {idoType === IDO_TYPE_REFUND && mappedIDOs(refundIDOs)}
        </div>
        
      </div>
      
      {!detectMobile.isMobile() && 
      <video 
      className='backgroundVideo' 
      autoPlay 
      loop 
      muted
      playsInline
      onLoadedData={() => setIsLoading(false)}
      preload="auto" >
          <source src={IDOPageVideo} type='video/webm' />
      </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
      <video 
      className='backgroundVideo' 
      autoPlay 
      loop 
      muted
      playsInline 
      onLoadedData={() => setIsLoading(false)} >
          <source src={IDOPageVideoMobile} type='video/webm' />
          <source src={IDOPageVideoMobileMp4} type='video/mp4' />
      </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
      <img className='backgroundVideo' src={IDOPageVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {isLoading && <LoadingSpinner />}
    </main>
  );
}

export default IdoPage;
