import BigNumber from "bignumber.js";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY } from "../../Components/Button/Button";
import LoadingSpinner, { LOADING_SPINNER_MEDIUM } from "../../Components/LoadingSpinner/LoadingSpinner";
import './BuyAllocationForm.scss';
import _ from 'lodash';
import toast from "react-hot-toast";
import { errorToast } from "../../Helpers/toastCSS";
import { max } from "moment/moment";
import TextInput from "../../Components/TextInput/TextInput";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { hasValue } from "../../Helpers/helperFunctions";
import { openURL } from "../../Helpers/helper";
import { getTokenAccountsByOwner } from "../../utils";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

const USDC_MINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
function BuyAllocationForm(props) {
    const {
        allocation,
        poolData,
        participantInfo,
        allocationInfo,
        userParticipationHydrazine,
        buyAllocation,
        buyFcfsAllocation,
        inputRef,
        burnHydrazine,
        isFCSFS,
        isBurn,
        idoInfo,
        isTutorial,
        maxBuy,
        transactionErrorMessage,
        hasIdoAccount,
        boughtInitial,
        loadingTransaction,
    } = props;

    const wallet = useWallet();
    const { connection } = useConnection();

    const [value, setValue] = useState('');
    const [usdcBalance, setUsdcBalance] = useState();
    const [maxAllowed, setMaxAllowed] = useState('');
    const [stakingLimit, setStakingLimit] = useState(null);
    const walletStats = useSelector((state) => state.statistics.walletStats);
    const personalCryptoStats = useSelector((state) => state.statistics.personalCryptoStats);

    const notEnoughUSDCInWallet = useMemo(() => {
        if (hasValue(usdcBalance) && hasValue(value)) {
            return parseFloat(value) > usdcBalance;
        }

        return false;
    }, [participantInfo, poolData, usdcBalance, value])

    const buyButtonDisabled = stakingLimit || notEnoughUSDCInWallet

    useEffect(() => {
        if (Boolean(idoInfo?.stakingRule)) {
            setStakingLimit(personalCryptoStats?.personallyLockedStakedStars < Number(idoInfo?.stakingLimit))
        } else {
            setStakingLimit(null)
        }

    }, [idoInfo])

    function fillMaxAllocation() {
        if (isTutorial) {
            return;
        }

        let overallMax = parseFloat(new BigNumber(allocation.amount).div(new BigNumber(1000000)).toFixed(3, 1));
        let alreadyBought = parseFloat(new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3, 1));
        setValue(((overallMax - 0.001) - alreadyBought).toFixed(3));
    }

    function fillMaxFcfsAllocation() {
        if (isTutorial) {
            return;
        }
        let overallMax = new BigNumber(maxBuy).div(new BigNumber(1000000));
        let alreadyBought = new BigNumber(participantInfo?.boughtOpen.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000));

        setValue(Math.abs(overallMax.minus(alreadyBought).toFixed(6, 1)));
    }

    function fillHydrazine() {
        if (walletStats.hydrazineInWallet) {
            const maxHydrazine = parseInt(new BigNumber(poolData.maxHydrazine).div(new BigNumber(100000000)).toFixed());
            if (maxHydrazine > walletStats.hydrazineInWallet) {
                setValue(Math.floor(walletStats.hydrazineInWallet));
            } else {
                setValue(maxHydrazine)
            }
        }
    }

    const _burnHydrazine = () => {
        if (isTutorial) {
            return;
        }
        burnHydrazine();
    }

    useEffect(() => {
        fetchTokensBalance();
    }, []);

    const fetchTokensBalance = async () => {
        const tokenAccs = await getTokenAccountsByOwner(connection, wallet.publicKey);
        const usdc = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === USDC_MINT)

        let usdcReturn = 0;


        if (usdc !== null && usdc.length !== 0) {
            const usdcBalance = await connection.getTokenAccountBalance(usdc[0].pubkey);

            usdcReturn = usdcBalance.value.uiAmount || 0;
        }

        return setUsdcBalance(usdcReturn);
    }

    const buyFcfsAllocationPercentage = () => {
        let overallMax = new BigNumber(maxBuy).div(new BigNumber(1000000));
        let alreadyBought = new BigNumber(participantInfo?.boughtOpen.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000));
        let maxAllowed = overallMax.minus(alreadyBought);
        if(Math.abs(new BigNumber(inputRef.current.value)) <= maxAllowed) {
            buyFcfsAllocation()
        } else {
            toast(`You already purchased ${alreadyBought.toFixed(2)}. Enter value less than ${maxAllowed.toFixed(2)}`, errorToast);
        }
    }

    const estimatedAllocation = useMemo(() => {
        const totalHydrazineBurned = poolData ? parseFloat(new BigNumber(poolData.totalHydrazine.toString()).div(new BigNumber(1000000)).div(new BigNumber(idoInfo?.factor))) : 0
        const raiseAmount = poolData ? parseFloat(new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(1000000)).toFixed(2)) : 0;
        const userParticipationHydrazine = participantInfo ? parseFloat(new BigNumber(participantInfo.burned.toString()).div(new BigNumber(1000000))) : 0;
        const estimatedAllocation = (raiseAmount / totalHydrazineBurned) * ((hasValue(value) ? parseFloat(value) : 0) + userParticipationHydrazine);

        if (estimatedAllocation > (raiseAmount / 20)) {
            return raiseAmount / 20;
        }

        return estimatedAllocation;
    }, [poolData, idoInfo?.factor, participantInfo, value]);

    if (isBurn) {
        const maxHydrazineReached = poolData ? value !== '' && !(new BigNumber(poolData.totalHydrazine.toString()).plus(new BigNumber(value).times(new BigNumber(1000000))).div(new BigNumber(idoInfo.factor)).lte(new BigNumber(poolData.maxHydrazine.toString()))) : false;
        return (
            <>
                <div className="allocationFormContainer" >
                    <div className="allocationFormInnerContainer">
                        <div className="flex-column">
                            <span className="allocationTitle">CONTRIBUTE YOUR HYDRAZINE</span>
                        </div>
                        <div className="inputContainer">
                            <input className="allocationInput" value={value} onChange={(e) => setValue(e.target.value)} type="number" placeholder="Enter amount" ref={inputRef} />
                            {!(maxHydrazineReached) &&
                                <div className="maxButtonContainer">
                                    <span className="maxButton" onClick={fillHydrazine}>MAX</span>
                                </div>
                            }
                        </div>
                        {maxHydrazineReached && <span className="allocationTitleWarning">MAX HYDRAZINE REACHED</span>}
                        <Button buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} text="CONTRIBUTE" onClick={_burnHydrazine} disabled={maxHydrazineReached} />
                        {loadingTransaction && <LoadingSpinner local size={LOADING_SPINNER_MEDIUM} />}
                    </div>
                    <div className="allocationBurnCalculator">
                        <span className="allocationBurnInfoTitle">Total Estimated allocation:</span>
                        <span className="allocationBurnValue">{parseInt(estimatedAllocation).toLocaleString()} USDC</span>
                        <span className="allocationBurnCalculatorInfoText">This is based on the total contributed Hydrazine by everyone and lowers as more Hydrazine gets contributed to the IDO</span>
                    </div>
                    <div>
                        {stakingLimit && Boolean(idoInfo?.stakingRule) &&
                            <div style={{ color: '#F77F00', textAlign: 'left', fontWeight: 600, fontSize: 14 }}>
                                You have currently staked only {personalCryptoStats?.personallyLockedStakedStars} STARS in locked vaults. Stake {idoInfo?.stakingLimit - personalCryptoStats?.personallyLockedStakedStars} more to become eligible for IDO participation.
                            </div>
                        }
                    </div>
                    {idoInfo?.stakingRule && stakingLimit &&
                        <div style={{ marginTop: 10 }}>
                            <div style={{ color: '#FFF', textAlign: 'center', fontWeight: 600, fontSize: 16 }}>
                                Minimum guaranteed allocation for {value > 0 ? value : '0'} N2H4 burnt will be  {(poolData && participantInfo && new BigNumber(poolData.totalHydrazine.toString()).toFixed(2) > 0 && value > 0) ?
                                    (new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(poolData.maxHydrazine.toString()))).times(new BigNumber(value)).toFixed(2) : '0'}  USDC. This number can increase if the hydrazine cap isn’t reached.
                                <br />
                                <span style={{ color: '#FFF', fontWeight: 400, fontSize: 13 }}>Read more about the way we determine allocations <a href="https://starlaunch.gitbook.io/starlaunch-a-cadets-guide/the-basics/two-token-mechanics/calculating-allocations" target="_blank">here</a></span>
                            </div>
                        </div>
                    }
                    {/* <div style={{ display: 'flex',  width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
                        <div className="flex-column" style={{ color: 'white', fontSize: 14, fontWeight: 500, marginRight: 30 }}>
                            <span className="">Minimum Allocation</span>
                        </div>
                        <div className="inputContainer"  style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>
                           {poolData && participantInfo && new BigNumber(poolData.totalHydrazine.toString()).toFixed(2) > 0 && value > 0 &&
                        (new BigNumber(poolData.usdcAmount.toString()).div(new BigNumber(poolData.maxHydrazine.toString()))).times(new BigNumber(value)).toFixed(2)} USDC
                        </div>
                    </div> */}
                </div>
            </>
        )
    }

    return (
        <div className="allocationFormContainer" style={{ flexDirection: 'column' }}>
            {/* <div className="allocationExtraInformationContainer">
                <div className="allocationExtraInformationContainerRow">
                    <span className="allocationExtraInformationLabel">YOUR ALLOCATION:</span>
                    <span
                        className="allocationExtraInformationValue">{new BigNumber(allocation.amount).div(new BigNumber(1000000)).toFixed(3, 1)} USDC</span>
                </div>
                <div className="allocationExtraInformationContainerRow">
                    <span className="allocationExtraInformationLabel">PURCHASED:</span>
                    <span
                        className="allocationExtraInformationValue"> {participantInfo ? new BigNumber(participantInfo.bought.toString()).times(new BigNumber(poolData.price.toString())).div(new BigNumber(1000000000000)).toFixed(3, 1) : 0} USDC</span>
                </div>
                <div className="allocationExtraInformationContainerRow">
                    <span className="allocationExtraInformationLabel">USDC WALLET BALANCE:</span>
                    {notEnoughUSDCInWallet ?
                        <span className="allocationExtraInformationValueError">{usdcBalance}</span> :
                        <span className="allocationExtraInformationValue">{usdcBalance}</span>}
                </div>
            </div> */}
            <div className="allocationFormInnerContainer">
                <span className="allocationTitle">BUY ALLOCATION</span>
                <div className="inputContainer">
                    <input className="allocationInput" value={value} onChange={(e) => setValue(e.target.value)}
                        type="number" placeholder={'Enter the amount of USDC'} ref={inputRef} disabled={stakingLimit} />
                    {!(stakingLimit) && !isFCSFS &&
                        <div className="maxButtonContainer">
                            <span className="maxButton"
                                onClick={isFCSFS ? fillMaxFcfsAllocation : fillMaxAllocation}>MAX</span>
                        </div>
                    }
                </div>
                <Button buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} text="BUY"
                    onClick={isFCSFS ? buyFcfsAllocationPercentage : buyAllocation} disabled={buyButtonDisabled} />
                {loadingTransaction &&
                    <LoadingSpinner local size={LOADING_SPINNER_MEDIUM} containerStyles={{ width: 'auto' }} />}
            </div>
            {hasValue(transactionErrorMessage.message) && <div className="allocationErrorMessage">
                <h4 className="allocationErrorTitle"
                    onClick={() => openURL(transactionErrorMessage.url)}>{transactionErrorMessage.message + ' Transaction: ' + transactionErrorMessage.url}</h4>
            </div>}
            <div style={{}}>
                {idoInfo?.stakingRule && stakingLimit ?
                    <div style={{ color: '#F77F00', textAlign: 'left', fontWeight: 600 }}>
                        You have currently staked only {personalCryptoStats?.personallyLockedStakedStars} STARS in locked
                        vaults.
                        Stake {idoInfo?.stakingLimit - personalCryptoStats?.personallyLockedStakedStars} more to become
                        eligible
                        for IDO participation.
                    </div>
                    :
                    <div style={{ color: '#F77F00', textAlign: 'left', fontWeight: 600 }}>
                        {/* You have currently staked  {personalCryptoStats?.personallyLockedStakedStars} STARS in locked vaults. Congrats! You are eligible for participation */}
                    </div>
                }
            </div>
        </div>
    )
}

export default BuyAllocationForm;
