import React, {useCallback} from "react";
import styles from "./RadioButton.module.scss";
import CheckmarkIcon from "../../images/svg/checkmark.svg"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import InformationIcon from "../../images/svg/information2.svg"
import CheckboxClick from "../../Sounds/check-box-click.mp3"
import { useSelector } from "react-redux";

function RadioButton(props) {
    const {
        name,
        value,
        id,
        label,
        tooltip,
        tooltipId,
        checked,
        setValue,
        required = false,
    } = props;

    const CheckboxClickAudio = new Audio(CheckboxClick);

    const isMuted = useSelector((state) => state.sound.mute);

    const handleChange = useCallback((event) => {
        if (!isMuted) {
            CheckboxClickAudio.play();
        }
        if (setValue) {
            setValue(event.target.value);
        }

    }, [setValue, isMuted, CheckboxClickAudio])

    const labelClassName = checked ? styles.labelActive : styles.label

    return (
        <div className={styles.inputContainer}>
            <input id={id}
                value={value}
                name={name} 
                required={required}
                type="radio" 
                onChange={handleChange}
                className={styles.input}
                checked={checked}/>
            <CheckmarkIcon className={styles.checkmarkIcon} />
            <label htmlFor={value} className={styles.label}>
                {label}
            </label>         
            {
                tooltip 
                &&
                <InformationIcon className={styles.tooltipIcon} data-for={tooltipId} data-tip={tooltip} />                    
            }   
        </div>
    );
}

export default RadioButton;
