import { useSelector } from 'react-redux';
import React from "react";
import NavClick from '../../Sounds/progressButton.mp3';
import './PageSelector.scss';

function PageSelector(props) {
    const {
        options,
        value,
        setValue,
        selectorRef,
        disableIfEmpty = false,
    } = props;

    const isMuted = useSelector((state) => state.sound.mute);
    const navClick = new Audio(NavClick);

    const _onClick = (key, disabled) => {
        if (value !== key && !disabled) {
            setValue(key);
            if (!isMuted) {
                navClick.play();
            }
        }
    };

    const content = () => {
      return options.map(option => {
        if (option.sideValue) {
            return ( 
            <div 
            key={option.key}
            className={`pageType${value === option.key ? "Active" : ""}`} 
            onClick={() => _onClick(option.key, false)}>
                {option.label}
                <div className='flexDivider' />
                <div className='sideValue'>{option.sideValue}</div>
            </div> );
        }
        const disabled = disableIfEmpty === true || option.disabled === true;

        return <div className={`pageType${value === option.key ? "Active" : disabled ? "Disabled" : ""}`} onClick={() => _onClick(option.key, disabled)} key={option.key}>{option.label}</div>;
        })
    }

    return (
    <div className='pageTypeContainer' ref={selectorRef}>
       {content()}
    </div>
    )
}

export default PageSelector;
