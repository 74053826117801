import solanaLogo from '../images/sol_icon.png';
import ethereumLogo from '../images/eth_icon.png';
import polygonLogo from '../images/polygon_icon.png';
import arbitrumLogo from '../images/arbitrum_icon.png';
import supraLogo from '../images/supra_icon.png';
import suiLogo from '../images/SUI_icon.png';
import avalancheLogo from '../images/avalancheLogo.png';
import dymensionLogo from '../images/dymensionLogo.png';
import skaleLogo from '../images/skale.png';
import baseLogo from '../images/base_icon.png';
import {useCallback, useEffect, useRef} from "react";
import {PATH_IDO_DETAILS} from "../App";

export const BLOCKCHAIN_ETH = 'ETHEREUM';
export const BLOCKCHAIN_POLYGON = 'POLYGON';
export const BLOCKCHAIN_ARBITRUM = 'ARBITRUM';
export const BLOCKCHAIN_SUPRA = 'SUPRA';
export const BLOCKCHAIN_SUI = 'SUI';
export const BLOCKCHAIN_AVALANCHE = 'AVALANCHE';
export const BLOCKCHAIN_DYMENSION = 'DYMENSION';
export const BLOCKCHAIN_SKALE = 'SKALE';
export const BLOCKCHAIN_BASE = 'BASE';
export const BLOCKCHAIN_SOLANA = 'SOLANA';
export const BLOCKCHAIN_CLASSIFIED = 'CLASSIFIED';
export const BLOCKCHAIN_UNKNOWN = 'UNKNOWN';

export const calculateBlockchain = (ido) => {
    if (ido?.isEthereum === true) {
        return BLOCKCHAIN_ETH;
    }
    if (ido?.isPolygon === true) {
        return BLOCKCHAIN_POLYGON;
    }

    if (ido?.isArbitrum === true) {
        return BLOCKCHAIN_ARBITRUM;
    }

    if (ido?.isSupra) {
        return BLOCKCHAIN_SUPRA;
    }

    if (ido?.isSol === true) {
        return BLOCKCHAIN_SOLANA;
    }

    if (ido?.isSUI === true) {
        return BLOCKCHAIN_SUI;
    }

    if (ido?.isAvalanche === true) {
        return BLOCKCHAIN_AVALANCHE;
    }

    if (ido?.isDymension === true) {
        return BLOCKCHAIN_DYMENSION;
    }

    if (ido?.isSkale === true) {
        return BLOCKCHAIN_SKALE;
    }

    if (ido?.isBase === true) {
        return BLOCKCHAIN_BASE;
    }

    if (ido?.isClassified === true) {
        return BLOCKCHAIN_CLASSIFIED;
    }

    return BLOCKCHAIN_UNKNOWN;
};

export const getBlockchainLogo = ({ blockchain, ido}) => {
    const blockchainLocal = hasValue(blockchain) ? blockchain : calculateBlockchain(ido);

    const logoMap = {
        [BLOCKCHAIN_SOLANA]: solanaLogo,
        [BLOCKCHAIN_ETH]: ethereumLogo,
        [BLOCKCHAIN_POLYGON]: polygonLogo,
        [BLOCKCHAIN_ARBITRUM]: arbitrumLogo,
        [BLOCKCHAIN_SUPRA]: supraLogo,
        [BLOCKCHAIN_SUI]: suiLogo,
        [BLOCKCHAIN_AVALANCHE]: avalancheLogo,
        [BLOCKCHAIN_DYMENSION]: dymensionLogo,
        [BLOCKCHAIN_SKALE]: skaleLogo,
        [BLOCKCHAIN_BASE]: baseLogo,
        [BLOCKCHAIN_CLASSIFIED]: solanaLogo,
        [BLOCKCHAIN_UNKNOWN]: solanaLogo,
    }

    const logo = logoMap[blockchainLocal];

    if (logo) return logo;

    return '';
}

export const convertUnixToLocalDate = (unixNum) => {
    return new Date(new Date(unixNum * 1000).toString() + ' UTC');
}

export const hasValue = (s) => {
    return s !== null && typeof s !== 'undefined' && s !== '';
}

export function uint8ArrayToHexString(uint8Array) {
    let hexString = '0x';
    for (const e of uint8Array) {
        const hex = e.toString(16);
        hexString += hex.length === 1 ? `0${hex}` : hex;
    }
    return hexString;
}
export const isHexStrict = (hex) =>
    typeof hex === 'string' && /^((-)?0x[0-9a-f]+|(0x))$/i.test(hex);

export const isUint8Array = (data) =>
    data instanceof Uint8Array || data?.constructor?.name === 'Uint8Array';
export const isSUIAddress = (value, checkChecksum = true) => {
    if (typeof value !== 'string' && !isUint8Array(value)) {
        return false;
    }

    let valueToCheck = '';

    if (isUint8Array(value)) {
        valueToCheck = uint8ArrayToHexString(value);
    } else if (typeof value === 'string' && !isHexStrict(value)) {
        valueToCheck = value.toLowerCase().startsWith('0x') ? value : `0x${value}`;
    } else {
        valueToCheck = value;
    }

    // check if it has the basic requirements of an address
    if (!/^(0x)?[0-9a-f]{64}$/i.test(valueToCheck)) {
        return false;
    }
    // If it's ALL lowercase or ALL upppercase
    if (
        /^(0x|0X)?[0-9a-f]{64}$/.test(valueToCheck) ||
        /^(0x|0X)?[0-9A-F]{64}$/.test(valueToCheck)
    ) {
        return true;
        // Otherwise check each case
    }
    return true;
};

export const isSUPRAAddress = (value, checkChecksum = true) => {
    if (typeof value !== 'string' && !isUint8Array(value)) {
        return false;
    }

    let valueToCheck = '';

    if (isUint8Array(value)) {
        valueToCheck = uint8ArrayToHexString(value);
    } else if (typeof value === 'string' && !isHexStrict(value)) {
        valueToCheck = value.toLowerCase().startsWith('0x') ? value : `0x${value}`;
    } else {
        valueToCheck = value;
    }

    // check if it has the basic requirements of an address
    if (!/^(0x)?[0-9a-f]{64}$/i.test(valueToCheck)) {
        return false;
    }
    // If it's ALL lowercase or ALL upppercase
    if (
      /^(0x|0X)?[0-9a-f]{64}$/.test(valueToCheck) ||
      /^(0x|0X)?[0-9A-F]{64}$/.test(valueToCheck)
    ) {
        return true;
        // Otherwise check each case
    }
    return true;
};

export const redirectToIdoDetails = (ido, history) => {
    const path = ido.name.replace(/\s/g,'');
    const location = {
        pathname: `${PATH_IDO_DETAILS}/${path}`,
        state: ido,
    }
    history.push(location);
};

export const walletFormatting = (wallet, sliceOverride = 6) => {
    const isMobile = window.screen.width < 1000;
    if (wallet) {
        return wallet.slice(0, isMobile ? 4 : sliceOverride) + '...' + wallet.slice(wallet.length - (isMobile ? 4 : sliceOverride));
    }

    return 'Missing Wallet';
}

export const useDebouncedEffect = (effect, delay, deps) => {
    const callback = useRef();
    callback.current = effect;

    useEffect(() => {
        const handler = setTimeout(() => {
            if (callback.current) {
                callback.current();
            }
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [...(deps || []), delay]);
};
