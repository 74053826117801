 function parseIdlErrors (idl) {
  const errors = new Map();
  // 
  if (idl.types) {
    const idlErrors = idl?.types[0].type?.variants;
    idlErrors.forEach((e) => {
      let msg = e.msg ?? e.name;
      errors.set(e.code, msg);
    });
  }
  return errors;
}

export const getErrorMessage = (err, idl) => {
  
  // Handle Phantom errors
  if(err.toString().includes("WalletSignTransactionError")) {
    let components = err.toString().split("WalletSignTransactionError: ");
    if (components.length !== 2) return null;
    return components[1]
  }

  // Handle Custom errors
  if(err.toString().includes("custom")) {
    let components = err.toString().split("custom program error: ");
    if (components.length !== 2) return null;
    let errorCode;
    try {
      errorCode = parseInt(components[1]);
    } catch (parseErr) {
      console.log(parseErr)
      return null;
    }
    console.log(idl)
    const idlErrors = parseIdlErrors(idl);
    console.log(idlErrors)
    let errorMsg = idlErrors.get(errorCode);
    return errorMsg
  }

}