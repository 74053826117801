import { createSlice } from '@reduxjs/toolkit'

export const SHIP_PART_REACTOR = 'reactor';
export const SHIP_PART_PLAYER_DASHBOARD = 'playerDashboard';
export const SHIP_PART_CLAIMS_PAGE = 'claimsPage';
export const SHIP_PART_IDO_PAGE = 'idoPage';
export const SHIP_PART_SWAP_PAGE = 'swapPage';

export const shipParts = createSlice({
    name: 'shipParts',
    initialState: {
        reactor: false,
        playerDashboard: false,
        claimsPage: false,
        idoPage: false,
        swapPage: false,
        disableHover: false,
    },
    reducers: {
        hover: (state, shipPart) => {
            state[shipPart.payload] = true;
        },
        unHover: (state, shipPart) => {
            state[shipPart.payload] = false;
        },
        setDisableHover: (state, bool) => {
            state.disableHover = bool.payload;
        },
    },
})

export const { hover, unHover, setDisableHover } = shipParts.actions

export default shipParts.reducer
