// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nPyx2FCJOU6LhIj9ogw2 {
  display: flex;
  align-items: center;
  height: 50%;
  position: relative;
  margin-top: 14px;
  margin-bottom: 26px; }
  .nPyx2FCJOU6LhIj9ogw2 svg {
    display: none;
    transition: all 0.3s ease-in-out; }

.Tky56gyOxCVpbYleE8gR {
  color: #C4C4C4;
  font-size: 14px;
  font-family: "Chakra Petch";
  text-transform: uppercase;
  margin-left: 8px;
  transition: all 0.3s ease-in-out; }

.Fewk1oCxa4ErviB3ZGe6 {
  appearance: none;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #c4c4c4;
  transition: all 0.3s ease-in-out; }
  .Fewk1oCxa4ErviB3ZGe6:checked {
    border: 2px solid white;
    background-color: white; }
    .Fewk1oCxa4ErviB3ZGe6:checked ~ .Tky56gyOxCVpbYleE8gR {
      color: white; }
    .Fewk1oCxa4ErviB3ZGe6:checked ~ svg {
      display: block;
      position: absolute;
      left: 6px;
      fill: black; }
`, "",{"version":3,"sources":["webpack://./src/Components/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAEA;EACC,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB,EAAA;EANpB;IASE,aAAa;IACb,gCAAgC,EAAA;;AAIlC;EACC,cAAc;EACX,eAAe;EACf,2BAA2B;EAC3B,yBAAyB;EAC5B,gBAAgB;EAChB,gCAAgC,EAAA;;AAGjC;EACI,gBAAgB;EACnB,YAAY;EACT,kBAAkB;EAClB,yBAAyB;EAC5B,gCAAgC,EAAA;EALjC;IAQE,uBAAuB;IACvB,uBAAuB,EAAA;IATzB;MAYG,YAAY,EAAA;IAZf;MAgBG,cAAc;MACd,kBAAkB;MAClB,SAAS;MACT,WAAW,EAAA","sourcesContent":["\r\n\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\theight: 50%;\r\n\tposition: relative;\r\n\tmargin-top: 14px;\r\n\tmargin-bottom: 26px;\r\n\r\n\t& svg {\r\n\t\tdisplay: none;\r\n\t\ttransition: all 0.3s ease-in-out;\r\n\t}\r\n}\r\n\r\n.label {\r\n\tcolor: #C4C4C4;\r\n    font-size: 14px;\r\n    font-family: \"Chakra Petch\";\r\n    text-transform: uppercase;\r\n\tmargin-left: 8px;\r\n\ttransition: all 0.3s ease-in-out;\r\n}\r\n\r\n.input {\r\n    appearance: none;\r\n\tpadding: 8px;\r\n    border-radius: 4px;\r\n    border: 2px solid #c4c4c4;\r\n\ttransition: all 0.3s ease-in-out;\r\n\t\r\n\t&:checked {\r\n\t\tborder: 2px solid white;\r\n\t\tbackground-color: white;\r\n\r\n\t\t& ~ .label {\r\n\t\t\tcolor: white;\r\n\t\t}\r\n\r\n\t\t& ~ svg {\r\n\t\t\tdisplay: block;\r\n\t\t\tposition: absolute;\r\n\t\t\tleft: 6px;\r\n\t\t\tfill: black;\r\n\t\t}\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `nPyx2FCJOU6LhIj9ogw2`,
	"label": `Tky56gyOxCVpbYleE8gR`,
	"input": `Fewk1oCxa4ErviB3ZGe6`
};
export default ___CSS_LOADER_EXPORT___;
