import React, { useEffect, useState } from "react";
import StarLaunchLogo from '../../images/svg/logo.svg'
import MediumIcon from '../../images/svg/medium.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import DiscordIcon from '../../images/svg/discord.svg'
import SoundIcon from '../../images/svg/soundIcon.svg'
import SoundIconMute from '../../images/svg/soundIconMute.svg'
import HamburgerIcon from '../../images/svg/burgerMenuIcon.svg'
import CloseMenuIcon from '../../images/svg/closeMenuIcon.svg'
import StarsDeck from '../../docs/StarLaunchDeck.pdf';
import "./Header.scss";
import { Buffer } from 'buffer';

import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { AnchorProvider, web3, BN,  } from '@project-serum/anchor';
import BigNumber from 'bignumber.js';
import Decimal from 'decimal.js-light';
import moment from 'moment';
import {Token, TOKEN_PROGRAM_ID} from '@solana/spl-token';
import { useConnection, useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';

import { BUTTON_TYPE_NO_STYLE, ButtonClickAudio } from "../Button/Button";
import { toggleMute, toggleVideo } from "../Leftnav/sound";
import Leftnav from "../Leftnav/Leftnav";
import MobileMenu from "../MobileMenu/MobileMenu";
import Apply from "../Apply/Apply";
import HomeTutorial from "../../screens/Home/HomeTutorial";
import VaultTutorial from "../../screens/Vault/VaultTutorial";
import PlayerDashboardTutorial from "../../screens/PlayerDashboard/PlayerDashboardTutorial";
import IdoPageTutorial from "../../screens/IdoPage/IdoPageTutorial";
import HomeInformation, { PAGE_KYC_INFO, PAGE_PROJECT_APPLY } from "../../screens/Home/HomeInformation";
import { PATH_HOME, PATH_IDO_DETAILS, PATH_IDO_PAGE, PATH_PLAYER_DASHBOARD, PATH_VAULT } from "../../App";
import { setInformationModalState, toggleShowApplyForm, toggleShowMobileMenu, toggleShowTutorial } from "../Leftnav/modal";

import useMobileDetect from 'use-mobile-detect-hook';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  setCadetWallets,
  setIDOStats, setKYCStats,
  setShowCreateAccount,
  setShowCreateIdoAccount, setShowCreateNodeIdoAccount,
  setShowCreateVaultAccount,
  setStatistics
} from "./statistics";
import * as helpers from '../../Helpers/helper';
import { setIdoData } from "./idoData";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import {
  IDO_STATUS_COMPLETED,
  IDO_STATUS_ONGOING, IDO_STATUS_REFUND,
  IDO_STATUS_UPCOMING,
  TBA_UNIX_DATE
} from "../../constants/constants";
import {doesURLInclude} from "../../Helpers/helper";
import IdoOngoingModal from "../IdoOngoingModal/IdoOngoingModal";
import {hasValue} from "../../Helpers/helperFunctions";

function Header(props) {
  const {
    setVaultState,
    setProvider,
    setAuthorized,
    authorized,
    provider,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const detectMobile = useMobileDetect();
  const wallet = useWallet();

  const { connection } = useConnection();
  const [isTransparent, setIsTransparent] = useState(false);
  const [mainVault, setMainVault] = useState(null);
  const [mainData, setMainData] = useState();
  const API_URL = helpers.getApiUrl();

  const isMuted = useSelector((state) => state.sound.mute);
  const showTutorial = useSelector((state) => state.modal.showTutorial);
  const showInformation = useSelector((state) => state.modal.informationState.show);
  const showMobileMenu = useSelector((state) => state.modal.showMobileMenu);
  const showApply = useSelector((state) => state.modal.showApplyForm);

  const showCreateAccount = useSelector((state) => state.statistics.showCreateAccount);
  const showCreateVaultAccount = useSelector((state) => state.statistics.showCreateVaultAccount);
  const showCreateIDOAccount = useSelector((state) => state.statistics.showCreateIDOAccount);
  const idoStats = useSelector((state) => state.statistics.idoStats);

  const kyc = useSelector((state) => state.statistics.userStats.KYCStatus);
  const kycCountry = useSelector((state) => state.statistics.userStats.KYCCountry);

  const starsMint = helpers.getStarsMintAddress();
  const hydrazineMint = helpers.getHydrazineMintAddress(); 
  const vaultProgramID = helpers.getVaultProgramId();

  useEffect(() => {
    // if (wallet.publicKey) {
        setProvider(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()));
    // }
  }, [wallet.connected]);

  useEffect(() => {
    if (provider && provider.wallet.connected) {
      getAccountData();
      fetchCadetInfo();
    }
  },[provider?.wallet?.connected]);

  useEffect(() => {
    const API_URL = helpers.getApiUrl();
    const fetchKYCStatus = async () => {
      try {
        fetch(API_URL + "/user/kyc/", {
          "method": "POST",
          "headers": { "content-type": "application/json", "accept": "application/json" },
          "body": JSON.stringify({ wallet: provider.wallet.publicKey.toBase58() })
        }).then(res => res.json())
          .then((result) => {
            if(result.data.kyc) {
              dispatch(setKYCStats({
                KYCStatus: 1,
                KYCCountry: result.data.country,
                tokenExists: result.data.tokenExists
              }))
            } else if (!result.data.kyc && result.data.tokenExists) {
              dispatch(setKYCStats({
                KYCStatus: 3,
                KYCCountry: '',
                tokenExists: result.data.tokenExists
              }))
            } else {
              dispatch(setKYCStats({
                KYCStatus: 2,
                KYCCountry: '',
                tokenExists: result.data.tokenExists
              }))
            }

            const noAuthorizationRequirement = doesURLInclude('app.starlaunch') || doesURLInclude('localhost') || doesURLInclude('dev-stars.starlaunch')
            if (!noAuthorizationRequirement) {
              if(result.data.country === 'TEAM') {
                setAuthorized(true);
              } else {
                setAuthorized(false)
              }
            }

          });
      } catch (e) {
        console.warn("Failed", e);
        toast("Unable to fetch your kyc");
      }
    }

    if (provider?.wallet?.publicKey && !showCreateAccount) {  
      fetchKYCStatus() 
    } else {
      dispatch(setKYCStats({
        KYCStatus: 4,
        KYCCountry: '',
        tokenExists: false
      }))
    }
  }, [provider?.wallet?.publicKey, showCreateAccount]);

  const _toggleShowApply = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    dispatch(toggleShowApplyForm());
  }, [isMuted, ButtonClickAudio, dispatch]);

  const fetchCadetInfo = async () => {
    if (wallet.publicKey) {
      const data = await fetch(helpers.getApiUrl() + '/cadetInfo/' + wallet.publicKey.toString(), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "accept": "*",
          'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
        },
      });


      if (!(await data).ok) return []
      const result = await data.json();
      const cadetData = result.data[0];

      dispatch(setCadetWallets({
        cadetEthereumWallet: cadetData?.ethereumWallet ? cadetData.ethereumWallet : '',
        cadetPolygonWallet: cadetData?.polygonWallet ? cadetData.polygonWallet : '',
        cadetArbitrumWallet: cadetData?.arbitrumWallet ? cadetData.arbitrumWallet : '',
        cadetSupraWallet: cadetData?.supraWallet ? cadetData.supraWallet : '',
        cadetSUIWallet: cadetData?.suiWallet ? cadetData.suiWallet : '',
        cadetAvalancheWallet: cadetData?.avalancheWallet ? cadetData.avalancheWallet : '',
        cadetDymensionWallet: cadetData?.dymensionWallet ? cadetData.dymensionWallet : '',
        cadetSkaleWallet: cadetData?.skaleWallet ? cadetData.skaleWallet : '',
        cadetBaseWallet: cadetData?.baseWallet ? cadetData.baseWallet : '',
      }))
    }
  }



  async function getAccountData() {
    const accts = await helpers.getAddys(provider);
    setMainData(accts.mainData);

    const vaultProgram = await helpers.getVaultProgram(provider);
    const idoProgram = await helpers.getIdoProgram(provider)
    const mainVaultNow = await vaultProgram.account.mainVault.fetch(accts.mainVault);
    const mainData = await idoProgram.account.mainData.fetch(accts.mainData);

    let totalHydrazine = 0;
    if (idoStats.totalHydrazineBurnt === 0) {
      const totalHydrazineBurnt = 0; //new BigNumber(mainData.totalHydrazine.toString()).div(new BigNumber(1000000)).toFixed(1);
      totalHydrazine = await helpers.getHydrazineSupply();// (new BigNumber(mainVaultNow.totalHydrazine.toString()).div(new BigNumber(1000000)) - totalHydrazineBurnt).toFixed(2)
      dispatch(setIDOStats({
        totalHydrazineBurnt: totalHydrazineBurnt,
        totalRaised: new BigNumber(mainData.totalRaised.toString()).div(new BigNumber(1000000)).toFixed(1),
        totalUSDCSpent: new BigNumber(mainData.usdcMint.toString()).div(new BigNumber(1000000)).toFixed(1),
      }))
    }

    setMainVault(mainVaultNow);
    let totalStaked = new BigNumber(mainVaultNow.totalStaked.toString()).div(new BigNumber(1000000)).toFixed(2);
    let totalDecayed = new BigNumber(mainVaultNow.totalDecay.toString()).div(new BigNumber(1000000)).toFixed(2);
    let totalPenalized = new BigNumber(mainVaultNow.totalPenalty.toString()).div(new BigNumber(1000000)).toFixed(2);
    // = new BigNumber(mainVaultNow.totalHydrazine.toString()).div(new BigNumber(1000000)).toFixed(2);
    
    let curTime = moment().unix();

    let lastGlobalPay = mainVaultNow.lastUpdateTime.toNumber();
    
    if (curTime < lastGlobalPay) {
      lastGlobalPay = curTime;
    }

    // calculate the overall decay (in actual decimals, not *10^6)
    let timelapse = new BigNumber(curTime - lastGlobalPay);
    let timeRatio = timelapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
    let leftTokens = new BigNumber(mainVaultNow.unlockedStaked.toString())
                      .times(
                          new BigNumber(new Decimal(0.5).pow(new Decimal(timeRatio.toString())).toString())
                        )
                      .plus(new BigNumber(1))
                      .div(new BigNumber(1000000));
    let starsToDecay;
    if (leftTokens.gte(new BigNumber(mainVaultNow.unlockedStaked).div(new BigNumber(1000000)))) {
      starsToDecay = new BigNumber(0);
    } else {
      starsToDecay = new BigNumber(mainVaultNow.unlockedStaked.toString())
                          .div(new BigNumber(1000000))
                          .minus(leftTokens);
    }
    mainVaultNow.curUnlockedStaked = new BigNumber(mainVaultNow.unlockedStaked.toString())
                                        .div(new BigNumber(1000000))
                                        .minus(starsToDecay)
                                        .toString();
    mainVaultNow.curTotalStaked = new BigNumber(mainVaultNow.totalStaked.toString())
                                        .div(new BigNumber(1000000))
                                        .minus(starsToDecay)
                                        .toString();
                        

    // calculate the overall new rewards per stars
    let toPay = new BigNumber(mainVaultNow.accHydrazinePerShare.toString());
    mainVaultNow.newAcc = new BigNumber(toPay.toString())
                              .plus(
                                new BigNumber((curTime - lastGlobalPay))
                                .times(new BigNumber(mainVaultNow.hydrazineRate.toString()))
                                .times(new BigNumber(100))
                                .div(new BigNumber(mainVaultNow.curTotalStaked))
                              ).toString();
    setMainVault(mainVaultNow);

    totalStaked = (await connection.getTokenAccountBalance(mainVaultNow.vaultStars)).value.uiAmount;;
    totalDecayed = new BigNumber(mainVaultNow.totalDecay.toString()).div(new BigNumber(1000000)).plus(starsToDecay).toFixed(2);
   
    const cadetAccountInit = await connection.getBalance(accts.cadetAcct);

    let userBoxes = [];
    let starsStakedPersonally = 0;
    let lockedstarsStakedPersonally = 0;
    let unlockedstarsStakedPersonally = 0;
    let starsPenalizedPersonally = 0;
    let starsDecayedPersonally = 0;
    let hydrazinePendingPersonally = 0;
    let boxId = 0;

    let cadetId = 0;
    let cadetRank = 'CADET';
    let cadetLvl = 0;
    let cadetMP = 0;
    let cadetWallet = '';

    let starsInWallet = 0;
    let hydrazineInWallet = 0;

    if (cadetAccountInit > 0) {
      if (showCreateAccount) {
        dispatch(setShowCreateAccount(false));
      }
      const cadetInfo = await helpers.getCadetInfo(provider, accts.cadetAcct);
      cadetId = (cadetInfo?.id?.toString()).padStart(8, '0');
      cadetLvl = cadetInfo.level.toString();
      cadetMP = cadetInfo.exp.toString();
      cadetWallet = wallet.publicKey.toString();//cadetInfo.cadet.toString()

      const starATA = await helpers.findATA(provider.wallet.publicKey,starsMint);
      const acctBal = new BN(await connection.getBalance(starATA));
      if (!acctBal.isZero()) {
        const starToken = new Token(connection,starsMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
        const starAcctInfo = await starToken.getAccountInfo(starATA);
        if (starAcctInfo.amount.gt(new BN(0))) {
          starsInWallet = new BigNumber(starAcctInfo.amount.toString()).div(new BigNumber(1000000)).toFixed(2);
        }
      }
      const hydrazineATA = await helpers.findATA(provider.wallet.publicKey,hydrazineMint);
      const hydrazineAcctBal = new BN(await connection.getBalance(hydrazineATA));
  
      if (!hydrazineAcctBal.isZero()) { 
        const hydrazineToken = new Token(connection,hydrazineMint,TOKEN_PROGRAM_ID,provider.wallet.publicKey);
        const hydrazineAcctInfo = await hydrazineToken.getAccountInfo(hydrazineATA);
        if (hydrazineAcctInfo.amount.gt(new BN(0))) {
          hydrazineInWallet = new BigNumber(hydrazineAcctInfo.amount.toString()).div(new BigNumber(1000000)).toFixed(4);
        } 
      }

      const nodeIdoAccountInit = await connection.getBalance(accts.nodeIdoAcct);

      if (nodeIdoAccountInit > 0) {
        dispatch(setShowCreateNodeIdoAccount(false))
      } else {
        dispatch(setShowCreateNodeIdoAccount(true))
      }
      
      const vaultAccountInit = await connection.getBalance(accts.vaultAccount);
      if (vaultAccountInit > 0) {
        if (showCreateVaultAccount) {
          dispatch(setShowCreateVaultAccount(false));
        }

        const idoAccountInit = await connection.getBalance(accts.idoAcct);
        if (idoAccountInit > 0) {
          if (showCreateIDOAccount) {
            dispatch(setShowCreateIdoAccount(false));
          }
      
        } else {
          dispatch(setShowCreateIdoAccount(true));
        }



        const boxOwner = await vaultProgram.account.boxOwner.fetch(accts.vaultAccount);
        boxId = ("Box " + String(boxOwner.totalBoxes.toNumber()).padStart(5, '0'));

        starsPenalizedPersonally = new BigNumber(boxOwner.totalPenalty.toString()).div(new BigNumber(1000000)).toFixed(4);
        starsDecayedPersonally = new BigNumber(boxOwner.totalDecay.toString()).div(new BigNumber(1000000)).toFixed(4);

        let myBoxes = boxOwner.totalBoxes.toNumber();
        let vaults = [];

        for (let i = 0; i < myBoxes; i++) {
          const [myBox] = await web3.PublicKey.findProgramAddress(
            [
                provider.wallet.publicKey.toBuffer(), 
                helpers.getVaultProgramNameBuffer(), 
                new BN(i.toString()).toArrayLike(Buffer,'le',8),
                Buffer.from("vault_box")
            ],
            vaultProgramID
          );
          const myBoxInit = await connection.getBalance(myBox);

          if (myBoxInit > 0) {
            let myVault;
            try {
              myVault = await vaultProgram.account.vaultBox2.fetch(myBox);
            } catch(e) {
              // for old programs
              myVault = await vaultProgram.account.vaultBox.fetch(myBox);
            }
            vaults.push({
              key: i, 
              account: myVault,
              publicKey: myBox
            });
          } 
        }

        // Run through the user fusion boxes
        for (let i = 0; i<vaults.length; i++) {

           // if (vaults[i].account.ownerWallet.toBase58() === provider.wallet.publicKey.toBase58()) {
            vaults[i].key = i;
            let lastUserPay = vaults[i].account.lastUpdateTime.toNumber();
            if (curTime < lastUserPay) {
              lastUserPay = curTime;
            }

            // calculate own decay (in actual decimals, not *10^6)
            vaults[i].account.curStaked = new BigNumber(vaults[i].account.staked.toString()).div(new BigNumber(1000000)).toString();
            if (!vaults[i].account.locked) {
              let boxTimelapse = new BigNumber(curTime - lastUserPay);
              let boxTimeRatio = boxTimelapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
              let boxLeftTokens = new BigNumber(vaults[i].account.curStaked)
                                .times(
                                  new BigNumber(new Decimal(0.5).pow(new Decimal(boxTimeRatio.toString())).toString())
                                  );
              let boxStarsToDecay = new BigNumber(vaults[i].account.curStaked)
                                    .minus(boxLeftTokens);
              vaults[i].account.curStaked = new BigNumber(vaults[i].account.curStaked)
                                            .minus(boxStarsToDecay).toString();


            }

            // calculate own pending stars (There might be bug here,it must depend on create time not last update time of the user)
             vaults[i].account.pendingStars =  new BigNumber(curTime - lastUserPay)
                                                  .times(vaults[i].account.stakingApr)
                                                  .times(new BigNumber(31536000))  // 365*24*60*60
                                                  .div(new BigNumber(100000000))
                                                  .div(new BigNumber(100))
                                                  .toString();

            // calculate own pending rewards
            vaults[i].account.pendingHydrazine =  new BigNumber(vaults[i].account.curStaked)
              .times(new BigNumber(mainVaultNow.newAcc).minus(new BigNumber(vaults[i].account.accHydrazinePerShare.toString())))
              .div(new BigNumber(100000000))
              .toString();

            vaults[i].account.pendingHydrazineOld = 0 // new BigNumber(0.03).times(vaults[i].account.curStaked).div(totalStaked).times(new BigNumber(curTime - lastUserPay));  
            // vaults[i].account.pendingHydrazine = new BigNumber(vaults[i].account.accHydrazinePerShare.toString());
            
            // calculate own penalty
            vaults[i].account.unstakePenalty = "0";
            if (vaults[i].account.locked) {
              let penalTimeLapse = new BigNumber(vaults[i].account.unlockTime.toNumber() - curTime);
              let penalTimeRatio = penalTimeLapse.div(new BigNumber(mainVaultNow.starsHalf.toString()));
              let penalLeftTokens = new BigNumber(vaults[i].account.curStaked)
                                    .times(
                                      new BigNumber(new Decimal(0.5).pow(new Decimal(penalTimeRatio.toString())).toString())
                                      );
              let penalTotalDecay = new BigNumber(vaults[i].account.curStaked).minus(penalLeftTokens);
              let penalDecay = penalTotalDecay.div(new BigNumber(2));
              vaults[i].account.unstakePenalty = penalDecay.toString();

              lockedstarsStakedPersonally += parseInt(vaults[i].account.curStaked);
            } else {
              unlockedstarsStakedPersonally += parseInt(vaults[i].account.curStaked);
            }

            starsStakedPersonally += parseInt(vaults[i].account.curStaked);
           
            hydrazinePendingPersonally += parseFloat(vaults[i].account.pendingHydrazine);
            // add to the final list
            userBoxes.push(vaults[i]);
        }
      } else {
        if (!showCreateVaultAccount) {
          dispatch(setShowCreateVaultAccount(true));
        }
      }
    } else {
      if (!showCreateAccount) {
        dispatch(setShowCreateAccount(true));
      }
    }

    dispatch(setStatistics({
      totalCryptoStats: {
        totalStarsStaked: totalStaked,
        totalStarsDecayed: totalDecayed,
        totalHydrazine: totalHydrazine,
        totalStarsPenalized: totalPenalized,
      },
      personalCryptoStats: {
        personallyStakedStars: starsStakedPersonally,
        personallyPendingHydrazine: hydrazinePendingPersonally,
        personallyPenalizedStars: starsPenalizedPersonally,
        personallyDecayedStars: starsDecayedPersonally,
        personallyLockedStakedStars: lockedstarsStakedPersonally,
        personallyUnlockedStakedStars : unlockedstarsStakedPersonally
      },
      wallet: {
        starsInWallet: starsInWallet,
        hydrazineInWallet: hydrazineInWallet,
      },
      cadetStats: {
          cadetLvl: cadetLvl,
          cadetMP: cadetMP,
          cadetRank: cadetRank,
          cadetId: cadetId,
          cadetWallet: cadetWallet,
      },
    }))

    setVaultState({
      userBoxes: userBoxes,
      newBoxId: boxId,
      mainVault: mainVaultNow,
    })
  }

  useEffect( ()=>{
    const fetchSaleData = async () => {
      fetch(API_URL+"/sales/", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
      }).then(res=>res.json())
          .then((result)=>{
            parseResult(result);
          });
    }

    fetchSaleData();
  },[]);

  function parseResult(result) {
    let refundList = [];
    let ongoingList = [];
    let upcomingTBAList = [];
    let upcomingList = [];
    let completedList = [];

/*
    upcomingList.push({
      name: 'Mystery IDO',
      description: "We've detected another mysterious signal in our travels throughout the unknown reaches of the metaverse. Our analysis has revealed the presence of an IDO along our current course, ETA 4/17. The signal origin is still unclear, but we expect to have all the information by 4/11.",
      status: 'Upcoming',
      pair: '?',
      swaprate: '?',
      dollarAmount: '?',
      poolcap: '?',
      symbol: '?',
      participants: '?',
      poolname: '',
      mysteryIdo: true,
    })*/

    for (let i=0; i<result.data.sales_json.sales.length; i++) {
      const idoData = result.data.sales_json.sales[i];

      if (!idoData || !idoData.status) return;

      const meetsRequirement = doesURLInclude('app.starlaunch') ? idoData?.production === true : true;

      if (idoData.status === IDO_STATUS_REFUND && meetsRequirement) {
        refundList.push(idoData);
      } else if (idoData.status === IDO_STATUS_UPCOMING && meetsRequirement) {

        if ((hasValue(idoData.salesEndTime) && idoData.salesEndTime > TBA_UNIX_DATE) || (idoData.isNodeSale && hasValue(idoData.nodeTiers?.ogTier?.endTime) && idoData.nodeTiers?.ogTier?.endTime  > TBA_UNIX_DATE)) {
          upcomingList.push(idoData);
        } else {
          upcomingTBAList.push(idoData);
        }

      } else if (idoData.status === IDO_STATUS_ONGOING && meetsRequirement) {
        ongoingList.push(idoData);
      } else if (idoData.status === IDO_STATUS_COMPLETED && meetsRequirement) {
        completedList.push(idoData);
      }
    }

    dispatch(setIdoData({
      refundIDOs: refundList.sort((a, b) => b?.salesEndTime - a?.salesEndTime),
      upcomingIDOs: upcomingList.sort((a, b) => {
        const aTime = a?.isNodeSale ? a?.nodeTiers?.ogTier?.endTime : a?.salesEndTime
        const bTime = a?.isNodeSale ? a?.nodeTiers?.ogTier?.endTime : a?.salesEndTime

        return bTime - aTime
      }).reverse().concat(upcomingTBAList),
      ongoingIDOs: ongoingList.sort((a, b) => b?.salesEndTime - a?.salesEndTime),
      completedIDOs: completedList.sort((a, b) => b?.salesEndTime - a?.salesEndTime),
    }))
  }

  const openURL = (url) => {
    window.open(url)
  }

  const walletClick = () => {
    if (!isMuted) {
      ButtonClickAudio.play()
    }
  }

  const _toggleMute = () => {
    dispatch(toggleMute())
  }

  const _toggleVideo = () => {
    dispatch(toggleVideo())
  }

  const _toggleShowInformation = useCallback((key) => {
    if (!isMuted) {
        ButtonClickAudio.play();
    }

    dispatch(setInformationModalState({ show: true, startPage: key }))
  }, [isMuted, ButtonClickAudio, dispatch]);

  useEffect(() => {
    if (window.location.pathname.includes('/kyc')) {
      _toggleShowInformation(PAGE_KYC_INFO)
    }
  }, []);

  const _toggleShowTutorial = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    dispatch(toggleShowTutorial())
  }, [isMuted, ButtonClickAudio, dispatch]);


  const _toggleShowMobileMenu = useCallback(() => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    if (showTutorial === true) {
      dispatch(toggleShowTutorial());
    }

    dispatch(toggleShowMobileMenu())
  }, [isMuted, ButtonClickAudio, dispatch, showTutorial]);

  const isHome = location.pathname === PATH_HOME;
  const isVault = location.pathname === PATH_VAULT;
  const isPlayerDashboard = location.pathname === PATH_PLAYER_DASHBOARD;
  const isAnyIdoPage = location.pathname === PATH_IDO_PAGE || location.pathname === PATH_IDO_DETAILS;

  const displayKycStatus = (kyc) => {
    switch (kyc) {
      case 0 : return <li className="deckButton" onClick={() => _toggleShowInformation(PAGE_KYC_INFO)}><a>FETCHING KYC STATUS ... </a></li>
      case 1 : return <li className="deckButton deckButtonSuccess" onClick={() => _toggleShowInformation(PAGE_KYC_INFO)}><a> KYC IS APPROVED ({kycCountry})</a></li>
      case 2 : return <li className="deckButton deckButtonError" onClick={() => _toggleShowInformation(PAGE_KYC_INFO)}><a>KYC NOT APPROVED </a></li>
      case 3 : return <li className="deckButton deckButtonWarning" onClick={() => _toggleShowInformation(PAGE_KYC_INFO)}><a>KYC PENDING </a></li>
    }
  }

  return (
    <header key='header'>
      {authorized && <Leftnav />}
      <nav
        id="main-navbar"
        className={`navbar navbar-expand-lg fixed-top ${ isTransparent ? "navbar-sl" : ""}`}
      >
        { false && <div className="topNotification">Solana is impacted by heavy congestion issues, which can cause transaction delays or timeouts. If your transaction fails, try again</div> }
        <div className="headerContainer">
          <div className="headerLeftContainer">
              <div className="logoContainer" onClick={() => history.push(PATH_HOME)}>
                <StarLaunchLogo className="logo"  />
                <span className="logoText">StarLaunch</span>
              </div>
             
              <a className="socialMediaBox" onClick={() => openURL('https://medium.com/@StarLaunch')}>
                <MediumIcon className="socialMediaIcon" />
              </a>
              <a className="socialMediaBox" onClick={() => openURL('https://twitter.com/StarLaunchSOL')}>
                <TwitterIcon className="socialMediaIcon" />
              </a>
              <a className="socialMediaBox" onClick={() => openURL('https://discord.gg/invite/StarlaunchSOL')}>
                <DiscordIcon className="socialMediaIcon" />
              </a>
          </div>

          <ul className="navbar-nav ms-auto d-flex flex-row"> 
              { provider?.wallet?.publicKey && displayKycStatus(kyc) }
            <li className="deckButton" onClick={() => _toggleShowInformation(PAGE_PROJECT_APPLY)}>
              <a>
                APPLY
              </a>
            </li>
            <li className="deckButton" onClick={() => _toggleShowInformation(PAGE_KYC_INFO)}>
              <a>
                KYC INFO
              </a>
            </li>
{/*          <li className="deckButton">
              <a>
                DECK
              </a>  
            </li> */}
            <li className="nav-item me-3 me-lg-0">
              <a className="nav-link" href="#" onClick={walletClick}>
                <WalletMultiButton />
              </a>
            </li>
          </ul>
          {showInformation && <HomeInformation 
            close={_toggleShowInformation} 
            kyc={kyc}
            wallet={provider?.wallet?.publicKey} 
          />}

          {detectMobile.isMobile() && <div className="mobileRightContainer">
            {!isMuted && <SoundIcon className="mobileIcon" onClick={_toggleMute} />}
            {isMuted && <SoundIconMute className="mobileIcon" onClick={_toggleMute} />}
            {/* {!videoOff && <VideoOnIcon className="mobileIcon" onClick={_toggleVideo} />}
            {videoOff && <VideoOffIcon className="mobileIcon" onClick={_toggleVideo} />} */}
            {!showMobileMenu && <HamburgerIcon className="mobileIcon" onClick={_toggleShowMobileMenu} />}
            {showMobileMenu && <CloseMenuIcon className="mobileIcon" onClick={_toggleShowMobileMenu} />}
            {showMobileMenu && <MobileMenu onClose={_toggleShowMobileMenu} kycStatus={kyc}  kycCountry={kycCountry} />}
            {showTutorial && isHome && 
            <HomeTutorial 
              closeTutorial={_toggleShowTutorial}  />}
            {showTutorial && isVault && 
              <VaultTutorial closeTutorial={_toggleShowTutorial} />}
            {showTutorial && isPlayerDashboard &&
              <PlayerDashboardTutorial closeTutorial={_toggleShowTutorial} />}
            {showTutorial && isAnyIdoPage && 
              <IdoPageTutorial closeTutorial={_toggleShowTutorial} />}
           
          </div>}
          {showApply && <Apply close={_toggleShowApply} />}
          <IdoOngoingModal />
        </div>
      </nav>
    </header>
  );
}

export default Header;
