// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zEA2AVMIYPrtOgWoRzp4 {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 14px;
  margin-bottom: 26px; }

.luzYwxM_jcsw0QU6KpxY, .KCFYzERHt60g6yrlk5oB {
  color: #C4C4C4;
  font-size: 14px;
  font-weight: bold;
  font-family: Chakra Petch;
  text-transform: uppercase;
  margin-left: 8px;
  transition: all 0.3s ease-in-out; }

.KCFYzERHt60g6yrlk5oB {
  color: white; }

.cLebotkqL6w8Y_I15Iox {
  appearance: none;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 4px;
  border: 2px solid #c4c4c4;
  transition: all 0.3s ease-in-out; }
  .cLebotkqL6w8Y_I15Iox:checked {
    border: 2px solid white;
    background-color: white; }
    .cLebotkqL6w8Y_I15Iox:checked ~ .luzYwxM_jcsw0QU6KpxY, .cLebotkqL6w8Y_I15Iox:checked ~ .KCFYzERHt60g6yrlk5oB {
      color: white; }
    .cLebotkqL6w8Y_I15Iox:checked ~ .Be036wwGD5aFs2XnQV82 {
      display: block;
      position: absolute;
      left: 6px;
      fill: black; }

.Be036wwGD5aFs2XnQV82 {
  fill: black;
  display: none;
  transition: all 0.3s ease-in-out; }

.RsY5PFOxmf_xDnKMO3oL {
  margin-left: 8px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  fill: #C4C4C4;
  display: block;
  position: relative; }
`, "",{"version":3,"sources":["webpack://./src/Components/RadioButton/RadioButton.module.scss"],"names":[],"mappings":"AAEA;EACC,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB,EAAA;;AAQpB;EACC,cAAc;EACd,eAAe;EACf,iBAAiB;EACd,yBAAyB;EACzB,yBAAyB;EAC5B,gBAAgB;EAChB,gCAAgC,EAAA;;AAGjC;EAEC,YAAY,EAAA;;AAGb;EACI,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACd,eAAe;EACZ,kBAAkB;EAClB,yBAAyB;EAC5B,gCAAgC,EAAA;EAPjC;IAUE,uBAAuB;IACvB,uBAAuB,EAAA;IAXzB;MAcG,YAAY,EAAA;IAdf;MAkBG,cAAc;MACd,kBAAkB;MAClB,SAAS;MACT,WAAW,EAAA;;AAKd;EACC,WAAW;EACX,aAAa;EACb,gCAAgC,EAAA;;AAGjC;EACC,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,aAAa;EACb,cAAc;EACd,kBAAkB,EAAA","sourcesContent":["\r\n\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tposition: relative;\r\n\tmargin-top: 14px;\r\n\tmargin-bottom: 26px;\r\n\r\n\t// & svg {\r\n\t// \tdisplay: none;\r\n\t// \ttransition: all 0.3s ease-in-out;\r\n\t// }\r\n}\r\n\r\n.label {\r\n\tcolor: #C4C4C4;\r\n\tfont-size: 14px;\r\n\tfont-weight: bold;\r\n    font-family: Chakra Petch;\r\n    text-transform: uppercase;\r\n\tmargin-left: 8px;\r\n\ttransition: all 0.3s ease-in-out;\r\n}\r\n\r\n.labelActive {\r\n\t@extend .label;\r\n\tcolor: white;\r\n}\r\n\r\n.input {\r\n    appearance: none;\r\n    height: 20px;\r\n    width: 20px;\r\n\tmin-width: 20px;\r\n    border-radius: 4px;\r\n    border: 2px solid #c4c4c4;\r\n\ttransition: all 0.3s ease-in-out;\r\n\t\r\n\t&:checked {\r\n\t\tborder: 2px solid white;\r\n\t\tbackground-color: white;\r\n\r\n\t\t& ~ .label {\r\n\t\t\tcolor: white;\r\n\t\t}\r\n\r\n\t\t& ~ .checkmarkIcon {\r\n\t\t\tdisplay: block;\r\n\t\t\tposition: absolute;\r\n\t\t\tleft: 6px;\r\n\t\t\tfill: black;\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.checkmarkIcon {\r\n\tfill: black;\r\n\tdisplay: none;\r\n\ttransition: all 0.3s ease-in-out;\r\n}\r\n\r\n.tooltipIcon {\r\n\tmargin-left: 8px;\r\n\theight: 20px;\r\n\twidth: 20px;\r\n\tmin-width: 20px;\r\n\tfill: #C4C4C4;\r\n\tdisplay: block;\r\n\tposition: relative;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `zEA2AVMIYPrtOgWoRzp4`,
	"label": `luzYwxM_jcsw0QU6KpxY`,
	"labelActive": `KCFYzERHt60g6yrlk5oB`,
	"input": `cLebotkqL6w8Y_I15Iox`,
	"checkmarkIcon": `Be036wwGD5aFs2XnQV82`,
	"tooltipIcon": `RsY5PFOxmf_xDnKMO3oL`
};
export default ___CSS_LOADER_EXPORT___;
