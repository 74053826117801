import React from "react";
import styles from "./Checkbox.module.scss";
import CheckboxClick from "../../Sounds/check-box-click.mp3"
import CheckmarkIcon from "../../images/svg/checkmark.svg"
import { useSelector } from "react-redux";
import classNames from "classnames";

function Checkbox(props) {
    const {
        id,
        label,
        className,
        onChange,
        checked,
    } = props;

    const CheckboxClickAudio = new Audio(CheckboxClick);

    const isMuted = useSelector((state) => state.sound.mute);

    const _onChange = (e) => {
        if (onChange) {
            if (!isMuted) {
                CheckboxClickAudio.play();
            }
            onChange(e);
        }
    }

    return (
        <div className={classNames(styles.inputContainer, className)}>            
            <input id={id} type="checkbox" className={styles.input} onChange={_onChange} checked={checked} />            
            <CheckmarkIcon />         
            <label for={id} className={styles.label}>
                {label}
            </label>
        </div>
    );
}

export default Checkbox;
