import React, {useCallback, useMemo, useState} from "react";
import './BuyAllocationForm.scss';
import {
  getEstimatedAllocation, getIdoNodeTime,
  getIdoTime,
  getProgressBarInformation,
  getTotalHydrazineContributed,
  getTotalUserContributedHydrazine,
  getUserAllocation, getUserFCFSContribution, getUserTotalUSDCContribution,
  getUserUSDCContribution, IDO_NODE_SALE,
  IDO_STAGE_CLOSED,
  IDO_STAGE_COMPLETED,
  IDO_STAGE_FCFS_SALE,
  IDO_STAGE_HYDRAZINE_CONTRIBUTION, IDO_STAGE_SETTING_ALLOCATION, IDO_STAGE_SETTING_FCFS_ALLOCATION,
  IDO_STAGE_USDC_CONTRIBUTION
} from "../../Helpers/idoHelper";
import styles from "./IdoSalesStage.module.scss";
import Countdown from "react-countdown";
import moment from "moment";
import sl_logo from '../../images/sl-icon-colored.png'
import IdoPurchaseAllocation from "./IdoPurchaseAllocation";
import {TBA_UNIX_DATE} from "../../constants/constants";
import {hasValue} from "../../Helpers/helperFunctions";

const IdoSaleStage = (props) => {
  const {
    stage,
    currentStage,
    poolData,
    idoInfo,
    participantInfo,
    setParticipantInfo,
    allocationInfo,
    provider,
    walletBalance,
    setIsSendingTransaction,
  } = props;

  const saleStageInfo = getProgressBarInformation(stage, poolData?.poolInfo, idoInfo)
  const idoTimes = getIdoTime(stage, idoInfo);
  const percentage = saleStageInfo.percentage;

  const timeNow = moment().unix();

  const startTimeUnix = moment.unix(idoTimes.start);
  const endTimeUnix = moment.unix(idoTimes.end);

  const startTimeTBA = TBA_UNIX_DATE > idoTimes.start;
  const endTimeTBA = TBA_UNIX_DATE > idoTimes.end;

  const hasEnded = () => {
    if (startTimeTBA || endTimeTBA) {
      return false;
    }

    if (idoTimes.end < timeNow) {
      return true;
    }

    return false;
  };

  const saleTitleAndText = useMemo(() => {
    if (stage === IDO_NODE_SALE) {
      return { title: 'NODE SALE', description: 'PURCHASE NODES BASED ON YOUR CURRENT TIER'}
    }
    if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
      return { title: 'HYDRAZINE CONTRIBUTION', description: 'Contribute your Hydrazine to secure an allocation' }
    }
    if (stage === IDO_STAGE_USDC_CONTRIBUTION) {
      return { title: 'USDC CONTRIBUTION', description: 'Contribute your USDC to fund your allocation' }
    }
    if (stage === IDO_STAGE_FCFS_SALE) {
      return { title: 'FCFS SALE', description: 'This is your chance to secure some extra tokens' }
    }
  }, [stage])

  const contributionRowContent = () => {
    if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
      const totalHydrazineContributed = getTotalHydrazineContributed(poolData?.poolInfo, idoInfo)
      const userContributedHydrazine = getTotalUserContributedHydrazine(participantInfo);

      return (
        <div className={styles.saleContributionRow}>
          <div className={styles.saleContributionBoxColumn}>
            <span className={styles.saleContributionValueColumnLabel}>TOTAL CONTRIBUTION</span>
            <span className={styles.saleContributionValueColumnValue}>{totalHydrazineContributed.toLocaleString() + ' HYDRAZINE'}</span>
          </div>
          <div className={styles.saleContributionDivider}/>
          <div className={styles.saleContributionBoxColumn}>
            <span className={styles.saleContributionValueColumnLabel}>YOUR CONTRIBUTION</span>
            <span className={styles.saleContributionValueColumnValue}>{userContributedHydrazine.toLocaleString()+ ' HYDRAZINE'}</span>
          </div>
        </div>
      )
    }
    const userAllocation = getUserAllocation(allocationInfo);
    const userUSDCContribution = getUserUSDCContribution(participantInfo, poolData?.poolInfo)
    const userFCFSContribution = getUserFCFSContribution(participantInfo, poolData?.poolInfo)

    return (
      <div className={styles.saleContributionRow}>
        <div className={styles.saleContributionBoxColumn}>
          <span className={styles.saleContributionValueColumnLabel}>
            {stage === IDO_STAGE_FCFS_SALE ? 'YOUR FCFS ALLOCATION' : 'YOUR ALLOCATION'}
          </span>
          <span className={styles.saleContributionValueColumnValue}>{userAllocation.toLocaleString() + ' USDC'}</span>
        </div>
        <div className={styles.saleContributionDivider}/>
        <div className={styles.saleContributionBoxColumn}>
          <span className={styles.saleContributionValueColumnLabel}>
            {stage === IDO_STAGE_FCFS_SALE ? 'YOUR FCFS CONTRIBUTION' : 'YOUR CONTRIBUTION'}
          </span>
          <span className={styles.saleContributionValueColumnValue}>
            {(stage === IDO_STAGE_FCFS_SALE ? userFCFSContribution.toLocaleString() :  userUSDCContribution.toLocaleString()) + ' USDC'}
          </span>
        </div>
      </div>
    )
  }

  const allocationEndedRowContent = () => {
    const userAllocation = getUserAllocation(allocationInfo);
    const estimatedAllocation = userAllocation > 1 ? userAllocation : getEstimatedAllocation({ poolInfo: poolData?.poolInfo, idoInfo, participantInfo})

    if (stage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
      //const userAllocation = getUserAllocation(allocationInfo);
      //const estimatedAllocation = userAllocation > 1 ? userAllocation : getEstimatedAllocation({ poolInfo: poolData?.poolInfo, idoInfo, participantInfo})

      const userContributedHydrazine = getTotalUserContributedHydrazine(participantInfo);
      if (userContributedHydrazine === 0) {
        return(
          <div className={styles.saleAllocationContainer}>
           <span className={styles.saleNotParticipatedText}>
             YOU DID NOT PARTICIPATE IN THIS SALE
            </span>
          </div>)
      }

      return (
        <div className={styles.saleAllocationContainer}>
          <div className={styles.saleEstimatedAllocationColumnLargeWidth}>
            <div>
              <span>CURRENT ESTIMATED ALLOCATION</span>
            </div>
            <span className={styles.estimatedAllocationValue}>{estimatedAllocation.toFixed(2).toLocaleString() + ' USDC'}</span>
          </div>
        </div>
      )
    }
    const userUSDCContribution = getUserUSDCContribution(participantInfo, poolData?.poolInfo)
   // const userAllocation = getUserAllocation(allocationInfo, userUSDCContribution, estimatedAllocation);
    const userEntireContribution = getUserTotalUSDCContribution(participantInfo, poolData?.poolInfo)
    const fundedEntireAllocation = userAllocation > userUSDCContribution * 0.999;

    if (userAllocation === 0 && userUSDCContribution === 0) {
      return(
        <div className={styles.saleAllocationContainer}>
         <span className={styles.saleNotParticipatedText}>
           YOU DID NOT PARTICIPATE IN THIS SALE
          </span>
        </div>)
    }

    return (
      <div className={styles.saleAllocationContainer}>
        {stage === IDO_STAGE_FCFS_SALE && <span className={styles.saleFundedAllocationText}>
            {'YOU CONTRIBUTED ' + userEntireContribution + ' USDC IN TOTAL FOR THIS SALE'}
          </span>}
        {fundedEntireAllocation && stage !== IDO_STAGE_FCFS_SALE &&
          <span className={styles.saleFundedAllocationText}>
            ✅ YOU FUNDED 100% OF YOUR ALLOCATION
          </span>}
        {!fundedEntireAllocation && stage !== IDO_STAGE_FCFS_SALE && <span className={styles.saleFundedAllocationText}>
          YOU FUNDED {(userAllocation / userUSDCContribution) * 100}% OF YOUR ALLOCATION
        </span>}
      </div>
    )
  }

  const currentlyClosedTitle = () => {
    if (stage === IDO_STAGE_USDC_CONTRIBUTION && currentStage === IDO_STAGE_SETTING_ALLOCATION ) {
      return (
        <div className={styles.saleClosedContainer}>
          <span className={styles.saleClosedTitle}>{'DETERMINING ALLOCATIONS'}</span>
          <img className={styles.saleClosedPicture} src={sl_logo} alt="Starlaunch Logo"/>
        </div>
      )
    }

    if (stage === IDO_STAGE_FCFS_SALE && currentStage === IDO_STAGE_SETTING_FCFS_ALLOCATION) {
      return (
        <div className={styles.saleClosedContainer}>
          <span className={styles.saleClosedTitle}>{'DETERMINING FCFS ALLOCATIONS'}</span>
          <img className={styles.saleClosedPicture} src={sl_logo} alt="Starlaunch Logo"/>
        </div>
      )
    }

    if (stage === IDO_STAGE_FCFS_SALE && currentStage === IDO_STAGE_FCFS_SALE && !participantInfo) {
      return (
        <div className={styles.saleClosedContainer}>
          <span className={styles.saleClosedTitle}>{'YOU ARE NOT ELIGIBLE FOR THIS ROUND'}</span>
          <img className={styles.saleClosedPicture} src={sl_logo} alt="Starlaunch Logo"/>
        </div>
      )
    }

    return (
      <div className={styles.saleClosedContainer}>
        <span className={styles.saleClosedTitle}>{'WAITING FOR ' + saleTitleAndText.title + ' TO OPEN'}</span>
        <img className={styles.saleClosedPicture} src={sl_logo} alt="Starlaunch Logo"/>
      </div>
    )
  }

  const saleEndedContent = () => {
    return (
      <div className={styles.saleEndContainer}>
        <div className={styles.titleRow}>
          <div className={styles.titleBox}>
            <span className={styles.saleStageTitle}>{saleTitleAndText.title}</span>
            <span className={styles.saleStageDescription}>{saleTitleAndText.description}</span>
          </div>
          {!hasEnded() &&  <div className={styles.saleStageWindowCountdownContainer}>
            {startTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW OPEN</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>TO BE ANNOUNCED</span>
                <br/>
              </div>
            </div>}
            {!startTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW OPEN</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment(startTimeUnix).utc().format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={idoTimes.start * 1000} renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>}
            {endTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>TO BE ANNOUNCED</span>
              </div>
            </div>}
            {!endTimeTBA &&<div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment(endTimeUnix).utc().format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={idoTimes.end * 1000} renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>}
          </div> }
          {hasEnded() && <div className={styles.titleBox}>
            <span className={styles.saleStageWindowTitle}>WINDOW CURRENTLY CLOSED</span>
            <span className={styles.saleStageFundedDescription}>
              {stage === IDO_STAGE_USDC_CONTRIBUTION ?
                `${saleStageInfo.value} USDC OF ${saleStageInfo.max} USDC FUNDED` :
                ''}
            </span>
          </div>}
        </div>
        {!hasEnded() && currentlyClosedTitle()}
        {hasEnded() && <div className={styles.saleCompletedContainer}>
          {contributionRowContent()}
          {allocationEndedRowContent()}
        </div>}
      </div>
    )
  }

  const showPurchaseWindow = () => {
    const curTime = moment().unix();

    if (stage !== currentStage) {
      return false;
    }

    if (participantInfo) return true

    if (hasValue(idoInfo?.openSaleNoRestrictionStartTime)) {
      return idoInfo?.openSaleNoRestrictionStartTime < curTime;
    } else {
      return false;
    }
  }


  const style = stage === currentStage ? styles.idoSaleStageContainerActive : styles.idoSaleStageContainer;

  return (
    <div className={style}>
      {!showPurchaseWindow() && saleEndedContent()}
      {showPurchaseWindow() && <div className={styles.saleEndContainer}>
        <div className={styles.titleRowOpen}>
          <div className={styles.titleBox}>
            <span className={styles.saleStageTitle}>{saleTitleAndText.title}</span>
            <span className={styles.saleStageDescription}>{saleTitleAndText.description}</span>
          </div>
          <div className={styles.saleStageOpenCountdownAndTitleCont}>
            <span className={styles.saleStageStatusTitle}>WINDOW CURRENTLY OPEN</span>
            <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment.utc(endTimeUnix).format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={endTimeUnix.toDate()} renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>
          </div>
        </div>
        <IdoPurchaseAllocation
          stage={stage}
          currentStage={currentStage}
          poolData={poolData}
          participantInfo={participantInfo}
          allocationInfo={allocationInfo}
          walletBalance={walletBalance}
          setParticipantInfo={setParticipantInfo}
          setIsSendingTransaction={setIsSendingTransaction  }
          provider={provider}
          idoInfo={idoInfo} />
      </div>}
    </div>
  )
}

export default IdoSaleStage;
