import { useState } from 'react';
import TutorialPopup from '../../Components/TutorialPopup/TutorialPopup';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import './PlayerDashboardTutorial.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import useMobileDetect from 'use-mobile-detect-hook';
import Button from '../../Components/Button/Button';

function PlayerDashboardTutorial(props) {
    const {
        closeTutorial,
    } = props;

    const [page, setPage] = useState(1);

    const mobileDetect = useMobileDetect();

    const overlayClassname = () => {
        if (page === 3) {
            return 'justifyLeft';
        }

        if (page === 4) {
            return 'justifyRight';
        }

        if (page === 5 || page === 6) {
            return 'invertColumn';
        }

        return '';
    } 


    return (
    <div className={'playdashboardOverlay ' + overlayClassname()}>
        {page === 1 && <TutorialPopup
            title="Player Dashboard" 
            text="Welcome to the Player Dashboard Commander! This is where you will connect your Wallet and claim your Cadet ID"
            containerStyle={{position: 'relative'}}
            maxPages={5}
            currentPage={page}
            closeTutorial={ closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page === 2 && <>
            {!mobileDetect.isMobile() && <div className='informationContainer'>
                <div className='informationHeader'>PERSONAL INFORMATION</div>
                <div className='personalInformationBody'>
                <div className='personalInformationBox'>
                    <span className='greyTitle'>NICKNAME</span>
                    <span 
                    className='greyTitle'  
                    data-tip='Your Personal Cadet Number' 
                    data-for='playerDashTut'>CADET ID</span>
                    <span className='information-value'>Your Username</span>
                    <span className='information-value'>{'Your Cadet ID'}</span>
                    <span 
                    className='greyTitle'
                    data-tip="Performance on the Leaderboard determines your Rank" 
                    data-for="playerDashTut">RANK</span>
                    <span 
                    className='greyTitle'
                    data-tip='Complete Missions from the Mission board to gain EXP' 
                    data-for='playerDashTut'
                    >LVL</span>
                    <span className='information-value'>{'Your Cadet Rank'}</span>
                    <span className='information-value'>{'Your Cadet Level'}</span>
                </div>
                <span className='greyTitle'>MP</span>
                <span className='information-value-mp'>{0 + '/1000'}</span>
                <ProgressBar percentage={parseInt('0')} />
                </div>
            </div> }
            <TutorialPopup
                title="Cadet ID" 
                text="Your Cadet ID is unique to your linked Wallet and is used to track data across the StarLaunch Platform, such as Mission data and Hydrazine Allocation"
                containerStyle={{position: 'relative', marginLeft: '50px'}}
                maxPages={5}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)} />
        </>}
        {page === 3 && <TutorialPopup
            title="Connect wallet" 
            text="To connect your wallet, click the “Connect Wallet” button on the top right hand corner."
            containerStyle={{top: '10vh', left: '80vw'}}
            maxPages={5}
            currentPage={page}q
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page === 4 && <>
                {!mobileDetect.isMobile() &&  <div className='informationContainer'>
                    <div className='informationHeader'>PERSONAL INFORMATION</div>
                    <Button text="Create Cadet ID"  />
                </div> }
                <TutorialPopup
                title="Create Cadet ID" 
                text="If you have not created a Cadet ID, click on the “Create Cadet ID” button when you are finished with the tutorial"
                containerStyle={{position: 'relative', marginLeft: '50px'}}
                maxPages={5}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)} />
            </>}
        {page === 5 && <TutorialPopup
            title="KYC" 
            text="The next step is to complete your KYC process and you are all set Commander!"
            containerStyle={{position: 'relative'}}
            maxPages={5}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => closeTutorial()} />}
        <ReactTooltip id="playerDashTut" effect="solid" place="top" html={true} />
    </div>
    )
};

export default PlayerDashboardTutorial;
