import React from 'react';
import ClaimIcon from '../../images/svg/claimVault.svg';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import Button from '../Button/Button';
import './TrancheBox.scss';

function TrancheBox(props) {
    const {
        claimTokens,
        index,
        amount,
        pool,
        mint,
        number,
        proof,
        decimals,
        claimMode,
        claimStatus,
        claimDate,
        timePassed,
        claimed,
    } = props;

    const _claimTokens = () => {
        claimTokens(pool, mint, number, amount, index, proof);
    }

    const trancheState = () => {

        if (!amount || parseInt(amount) === 0) {
            return <span className='trancheText'>No Claim Available</span>;
        }

        if (((claimMode === 1 && claimStatus === 2) || (claimMode === 2 && timePassed)) && !claimed) {
            return <Button text="claim" onClick={_claimTokens} Icon={ClaimIcon} />;
        }

        if (claimed) {
            return <span className='trancheText'>Already Claimed</span>;
        }

        if (claimMode === 1 && claimStatus !==2) {
            return <span className='trancheText'>Claims Not Open</span>;
        }

        if (claimMode === 2 && !timePassed) {
            return <span className='trancheText'>Claim opens on {moment.unix(claimDate).utc().format("MMM DD YYYY, h:mm:ss A")} UTC</span>;
        }

        return (
            <span className='trancheText'>Claim Not Found</span>
        );
    }


    return (
        <div className='trancheBoxContainer'>
            <span className='trancheTitle'>TRANCHE {number}</span>
            {amount && parseInt(amount) !== 0 && <span className='trancheValue'>Claim: {new BigNumber(amount).div(new BigNumber(10).pow(new BigNumber(decimals))).toFixed(2)}</span>}
            {trancheState()}
        </div>
    )
}

export default TrancheBox;
