import React, {useCallback, useMemo, useState} from "react";
import styles from "./CadetWalletInput.module.scss";
import TextInput from "../TextInput/TextInput";
import {useDispatch, useSelector} from "react-redux";
import Button, {BUTTON_TYPE_PRIMARY_BRIGHT} from "../Button/Button";
import * as helpers from "../../Helpers/helper";
import { isAddress } from 'web3-validator';
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../Helpers/toastCSS";
import {setCadetWallets} from "../Header/statistics";
import solanaIcon from '../../images/sol_icon.png'
import ethIcon from '../../images/eth_icon.png'
import polygonIcon from '../../images/polygon_icon.png'
import arbitrumIcon from '../../images/arbitrum_icon.png'
import supraIcon from '../../images/supra_icon.png'
import suiIcon from '../../images/SUI_icon.png'
import avalancheIcon from '../../images/avalancheLogo.png'
import dymensionIcon from '../../images/dymensionLogo.png'
import skaleIcon from '../../images/skale.png'
import baseIcon from '../../images/base_icon.png'
import Modal from "../Modal/Modal";
import {useWallet} from "@solana/wallet-adapter-react";
import {hasValue, isSUIAddress, isSUPRAAddress} from "../../Helpers/helperFunctions";
import Checkbox from "../Checkbox/Checkbox";
import {Tooltip} from "react-tooltip";

function CadetWalletInput() {
    const dispatch = useDispatch();
    const wallet = useWallet();

    const ethereumWallet = useSelector((state) => state.statistics.cadetStats.cadetEthereumWallet);
    const polygonWallet = useSelector((state) => state.statistics.cadetStats.cadetPolygonWallet);
    const arbitrumWallet = useSelector((state) => state.statistics.cadetStats.cadetArbitrumWallet);
    const supraWallet = useSelector((state) => state.statistics.cadetStats.cadetSupraWallet);
    const suiWallet = useSelector((state) => state.statistics.cadetStats.cadetSUIWallet);
    const avalancheWallet = useSelector((state) => state.statistics.cadetStats.cadetAvalancheWallet);
    const dymensionWallet = useSelector((state) => state.statistics.cadetStats.cadetDymensionWallet);
    const skaleWallet = useSelector((state) => state.statistics.cadetStats.cadetSkaleWallet);
    const baseWallet = useSelector((state) => state.statistics.cadetStats.cadetBaseWallet);
    const cadetWallet = useSelector((state) => state.statistics.cadetStats.cadetWallet);
    const cadetId = useSelector((state) => state.statistics.cadetStats.cadetId);

    const [ethereumWalletInput, setEthereumWalletInput] = useState(ethereumWallet);
    const [polygonWalletInput, setPolygonWalletInput] = useState(polygonWallet);
    const [arbitrumWalletInput, setArbitrumWalletInput] = useState(arbitrumWallet);
    const [supraWalletInput, setSupraWalletInput] = useState(supraWallet);
    const [suiWalletInput, setSUIWalletInput] = useState(suiWallet);
    const [avalancheWalletInput, setAvalancheWalletInput] = useState(avalancheWallet);
    const [dymensionWalletInput, setDymensionWalletInput] = useState(dymensionWallet);
    const [skaleWalletInput, setSkaleWalletInput] = useState(skaleWallet);
    const [baseWalletInput, setBaseWalletInput] = useState(baseWallet);

    const ethereumWalletDisabled = isAddress(ethereumWallet);
    const polygonWalletDisabled = isAddress(polygonWallet);
    const arbitrumWalletDisabled = isAddress(arbitrumWallet);
    const supraWalletDisabled = isAddress(supraWallet);
    const suiWalletDisabled = isSUIAddress(suiWallet)
    const avalancheWalletDisabled = isAddress(avalancheWallet);
    const dymensionWalletDisabled = isAddress(dymensionWallet);
    const skaleWalletDisabled = isAddress(skaleWallet);
    const baseWalletDisabled = isAddress(baseWallet);

    const ethereumInputIsValid = useMemo(() => isAddress(ethereumWalletInput), [ethereumWalletInput]);
    const polygonInputIsValid = useMemo(() => isAddress(polygonWalletInput), [polygonWalletInput]);
    const arbitrumInputIsValid = useMemo(() => isAddress(arbitrumWalletInput), [arbitrumWalletInput]);
    const supraInputIsValid = useMemo(() => isSUPRAAddress(supraWalletInput), [supraWalletInput]);
    const suiInputIsValid = useMemo(() => isSUIAddress(suiWalletInput), [suiWalletInput]);
    const avalancheInputIsValid = useMemo(() => isAddress(avalancheWalletInput), [avalancheWalletInput]);
    const dymensionInputIsValid = useMemo(() => isAddress(dymensionWalletInput), [dymensionWalletInput]);
    const skaleInputIsValid = useMemo(() => isAddress(skaleWalletInput), [skaleWalletInput]);
    const baseInputIsValid = useMemo(() => isAddress(baseWalletInput), [baseWalletInput]);

    const [showWarningPopup, setShowWarningPopup] = useState(false);
    const [agreeCheck, setAgreeCheck] = useState(false);

    const API_URL =  helpers.getApiUrl();

    const submitButtonDisabled = () => {
        if (ethereumWalletInput !== '' && !ethereumInputIsValid) {
            return true;
        }

        if (polygonWalletInput !== '' && !polygonInputIsValid) {
            return true;
        }

        if (arbitrumWalletInput !== '' && !arbitrumInputIsValid) {
            return true;
        }

        if (supraWalletInput !== '' && !supraInputIsValid) {
            return true;
        }

        if (suiWalletInput !== '' && !suiInputIsValid) {
            return true;
        }

        if (avalancheWalletInput !== '' && !avalancheInputIsValid) {
          return true;
        }

        if (dymensionWalletInput !== '' && !dymensionInputIsValid) {
          return true;
        }

        if (skaleWalletInput !== '' && !skaleInputIsValid) {
          return true;
        }

        if (baseWalletInput !== '' && !baseInputIsValid) {
          return true;
        }

        return ethereumWalletInput === ethereumWallet && polygonWalletInput === polygonWallet &&
          arbitrumWalletInput === arbitrumWallet && supraWalletInput === supraWallet &&
          suiWalletInput === suiWallet && avalancheWalletInput === avalancheWallet &&
          dymensionWalletInput === dymensionWallet && skaleWalletInput === skaleWallet && baseWalletInput === baseWallet
    };

    const sendCadetData = async () => {
        const resp = await fetch(API_URL + '/cadetInfo', {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "accept": "*",
                'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
            },
            body: JSON.stringify({
                cadetId: cadetId,
                solanaWallet: hasValue(cadetWallet) && cadetWallet !== '0' ? cadetWallet : wallet.publicKey.toString(),
                ethereumWallet: ethereumInputIsValid ? ethereumWalletInput : ethereumWallet,
                polygonWallet: polygonInputIsValid ? polygonWalletInput : polygonWallet,
                arbitrumWallet: arbitrumInputIsValid ? arbitrumWalletInput : arbitrumWallet,
                supraWallet: supraInputIsValid ? supraWalletInput : supraWallet,
                suiWallet: suiInputIsValid ? suiWalletInput : suiWallet,
                avalancheWallet: avalancheInputIsValid ? avalancheWalletInput : avalancheWallet,
                dymensionWallet: dymensionInputIsValid ? dymensionWalletInput : dymensionWallet,
                skaleWallet: skaleInputIsValid ? skaleWalletInput : skaleWallet,
                baseWallet: baseInputIsValid ? baseWalletInput : baseWallet,
            }),
        });

        if (resp.ok) {
            toast('Wallets Saved!', successToast);

            dispatch(setCadetWallets({
                cadetEthereumWallet: ethereumInputIsValid ? ethereumWalletInput : ethereumWallet,
                cadetPolygonWallet: polygonInputIsValid ? polygonWalletInput : polygonWallet,
                cadetArbitrumWallet: arbitrumInputIsValid ? arbitrumWalletInput : arbitrumWallet,
                cadetSupraWallet: supraInputIsValid ? supraWalletInput : supraWallet,
                cadetSUIWallet: suiInputIsValid ? suiWalletInput : suiWallet,
                cadetAvalancheWallet: avalancheInputIsValid ? avalancheWalletInput : avalancheWallet,
                cadetDymensionWallet: dymensionInputIsValid ? dymensionWalletInput : dymensionWallet,
                cadetSkaleWallet: skaleInputIsValid ? skaleWalletInput : skaleWallet,
                cadetBaseWallet: baseInputIsValid ? baseWalletInput : baseWallet,
            }))
        } else {
            toast('Error saving wallet', errorToast);
        }


        setShowWarningPopup(false);
    }

    return (
      <div className={styles.InputContainer}>
        <h4 className={styles.etherHeaderText}>
          Fasten your seatbelt, Cadet and prepare yourself for our Cross-Chain token launches.
          <br/>
          Enter your non-custodial cross-chain wallets, to get access to the sales.
          <br/>
          <br/>
          <span
            className={styles.centralizedExchangeWarning}>IMPORTANT: CENTRALIZED EXCHANGE ADDRESSES CAN'T BE USED.</span>

        </h4>
        <br/>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={solanaIcon}
            label={'Solana Wallet'}
            disabled={true}
            value={cadetWallet}/>
        </div>
        <div className={styles.validationTextContainer}>
                <span
                  className={styles.validationTextCorrect}>
                    SOLANA WALLET AUTOMATICALLY VALIDATED
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={ethIcon}
            label={'Ethereum Wallet'}
            disabled={ethereumWalletDisabled}
            value={ethereumWalletInput}
            setValue={setEthereumWalletInput}/>

        </div>
        <div className={styles.validationTextContainer}>
                <span
                  className={ethereumInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                   {ethereumWalletInput !== '' && !ethereumInputIsValid && 'NOT A VALID ETHEREUM WALLET ADDRESS'}
                  {ethereumInputIsValid && 'VALID ETHEREUM WALLET ADDRESS'}
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={polygonIcon}
            label={'Polygon Wallet'}
            disabled={polygonWalletDisabled}
            value={polygonWalletInput} s
            setValue={setPolygonWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
                <span
                  className={polygonInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                    {polygonWalletInput !== '' && !polygonInputIsValid && 'NOT A VALID Polygon WALLET ADDRESS'}
                  {polygonInputIsValid && 'VALID POLYGON WALLET ADDRESS'}
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={arbitrumIcon}
            label={'Arbitrum Wallet'}
            disabled={arbitrumWalletDisabled}
            value={arbitrumWalletInput}
            setValue={setArbitrumWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
                <span
                  className={arbitrumInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                    {arbitrumWalletInput !== '' && !arbitrumInputIsValid && 'NOT A VALID ARBITRUM WALLET ADDRESS'}
                  {arbitrumInputIsValid && 'VALID ARBITRUM WALLET ADDRESS'}
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={supraIcon}
            label={'Supra Wallet'}
            value={supraWalletInput}
            setValue={setSupraWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
                        <span
                          className={supraInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                    {supraWalletInput !== '' && !supraInputIsValid && 'NOT A VALID SUPRA WALLET ADDRESS'}
                          {supraInputIsValid && 'VALID SUPRA WALLET ADDRESS'}
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={suiIcon}
            label={'SUI Wallet'}
            disabled={suiWalletDisabled}
            value={suiWalletInput}
            setValue={setSUIWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
                <span
                  className={suiInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                    {suiWalletInput !== '' && !suiInputIsValid && 'NOT A VALID SUI WALLET ADDRESS'}
                  {suiInputIsValid && 'VALID SUI WALLET ADDRESS'}
                </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            iconSrc={avalancheIcon}
            label={'Avalanche Wallet'}
            disabled={avalancheWalletDisabled}
            value={avalancheWalletInput}
            setValue={setAvalancheWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
              <span
                className={avalancheInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                  {avalancheWalletInput !== '' && !avalancheInputIsValid && 'NOT A VALID AVALANCHE WALLET ADDRESS'}
                {avalancheInputIsValid && 'VALID AVALANCHE WALLET ADDRESS'}
              </span>
        </div>

        <div className={styles.walletRow}>
          <TextInput
            id="dymensionWalletInput"
            tooltipContent={<span>Add your EVM compatible Dymension wallet<br/>The wallet should start with 0x if its EVM compatible</span>}
            iconSrc={dymensionIcon}
            label={'Dymension Wallet (EVM)'}
            disabled={dymensionWalletDisabled}
            value={dymensionWalletInput}
            setValue={setDymensionWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
              <span
                className={dymensionInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                  {dymensionWalletInput !== '' && !dymensionInputIsValid && 'NOT A VALID DYMENSION WALLET ADDRESS'}
                {dymensionInputIsValid && 'VALID DYMENSION WALLET ADDRESS'}
              </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            id="skaleWalletInput"
            iconSrc={skaleIcon}
            label={'Skale Wallet'}
            disabled={skaleWalletDisabled}
            value={skaleWalletInput}
            setValue={setSkaleWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
              <span
                className={skaleInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                  {skaleWalletInput !== '' && !skaleInputIsValid && 'NOT A VALID SKALE WALLET ADDRESS'}
                {skaleInputIsValid && 'VALID SKALE WALLET ADDRESS'}
              </span>
        </div>
        <div className={styles.walletRow}>
          <TextInput
            id="baseWalletInput"
            iconSrc={baseIcon}
            label={'BASE Wallet'}
            disabled={baseWalletDisabled}
            value={baseWalletInput}
            setValue={setBaseWalletInput}/>
        </div>
        <div className={styles.validationTextContainer}>
              <span
                className={baseInputIsValid ? styles.validationTextCorrect : styles.validationText}>
                  {baseWalletInput !== '' && !baseInputIsValid && 'NOT A VALID BASE WALLET ADDRESS'}
                {baseInputIsValid && 'VALID BASE WALLET ADDRESS'}
              </span>
        </div>

        <div className={styles.submitButtonRow}>
          <Button
            onClick={() => setShowWarningPopup(true)}
            text={'Submit Wallet'}
            buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}
            disabled={submitButtonDisabled()}/>

        </div>
        <Modal
          exit
          setShow={setShowWarningPopup}
          show={showWarningPopup}
          onConfirm={sendCadetData}
          confirmButtonDisabled={agreeCheck === false}
          confirmButtonText={'Submit'}>
          <div>
            <span>You have entered the following wallet addresses:</span>
            <br/>
            <br/>
            {!isAddress(ethereumWallet) && ethereumInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>ETHEREUM:</span>
                <span className={styles.validationTextCorrect}>{ethereumWalletInput}</span>
              </div>
            }
            {!isAddress(polygonWallet) && polygonInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>POLYGON:</span>
                <span className={styles.validationTextCorrect}> {polygonWalletInput}</span>
              </div>}
            {!isAddress(arbitrumWallet) && arbitrumInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>ARBITRUM:</span>
                <span className={styles.validationTextCorrect}>{arbitrumWalletInput}</span>
              </div>}
            {!isSUPRAAddress(supraWallet) && supraInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>SUPRA:</span>
                <span className={styles.validationTextCorrect}>{supraWalletInput}</span>
              </div>}
            {!isSUIAddress(suiWallet) && suiInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>SUI:</span>
                <span className={styles.validationTextCorrect}>{suiWalletInput}</span>
              </div>}
            {!isAddress(avalancheWallet) && avalancheInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>AVALANCHE:</span>
                <span className={styles.validationTextCorrect}>{avalancheWalletInput}</span>
              </div>}
            {!isAddress(dymensionWallet) && dymensionInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>DYMENSION:</span>
                <span className={styles.validationTextCorrect}>{dymensionWalletInput}</span>
              </div>}
            {!isAddress(skaleWallet) && skaleInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>SKALE:</span>
                <span className={styles.validationTextCorrect}>{skaleWalletInput}</span>
              </div>}
            {!isAddress(baseWallet) && baseInputIsValid &&
              <div className={styles.walletModalTextRow}>
                <span>BASE:</span>
                <span className={styles.validationTextCorrect}>{baseWalletInput}</span>
              </div>}
            <span>
                Once submitted, wallet addresses will be locked in and used for all future token sales on the associated blockchain.
            </span>
            <Checkbox id="vaultAgree" onChange={(e) => setAgreeCheck(prev => !prev)} checked={agreeCheck}
                      label={<span className={styles.walletModalCheckbox}>I have double checked the information and understand that wallets can't be edited.</span>}/>
          </div>

        </Modal>
      </div>
    );
}

export default CadetWalletInput;
