import React, {useState, useEffect, useMemo, useCallback} from 'react';

import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
// import anchor  from '@project-serum/anchor';
import toast from 'react-hot-toast';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import BigNumber from "bignumber.js";
import styles from "./IdoNodeSaleDetails.module.scss";
import IDOPageVideo from '../../images/idoPageBackground.webm';
import IDOPageVideoMobile from '../../images/idoPageBackgroundMobile.webm';
import IDOPageVideoMobileMp4 from '../../images/idoPageBackgroundMobile.mp4';
import BackButton from "../../images/svg/backIcon.svg"
import TelegramIcon from '../../images/svg/telegram.svg'
import MediumIcon from '../../images/svg/medium.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import WebsiteIcon from '../../images/svg/website.svg'
import DiscordIcon from '../../images/svg/discord.svg'
import CaretForward from '../../images/svg/caretForward.svg';
import LockIcon from '../../images/svg/lock-closed.svg';
import AccountAddIcon from '../../images/svg/account-add.svg';
import WalletNotConnectedIcon from '../../images/svg/walletNotConnected.svg';
import aethirInvestors from '../../images/aethir-investors.png'
import aethirRoadmap from '../../images/aethir-roadmap.jpeg'
import { useHistory, useParams } from 'react-router-dom';
import { PATH_IDO_PAGE, PATH_PLAYER_DASHBOARD } from '../../App';
import Button, {
  BUTTON_TYPE_PRIMARY_BRIGHT,
  ProgressButtonClickAudio,
  ProgressButtonHoverAudio
} from '../../Components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { authInfo, authTitle, connectWalletText, connectWalletTitle } from '../Vault/Vault';
import InfoCard from '../../Components/InfoCard/InfoCard';
import { useRef } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import LoadingSpinner, {
  LOADING_SPINNER_LARGE,
  LOADING_SPINNER_MEDIUM
} from '../../Components/LoadingSpinner/LoadingSpinner';
import * as helpers from '../../Helpers/helper';
import _ from 'lodash';
import {
  FRACTAL_LINK,
  getNodeIdoProgramId,
  getNodeIdoProgramName, getNodeIdoProgramNameBuffer,
  getNodeProgram,
  KYC_STATUS_APPROVED,
  openURL
} from "../../Helpers/helper";
import {isAddress} from "web3-validator";
import InformationIcon from "../../images/svg/information2.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {errorToast, successToast} from "../../Helpers/toastCSS";
import {setShowCreateIdoAccount, setShowCreateNodeIdoAccount} from "../../Components/Header/statistics";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import moment from "moment/moment";
import Countdown from "react-countdown";
import Checkbox from "../../Components/Checkbox/Checkbox";
import {ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {getTokenAccountsByOwner} from "../../utils";
import {hasValue} from "../../Helpers/helperFunctions";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const FIRST_SALE_STAGE = 'OG Round';
const SECOND_SALE_STAGE = 'Stakers Round';
const THIRD_SALE_STAGE = 'Public Round'
const ENDED_SALE_STAGE = 'Ended'

export const IDO_NODE_NAME = 'aethir';

export const PROGRAM_NAME = 'star_node3';

// const USDC_MINT = "CYoKH2gmndgTky3CEdrXhNRXjiHvUJ1UFkcjjjGWBqun";
// const HYDRAZINE_MINT = "H8KpvSniaKpTF4VQxtj2igC5cpvvS3zJgawm3ehGrrPa";

const USDC_MINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
const HYDRAZINE_MINT = "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda";

function IdoNodeSaleDetails() {

  const { connection } = useConnection();
  const wallet = useWallet();
  const history = useHistory();
  const dispatch = useDispatch();
  const detectMobile = useMobileDetect();
  const API_URL = helpers.getApiUrl();

  const [isLoading, setIsLoading] = useState(true);
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);
  const [isPurchasingNode, setIsPurchasingNode] = useState(false);
  const [videoSize, setVideoSize] = useState({ width: '640', height: '320'})
  const [saleStage, setSaleStage] = useState(FIRST_SALE_STAGE);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const [provider, setProvider] = useState();
  const [merkelProof, setMerkelProof] = useState()
  const [poolInfo, setPoolInfo] = useState();
  const [hasBoughtNode, setHasBoughtNode] = useState();
  const [walletBalance, setWalletBalance] = useState({ usdc: 0, hydrazine: 0 })
  const [hasParticipation, setHasParticipation] = useState(false);
  const [nodeData, setNodeData] = useState({ nodesSold: 75, maxNodes: 75 })
  const [saleTimes, setSaleTimes] = useState({
    ogSaleStart: null,
    ogSaleEnd: null,
    stakerSaleStart: null,
    stakerSaleEnd: null,
    openSaleStart: null,
  })

  const isMuted = useSelector((state) => state.sound.mute);
  const hasCadetAccount = !useSelector((state) => state.statistics.showCreateAccount);
  const hasNodeIdoAccount = !useSelector((state) => state.statistics.showCreateNodeIdoAccount);
  const arbitrumWallet = useSelector((state) => state.statistics.cadetStats.cadetArbitrumWallet);
  const personallyStakedStars = useSelector((state) => state.statistics.personalCryptoStats.personallyStakedStars);
  const KYCStatus = useSelector((state) => state.statistics.userStats.KYCStatus);

  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;

  const primaryContainerRef = useRef();

  useEffect(() => {
    if (wallet.publicKey) {
      setProvider(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()));
    }
  }, [wallet.connected]);

  const onResize = () => {
    if (primaryContainerRef.current) {
      const videoWidth = primaryContainerRef.current.clientWidth;
      const videoHeight = videoWidth / 2

      if (videoWidth !== videoSize.width) {
        setVideoSize({width: videoWidth, height: videoHeight  })
      }

    }
  }

  useEffect(() => {
    if (primaryContainerRef.current) {
      const videoWidth = primaryContainerRef.current.clientWidth ;
      const videoHeight = videoWidth / 2
      setVideoSize({width: videoWidth, height: videoHeight  })
    }
  }, [primaryContainerRef.current]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    const getMerkleProof = async () => {
      const data = await fetch(API_URL + `/node/allocation?wallet=${wallet.publicKey.toString()}&sale=aethir`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "accept": "*"
        },
      });


      if (!(await data).ok) return []
      const result = await data.json();
      setMerkelProof(result.data);
    }

    if (hasParticipation) {
      getMerkleProof();
    }
  }, [hasParticipation]);

  useEffect(() => {
    if (provider && provider.wallet.connected) {
      getAccountData();
      fetchTokensBalance();
    }
  }, [provider]);

  const fetchTokensBalance = async () => {
    const tokenAccs = await getTokenAccountsByOwner(connection, wallet.publicKey);
    const hydrazine = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === HYDRAZINE_MINT)
    const usdc = tokenAccs.filter(acc => acc.accountInfo.mint.toBase58() === USDC_MINT)

    let hydrazineReturn = 0;
    let usdcReturn = 0;

    if (hydrazine !== null && hydrazine.length !== 0) {
      const hydrazineBalance = await connection.getTokenAccountBalance(hydrazine[0].pubkey);

      hydrazineReturn = hydrazineBalance.value.uiAmount || 0;
    }

    if (usdc !== null && usdc.length !== 0) {
      const usdcBalance = await connection.getTokenAccountBalance(usdc[0].pubkey);

      usdcReturn = usdcBalance.value.uiAmount || 0;
    }

    return setWalletBalance({usdc: usdcReturn, hydrazine: hydrazineReturn });
  }


  const getAccountData = async () => {
    if (hasNodeIdoAccount) {
      const nodeIdoProgram = helpers.getNodeProgram(provider);
      const accts = await helpers.getAddys(provider, IDO_NODE_NAME, true);

      const poolInfo = await nodeIdoProgram.account.pool.fetch(accts.nodePool);

      setPoolInfo(poolInfo);
      const ogSaleStart = poolInfo.idoTimes.ogSaleStart.toString();
      const ogSaleEnd =poolInfo.idoTimes.ogSaleEnd.toString(); // moment().add(1, 'days').unix()
      const stakerSaleStart = poolInfo.idoTimes.stakerSaleStart.toString();//moment().add(2, 'days').unix() //
      const stakerSaleEnd =poolInfo.idoTimes.stakerSaleEnd.toString();// moment().add(3, 'days').unix() //
      const openSaleStart = poolInfo.idoTimes.openSaleStart.toString();//moment().add(4, 'days').unix() //

      setSaleTimes({
        ogSaleStart,
        ogSaleEnd,
        stakerSaleStart,
        stakerSaleEnd,
        openSaleStart,
      });

      const nodeDataLocal = { nodesSold: Number(poolInfo.nodesSold.toString()), maxNodes: Number(poolInfo.nodesForSale.toString()) };

      calculateSalesStage(ogSaleStart,
        ogSaleEnd,
        stakerSaleStart,
        stakerSaleEnd,
        openSaleStart,
        nodeDataLocal);

     // setNodeData(nodeDataLocal)

      const pptInit = await connection.getBalance(accts.nodePpt);

      if (pptInit > 0) {
        setHasParticipation(true);
        const pptInfo = await nodeIdoProgram.account.participant.fetch(accts.nodePpt);
        setHasBoughtNode(pptInfo.bought.toString() !== '0')
      }
    }
  }

  const calculateSalesStage = (ogSaleStart, ogSaleEnd, stakerSaleStart, stakerSaleEnd, openSaleStart, nodeDataLocal) => {
    const timeNow = moment().unix();
    return setSaleStage(ENDED_SALE_STAGE);
    if (timeNow > ogSaleStart && timeNow < ogSaleEnd) {
      return setSaleStage(FIRST_SALE_STAGE);
    }

    if (timeNow > stakerSaleStart && timeNow < stakerSaleEnd) {
      return setSaleStage(SECOND_SALE_STAGE);
    }

    if (timeNow > openSaleStart) {
      return setSaleStage(THIRD_SALE_STAGE);
    }

    if (nodeDataLocal.nodesSold === nodeDataLocal.maxNodes) {
      return setSaleStage(ENDED_SALE_STAGE);
    }

    setSaleStage('Waiting');
  }

  async function createUser() {
      try {
          setIsSendingTransaction(true);
          const nodeIdoProgram = helpers.getNodeProgram(provider);
          const accts = await helpers.getAddys(provider, IDO_NODE_NAME, true);

          const transaction = new web3.Transaction();

          transaction.add(
              nodeIdoProgram.instruction.createUser(
                  accts.nodeIdoAcctBump,
                  {
                      accounts: {
                          userAuthority: provider.wallet.publicKey,
                          idoUser: accts.nodeIdoAcct,
                          mainData: accts.mainData,
                          systemProgram: SystemProgram.programId,
                          rent: SYSVAR_RENT_PUBKEY,
                      }
                  })
          );

          const signature = await provider.wallet.sendTransaction(transaction, connection);
          await connection.confirmTransaction(signature, 'processed');
          toast("NODE IDO Account Created", successToast);
          dispatch(setShowCreateNodeIdoAccount(false));
      } catch (e) {
          console.warn("Failed", e);
          toast(e.message, errorToast);
      } finally {
        setIsSendingTransaction(false);
      }
  }

  async function createParticipantAccount() {
      try {
        setIsSendingTransaction(true);
          const nodeIdoProgramID = getNodeIdoProgramId();

          const [pool, poolBump] = web3.PublicKey.findProgramAddressSync(
              [Buffer.from(IDO_NODE_NAME)],
              nodeIdoProgramID
          );

          const [idoUser, idoUserBump] = web3.PublicKey.findProgramAddressSync(
              [provider.wallet.publicKey.toBuffer(), Buffer.from(PROGRAM_NAME), Buffer.from("pool_user")],
              nodeIdoProgramID
          );
          const [participant, participantBump] = web3.PublicKey.findProgramAddressSync(
              [provider.wallet.publicKey.toBuffer(), Buffer.from(IDO_NODE_NAME), Buffer.from("pool_participant")],
              nodeIdoProgramID
          );

          const nodeIdoProgram = helpers.getNodeProgram(provider);
          const transaction = new web3.Transaction();

          transaction.add(
            nodeIdoProgram.instruction.createParticipant(
                  participantBump,
                  {
                      accounts: {
                          userAuthority: provider.wallet.publicKey,
                          idoUser: idoUser,
                          participant: participant,
                          pool: pool,
                          systemProgram: SystemProgram.programId,
                          rent: SYSVAR_RENT_PUBKEY,
                      }
                  })
          );

          const signature = await provider.wallet.sendTransaction(transaction, connection);
          await connection.confirmTransaction(signature, 'processed');
          toast("Participation Account Created", successToast);
          setHasParticipation(true);
         // await getAccountData();
      } catch (e) {
          console.warn("Failed", e);
          toast(e.message, errorToast);
      } finally {
        setIsSendingTransaction(false);
      }
  }

  const toBytes32Array = (b) => {
    const buf = Buffer.alloc(32);
    b.copy(buf, 32 - b.length);
    return Array.from(buf);
  };

  const getHydrazinePrice = useCallback(() => {
    if (!poolInfo) {
      return;
    }

    if (saleStage === FIRST_SALE_STAGE) {
      return poolInfo?.hydrazinePriceOg;
    }
    if (saleStage === SECOND_SALE_STAGE) {
      return poolInfo?.hydrazinePriceStaker;
    }
    if (saleStage === THIRD_SALE_STAGE) {
      return poolInfo?.hydrazinePriceOpen;
    }

  }, [poolInfo, saleStage]);

  const purchaseNode = async () => {
    try {
      setIsPurchasingNode(true);
      const nodeIdoProgramID = getNodeIdoProgramId();
      const nodeIdoProgram = helpers.getNodeProgram(provider);
      const index = new BN(merkelProof.index);
      const totalAmount = new BN(1);

      const hydPrice = getHydrazinePrice();

      if (!hasValue(hydPrice)) {
        return toast(errorToast, 'ERROR: No current stage detected');
      }

      const hydrazine_amount = new BN(hydPrice);
      const usdc_amount = new BN(poolInfo?.usdcPrice);

      const proof = saleStage === FIRST_SALE_STAGE ? merkelProof.proof.map((p) => toBytes32Array(Buffer.from(p, "hex"))) : [];

      const [mainData, mainDataBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(PROGRAM_NAME)],
        nodeIdoProgramID
      );
      const [pool, poolBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(IDO_NODE_NAME)],
        nodeIdoProgramID
      );
      const [usdcVault, usdcBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(IDO_NODE_NAME), Buffer.from("usdc_vault")],
        nodeIdoProgramID
      );
      const [hydrazineVault, hydrazineBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(IDO_NODE_NAME), Buffer.from("hydrazine_vault")],
        nodeIdoProgramID
      );
      let usdcMintKey = new web3.PublicKey(USDC_MINT);
      let hydrazineMintKey = new web3.PublicKey(HYDRAZINE_MINT);

      const [userUsdc, userUsdcBump] = await web3.PublicKey.findProgramAddress([
          provider.wallet.publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          usdcMintKey.toBuffer()
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID);
      const [userHydrazine, userHydrazineBump] = await web3.PublicKey.findProgramAddress([
          provider.wallet.publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          hydrazineMintKey.toBuffer()
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID);

      const [idoUser, idoUserBump] = web3.PublicKey.findProgramAddressSync(
        [
          provider.wallet.publicKey.toBuffer(), 
          Buffer.from(PROGRAM_NAME), 
          Buffer.from("pool_user")
        ],
        nodeIdoProgramID
      );
      const [participant, participantBump] = web3.PublicKey.findProgramAddressSync(
        [provider.wallet.publicKey.toBuffer(), Buffer.from(IDO_NODE_NAME), Buffer.from("pool_participant")],
        nodeIdoProgramID
      );
      const transaction = new web3.Transaction();

      transaction.add(
        nodeIdoProgram.instruction.purchaseNode(
          index,
          totalAmount,
          proof,
          hydrazine_amount,
          usdc_amount,
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              idoUser: idoUser,
              mainData: mainData,
              participant: participant,
              pool: pool,
              usdcMint: usdcMintKey,
              hydrazineMint: hydrazineMintKey,
              userUsdc: userUsdc,
              userHydrazine: userHydrazine,
              usdcVault: usdcVault,
              hydrazineVault: hydrazineVault,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            }
          })
        );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("NODE Successfully purchased!", successToast);
      setHasBoughtNode(true);
      setNodeData(prev => ({ maxNodes: prev.maxNodes, nodesSold: prev.nodesSold + 1 }));

  } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
  } finally {
      setIsPurchasingNode(false);
    }
  }

  const backButtonClick = () => {
    if (!isMuted) {
      ProgressButtonClickAudio.play();
    }
    history.push(PATH_IDO_PAGE);
  }

  const _onHover = () => {
    if (!isMuted) {
      ProgressButtonHoverAudio.play();
    }
  }

  const saleInfoRender = useMemo(() => {
    return (
      <>
        <div className={styles.saleInfoContentHeader}>
          <h4 className={styles.contentTitle}>Aethir</h4>
        </div>
        <div className={styles.contentSubTitle}>Decentralized Cloud Infrastructure (DCI) for Gaming & AI</div>
        <div className={styles.contentText}>
          Aethir is an enterprise-focused,
          distributed GPU cloud provider with the largest GPU network and highest committed revenue within the DePIN
          sector.
          Aethir holds the keys to global GPU compute
        </div>
        <div className={styles.videoContainer}>
          <iframe src="https://player.vimeo.com/video/876617870?h=50409429f8" width={videoSize.width}
                  height={videoSize.height} frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        <br/>
        <h4 className={styles.contentTitle}>About Aethir</h4>
        <div className={styles.contentText}>
          {aboutAethir}
        </div>
        {aethirSaleContent()}
      </>
    )
  }, [videoSize.height, videoSize.width]);

  const saleEndedContent = (stage, tooltip) => {
    const timeNow = moment().unix();
    const hasEnded = getSaleStageTime(stage, true) < timeNow;

    return (
      <div className={styles.saleEndContainer}>

        <div className={styles.lockContainer}>
          <LockIcon/>
          <div>{stage}</div>
        </div>
        {hasEnded ? <div className={styles.endedText}>Ended</div>
          :
          <div className={styles.untilTimerContainer}>
            <div className={styles.untilCountdownContainer}>
            <div className={styles.endedText}>Time Until:</div>
                <span className={styles.countdownTextUntil}>
                  <Countdown
                    date={new BigNumber(getSaleStageTime(stage)).times(new BigNumber(1000)).toNumber(0)} />
               </span>
              </div>
              <div className={styles.endedText}>{moment.unix(getSaleStageTime(stage)).utc().format("MMMM DD YYYY, h:mm A") + ' UTC'}</div>
            </div>}
        </div>
    )
  }

  const getSaleStageTime = (stage, endTime) => {
    if (stage === FIRST_SALE_STAGE) {
      return endTime === true ? saleTimes.ogSaleEnd : saleTimes.ogSaleStart;
    }
    if (stage === SECOND_SALE_STAGE) {
      return endTime === true ? saleTimes.stakerSaleEnd : saleTimes.stakerSaleStart;
    }
    if (stage === THIRD_SALE_STAGE) {
      return saleTimes.openSaleStart;
    }
  }

  const submitButtonState = useCallback(() => {
    if (nodeData.nodesSold === nodeData.maxNodes) {
      return { disabled: true, reason: 'All NODES have been purchased'}
    }
    if (!agreeCheck) {
      return { disabled: true, reason: 'Accept the terms of service'}
    }
    if (saleStage === FIRST_SALE_STAGE) {
      if (merkelProof?.allocation === false) {
        return { disabled: true, reason: 'You are not a OG Staker'}
      }
    }
    if (saleStage === SECOND_SALE_STAGE) {
      if (personallyStakedStars < 1000) {
        return { disabled: true, reason: "You don't have enough staked STARS in your vault. Minimum is 1000 STARS to participate in this round"}
      }
    }
    return { disabled: false, reason: ''}
  }, [nodeData.nodesSold, nodeData.maxNodes, saleStage, merkelProof?.allocation, personallyStakedStars, agreeCheck]);

  const isApprovedBalance = () => {
    let returnBalance = { usdc: false, hydrazine: false};

    if (walletBalance.usdc > 990) {
      returnBalance.usdc = true;
    }

    if (saleStage === FIRST_SALE_STAGE && walletBalance.hydrazine > 990) {
      returnBalance.hydrazine = true;
    }
    if (saleStage === SECOND_SALE_STAGE && walletBalance.hydrazine > 1500) {
      returnBalance.hydrazine = true;
    }
    if (saleStage === THIRD_SALE_STAGE && walletBalance.hydrazine > 3000) {
      returnBalance.hydrazine = true;
    }

    return returnBalance;
  }

  const saleStageRenderer = (stage, price) => {
    const percentage = (nodeData.nodesSold / nodeData.maxNodes) * 100;

    const { disabled, reason } = submitButtonState();
    const { usdc, hydrazine } = isApprovedBalance();

    return (
      <div className={styles.stageContainer}>
        <div className={styles.stageContainerTitleRow}>
          <h4 className={styles.contentTitle}>{stage}</h4>
          <div className={styles.liveContainer}>
            <div className={styles.liveDot}/>
            <span className={styles.liveText}>Live</span>
          </div>
        </div>
        <span className={styles.contentText} style={{marginBottom: '4px'}}>Remaining nodes left to purchase</span>
        <div className={styles.progressBarTextContainer}>
          <span className={styles.endedText}>Purchased</span>
          <span className={styles.endedText}>{nodeData.nodesSold + '/' + nodeData.maxNodes}</span>
        </div>
        <ProgressBar percentage={percentage}/>
        <br/>
        {stage !== THIRD_SALE_STAGE && <div>
          <span className={styles.contentText}>Time left:</span>
          <span className={styles.countdownText}>
            <Countdown date={new BigNumber(getSaleStageTime(stage, true)).times(new BigNumber(1000)).toNumber(0)}  />
          </span>
        </div>}
        <span
          className={styles.contentText}>{moment.unix(getSaleStageTime(stage, true)).utc().format("MMMM DD YYYY, h:mm A") + ' UTC'}</span>
        <div className={styles.submitContainer}>
          {!hasBoughtNode && <>
            <h4 className={styles.smallContentTitle}>PRICE</h4>
            <span className={styles.contentText} style={{marginBottom: '4px'}}>{price.hydrazine} HYDRAZINE + {price.usdc} USDC</span>
            <Checkbox id="vaultAgree" onChange={(e) => setAgreeCheck(prev => !prev)} checked={agreeCheck}
                      label={<span
                        className={styles.walletModalCheckbox}>I agree to the General Terms of Service</span>}/>
            <div className={styles.walletBalanceCheck}>
              <h4 className={styles.smallContentTitle}>Eligibility verification</h4>
              <div className={styles.walletBalanceTokenContainer}>
                HYDRAZINE:
                {hydrazine ?
                  <div className={styles.approvedBalance}>{'✅'}</div> :
                  <div className={styles.notApprovedBalance}>{' ❌' + walletBalance.hydrazine}</div>}
              </div>
              <div className={styles.walletBalanceTokenContainer}>
                USDC:
                {usdc ?
                  <div className={styles.approvedBalance}>{'✅'}</div> :
                  <div className={styles.notApprovedBalance}>{' ❌ ' + walletBalance.usdc}</div>}
              </div>
              <div className={styles.walletBalanceTokenContainer}>
                TERMS OF SERVICE:
                {agreeCheck ?
                  <div className={styles.approvedBalance}>{'✅'}</div> :
                  <div className={styles.notApprovedBalance}>{' ❌ '}</div>}
              </div>
              {stage === FIRST_SALE_STAGE && <div className={styles.walletBalanceTokenContainer}>
                OG:
                {merkelProof?.allocation === true ?
                  <div className={styles.approvedBalance}>{'✅'}</div> :
                  <div className={styles.notApprovedBalance}>{' ❌ ' + 'Your wallet is not on the OG Whitelist'}</div>}
              </div>}
              {stage === SECOND_SALE_STAGE && <div className={styles.walletBalanceTokenContainer}>
                STARS STAKED:
                {personallyStakedStars > 999 ?
                  <div className={styles.approvedBalance}>{'✅'}</div> :
                  <div
                    className={styles.notApprovedBalance}>{' ❌ ' + 'You have ' + personallyStakedStars + ' STARS Staked'}</div>}
              </div>}
            </div>
            {reason && <br/>}
            {(!usdc || !hydrazine) && <div className={styles.submitValidationTextInvalid}>
              Insufficient funds
            </div>}
            {reason === '' && usdc && hydrazine && !hasBoughtNode && <div className={styles.submitValidationText}>
              You are eligible to purchase a Aethir NODE
            </div>}
            <Button onClick={purchaseNode} disabled={disabled && usdc && hydrazine} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} text={'Purchase 1 Aethir Node'}/>
          </>}

        </div>
      </div>
    )
  }

  return (
    <main key="IdoNodeSaleDetails"
                 className={styles.idoNodeSaleDetailsMain}>
      <ReactTooltip id="node" effect="solid" place="right" />
      {isPurchasingNode && <div className={styles.transactionPendingOverlay}>
        <span className={styles.transactionPendingText}>blockchain transaction is pending, do not close this window</span>
        <LoadingSpinner />
      </div>}
      <div className='idoOverlay'/>
      <div className='idoContainer'>

        <div className={wallet.publicKey ? 'backButton' : 'backButtonPosition'} onClick={backButtonClick}
               onMouseEnter={_onHover}>
            <BackButton className='backButtonIcon'/>
            <span>BACK</span>
          </div>
          {!wallet.publicKey &&
              <InfoCard
                  text={connectWalletText()}
                  title={connectWalletTitle()}
                  icon={WalletNotConnectedIcon}/>}
          {!hasCadetAccount && wallet.publicKey &&
              <InfoCard text={authInfo()}
                        title={authTitle()}
                        buttonText="Player Dashboard"
                        buttonIcon={CaretForward}
                        onConfirm={() => history.push(PATH_PLAYER_DASHBOARD)}
                        icon={AccountAddIcon} />}
        {hasCadetAccount && wallet.publicKey &&
            <div className={styles.idoNodeSaleDetails}>
              <div className={styles.primaryContainerCard} ref={primaryContainerRef}>
                {saleInfoRender}
              </div>
              <div className={styles.secondaryContainerCard}>
{/*                <>
                  <h4 className={styles.contentTitle}>Coming soon</h4>
                  <br/>
                  <div>
                    <span className={styles.contentSubTitle}>Node Sale launching in:</span>
                    <span className={styles.countdownText}>
                    <Countdown
                      date={new BigNumber(moment.utc('2024-03-19 12:00').unix()).times(new BigNumber(1000)).toNumber(0)}/>
                  </span>
                  </div>
                </>*/}

                {KYCStatus !== KYC_STATUS_APPROVED && <div className={styles.missingKYCContainer}>
                  <span className={styles.walletKYCText}>Your wallet has not been verified by Fractal. To participate please press the button below to complete KYC</span>
                  <Button text={'complete KYC'} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}
                          onClick={() => openURL(FRACTAL_LINK)}/>
                </div>}
                {!isAddress(arbitrumWallet) && <div className={styles.missingKYCContainer}>
                  <span className={styles.walletKYCText}>To participate in this sale please enter your Arbitrum wallet in the player dashboard</span>
                  <Button text={'Player dashboard'} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}
                          onClick={() => history.push(PATH_PLAYER_DASHBOARD)}/>
                </div>}
                {!hasNodeIdoAccount && <div className={styles.missingKYCContainer}>
                  <span className={styles.walletKYCText}>To participate in this sale please create your Node IDO Account by pressing the button below</span>
                  {isSendingTransaction ? <LoadingSpinner local size={LOADING_SPINNER_MEDIUM} /> : <br/>}
                  <Button text={'Node IDO Account'} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={createUser}/>
                </div>}
                {!hasParticipation && hasNodeIdoAccount && <div className={styles.missingKYCContainer}>
                  <span className={styles.walletKYCText}>Almost there! Now create a participation account and then you are ready to participate!</span>
                  {isSendingTransaction ? <LoadingSpinner local size={LOADING_SPINNER_MEDIUM} /> : <br/>}
                  <Button text={'participation account'} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}
                          onClick={createParticipantAccount}/>
                </div>}
                {KYCStatus === KYC_STATUS_APPROVED && isAddress(arbitrumWallet) && hasNodeIdoAccount && hasParticipation &&
                  <div className={styles.salesInnerContainer}>
                    {saleStage === ENDED_SALE_STAGE ?
                      <>
                        <h4 className={styles.contentTitle}>Sale has ended</h4>
                        <div className={styles.progressBarTextContainer}>
                          <span className={styles.endedText}>All Sold</span>
                          <span className={styles.endedText}>{nodeData.maxNodes + '/' + nodeData.maxNodes}</span>
                        </div>
                        <ProgressBar percentage={100}/>
                        {hasBoughtNode && <div className={styles.submitValidationText}>
                          You have successfully bought 1 Aethir NODE!
                        </div>}
                      </>
                      : <h4 className={styles.contentTitle}>Sale Stage</h4>}
                   {/* {saleStage === FIRST_SALE_STAGE ? saleStageRenderer(FIRST_SALE_STAGE, {hydrazine: 990, usdc: 990})
                      : saleEndedContent(FIRST_SALE_STAGE, 'This stage is for OG Starlaunch stakers, loyal cadets who have been with us since the beginning staking every step of the way')}
                    {saleStage === SECOND_SALE_STAGE ? saleStageRenderer(SECOND_SALE_STAGE, {
                        hydrazine: 1500,
                        usdc: 990
                      }) :
                      saleEndedContent(SECOND_SALE_STAGE, 'This stage is for everyone who has over 1000 STARS staked in the vault')}
                    {saleStage === THIRD_SALE_STAGE ? saleStageRenderer(THIRD_SALE_STAGE, {
                        hydrazine: 3000,
                        usdc: 990
                      })
                      : saleEndedContent(THIRD_SALE_STAGE, 'This stage is for everyone')}*/}

                  </div>}

              </div>
            </div>}
      </div>
      {!detectMobile.isMobile() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsLoading(false)}>
          <source src={IDOPageVideo} type='video/webm' />
        </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
        <video
          className='backgroundVideo'
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setIsLoading(false)} >
          <source src={IDOPageVideoMobile} type='video/webm' />
          <source src={IDOPageVideoMobileMp4} type='video/mp4' />
        </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
        <img className='backgroundVideo' src={IDOPageVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {isLoading && <LoadingSpinner />}
      {/*  {videoOff && <img className='backgroundVideo' src={IDOPageImage} alt="" />} */}

    </main>
  );
}

export default IdoNodeSaleDetails;

const aboutAethir = 'Aethir is an enterprise-grade GPU-as-a-service-provider that caters specifically to AI/ML clients. Its a revolutionary cloud computing infrastructure platform that aims to transform the ownership, distribution, and utilization paradigms of enterprise-grade GPUs. It offers a decentralized cloud infrastructure (DCI) specifically designed to address the demanding processing requirements of industries like gaming, AI model training, and inference. Aethir optimizes GPU utilization through resource pooling and decentralized ownership, democratizing access to advanced computational power and fostering a globally diverse and interconnected digital ecosystem.'

const aethirSaleContent = () => {
  return (
    <>
      <br/>
      <h4 className={styles.contentTitle}>Highlights</h4>
      <div className={styles.listTitle}>Validated demand: live multi-year contracts representing 20M+ ARR. Clients
        include:
      </div>
      <ul className={styles.list}>
        <li className={styles.listItem}>World’s largest telecommunications company</li>
        <li className={styles.listItem}>World’s largest game development studio + 10 AAA Games</li>
        <li className={styles.listItem}>WellLink, largest cloud gaming company with over 64M MAU.</li>
        <li className={styles.listItem}>Aethir’s Q1 projected revenue matches all other DePin projects combined.
        </li>
      </ul>
      <div className={styles.listTitle}>Validated Supply</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>20x more GPU’s than RNDR Network</li>
        <li className={styles.listItem}>45x more TFLOPS (compute power) than Akash Network</li>
        <li className={styles.listItem}>31x more infrastructure capital committed than Akash Network and RNDR
          Network combined
        </li>
        <li className={styles.listItem}>4000+ H100 AI GPUs secured and available via Aethir platform</li>
        <li className={styles.listItem}>Confirmed securing >50,000 more H100s over the next 6 months</li>
      </ul>
      <br/>
      <div className={styles.listTitle}>Social Traction</div>
      <ul className={styles.list}>
        <li className={styles.listItem}>170k+ community members</li>
        <li className={styles.listItem}>Gaming clients will bring access to over 150M MAU.</li>
      </ul>
      <br/>
      <div className={styles.listTitle}>Top Tier Investors</div>
      <img className={styles.imageContent} src={aethirInvestors} alt={'Aethir Investors'}/>
      <br/>
      <br/>
      <div className={styles.listTitle}>Top Tier Listings</div>
      <br/>
      <h4 className={styles.contentTitle}>OPPORTUNITY</h4>
      <ul className={styles.list}>
        <li className={styles.listItem}>15% of the Total Supply distributed to the node operators, vesting is linear
          over 4 years.
        </li>
        <li className={styles.listItem}>An opportunity for passive rewards in $ATH</li>
        <li className={styles.listItem}>Opportunity to get access to Tier 4 and Tier 5 nodes prior to significantly
          higher tiers being available on the public market.
        </li>
        <li className={styles.listItem}>Top Web3 VCs like Animoca Brands, Big Brain Holdings and Hashkey Group are
          invested.
        </li>
        <li>Validate computing work performed by Containers, accumulating $ATH token as reward. There will be 15% of
          $ATH Total Token Supply being distributed to Checkers.
        </li>
      </ul>
      <div className={styles.listTitle}>Disclaimer: The nodes are non-transferable for a period of 1 year. Should
        you decide to sell or transfer.
      </div>
      <br/>
      <h4 className={styles.contentTitle}>WHY RUN A AETHIR NODE?</h4>
      <div className={styles.contentText}>
        The Aethir Network is a unique digital system that combines technology and economy. It’s like a bustling city
        where different players (miners, developers, users, token holders, and the Aethir DAO) come together to make
        everything work smoothly. Unlike usual blockchain networks, Aethir Network uses a special way to operate.
        Here, the nodes do two main jobs:
      </div>
      <ul className={styles.list}>
        <li className={styles.list}>Proof of Rendering Capacity: Every 15 minutes, a group of these nodes is randomly
          chosen to validate
          transactions (like checking if everything’s in order). The more they have invested (in terms of tokens), the
          better their service quality, and the less often they’ve been chosen before, the more likely they are to be
          picked.
        </li>
        <li className={styles.list}>Proof of Rendering Work: While working, their performance is closely monitored to
          ensure quality. The
          network makes sure that users get the best possible service, adjusting resources based on demand and
          location.
        </li>
      </ul>
      <br/>
      <h4 className={styles.contentTitle}>THE BENEFITS OF RUNNING A NODE</h4>
      <div className={styles.contentText}>
        Running a node on Aethir contributes to the decentralisation of cloud compute and earns rewards from being a
        network participant. The first type of node in the Aethir network that will be made available is the checker
        node, a node for any user to operate to help in the verification of the uptime, latency, quality of service,
        and computational power provided by other node participants in the future.
      </div>
      <br/>
      <h4 className={styles.contentTitle}>THE AETHIR TOKEN</h4>
      <div className={styles.contentText}>
        The Aethir Token, $ATH, is central to Aethir’s ecosystem, acting as the primary currency for transactions
        within
        Aethir. This includes: facilitating payments for AI applications, cloud gaming and virtualized compute.
      </div>
      <div className={styles.contentText}>
        $ATH will also be a core driver of governance, staking, security and quality assurance.
      </div>
      <div className={styles.listTitle}>
        Note that this sale is not for the $ATH token, but to purchase a (NFT) license to run a Checker Node. The NFT
        (Checker Node) is non transferable for the first year.
      </div>
      <br/>
      <h4 className={styles.contentTitle}>HOW WILL NODE REWARDS BE DISTRIBUTED?</h4>
      <div className={styles.contentText}>
        Nodes will accrue rewards over a period of 4 years.
      </div>
      <div className={styles.listTitle}>
        What rewards will I receive?
      </div>
      <div className={styles.contentText}>
        Node rewards are received in veATH tokens.
      </div>
      <ul className={styles.list}>
        <li className={styles.list}> 10% of the token supply rewards will be distributed linearly.</li>
        <li className={styles.list}>
          5% of the token supply rewards distributed quarterly and performance-based (bad performing nodes will
          receive
          less than nodes that keep online consistency).
        </li>
      </ul>
      <br/>
      <div className='idoSocialGrid'>
        <div className="idoSocialBox">
          <TelegramIcon className='idoSocialIcon' onClick={() => openURL('https://t.me/aethirofficial')}/>
        </div>
        <div className="idoSocialBox">
          <TwitterIcon className='idoSocialIcon' onClick={() => openURL('https://twitter.com/AethirCloud')}/>
        </div>
        <div className="idoSocialBox">
          <WebsiteIcon className='idoSocialIcon' onClick={() => openURL('https://www.aethir.com/')}/>
        </div>
        <div className="idoSocialBox">
          <DiscordIcon className='idoSocialIcon' onClick={() => openURL('https://discord.com/invite/aethircloud')}/>
        </div>
      </div>
      <br/>
      <h4 className={styles.contentTitle}>ROADMAP</h4>
      <img className={styles.imageContent} src={aethirRoadmap} alt={'Roadmap'}/>
    </>
  )
}
