import { createSlice } from '@reduxjs/toolkit'

export const idoData = createSlice({
    name: 'idoData',
    initialState: {
        refundIDOs: [],
        upcomingIDOs: [],
        ongoingIDOs: [],
        completedIDOs: [],
        allIDOs: [],
    },
    reducers: {
        setIdoData: (state, data) => {
            const idoPayload = data.payload;
            state.refundIDOs = idoPayload.refundIDOs;
            state.upcomingIDOs = idoPayload.upcomingIDOs;
            state.ongoingIDOs = idoPayload.ongoingIDOs;
            state.completedIDOs = idoPayload.completedIDOs;
            state.allIDOs = idoPayload.completedIDOs.concat(idoPayload.ongoingIDOs.concat(idoPayload.upcomingIDOs.concat(idoPayload.refundIDOs.concat())))
        },
    },
})

export const { setIdoData } = idoData.actions

export default idoData.reducer
