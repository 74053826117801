import React, {useCallback, useMemo} from "react";
import styles from "./TokenClaimTab.module.scss";
import BigNumber from "bignumber.js";
import Button from "../../Components/Button/Button";
import * as helpers from "../../Helpers/helper";
import {BN, web3} from "@project-serum/anchor";
import {ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../Helpers/toastCSS";
import {Buffer} from "buffer";
import {useConnection} from "@solana/wallet-adapter-react";
import {useParams} from "react-router-dom";
import {SystemProgram} from "@solana/web3.js";
import {getErrorMessage} from "../../utils/utils";
import {IDO_STAGE_REFUNDS_OPEN} from "../../Helpers/idoHelper";
import {hasValue} from "../../Helpers/helperFunctions";

const TokenClaimTab = (props) => {
  const {
    idoInfo,
    poolData,
    allocationInfo,
    participantInfo,
    vestedAmount,
    provider,
    currentStage,
  } = props;
  
  const { connection } = useConnection();

  const idoProgramID = helpers.getIdoProgramId()
  const poolname = hasValue(idoInfo?.poolname) ? idoInfo?.poolname : idoInfo?.devPoolname;

  const claimTokens = async () => {
    try {
      const accts = await helpers.getAddys(provider, poolname);
      const idoProgram = helpers.getIdoProgram(provider);
      const userToken = await helpers.findATA(provider.wallet.publicKey, poolData.poolInfo.poolInfotoken);
      const userTokenInit = new BN(await connection.getBalance(userToken));
      if (userTokenInit.isZero()) {
        let token = new Token(connection, poolData.poolInfo.poolInfotoken, TOKEN_PROGRAM_ID, provider.wallet.publicKey);
        //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
        let tx = new web3.Transaction();
        tx.add(
          Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, poolData.poolInfo.poolInfotoken,
            userToken, provider.wallet.publicKey, provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx, connection);
        await connection.confirmTransaction(sign, 'processed');
        toast(idoInfo?.symbol + " Account created", successToast);
      }
      const [tokenVault, tokenBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolname), Buffer.from("token_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.claimTokens(
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              participant: accts.ppt,
              pool: accts.pool,
              userToken: userToken,
              tokenVault: tokenVault,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully claimed", successToast);
      window.location.reload();
    } catch (e) {
      console.warn("Failed", e);
      toast(e.message, errorToast);
    }
  }

  const claimVestedTokens = async () => {
    const accts = await helpers.getAddys(provider, poolname);
    const idoProgram = helpers.getIdoProgram(provider);

    try {
      const userToken = await helpers.findATA(provider.wallet.publicKey, poolData.token);
      const userTokenInit = new BN(await connection.getBalance(userToken));
      if (userTokenInit.isZero()) {
        let token = new Token(connection, poolData.token, TOKEN_PROGRAM_ID, provider.wallet.publicKey);
        //    await token.createAssociatedTokenAccount(provider.wallet.publicKey);
        let tx = new web3.Transaction();
        tx.add(
          Token.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, poolData.token,
            userToken, provider.wallet.publicKey, provider.wallet.publicKey)
        );
        const sign = await provider.wallet.sendTransaction(tx, connection);
        await connection.confirmTransaction(sign, 'processed');
        toast(idoInfo?.symbol + " Account created", successToast);
      }
      const [tokenVault, tokenBump] = await web3.PublicKey.findProgramAddress(
        [Buffer.from(poolname), Buffer.from("token_vault")],
        idoProgramID
      );
      const transaction = new web3.Transaction();
      transaction.add(
        idoProgram.instruction.claimVestedTokens(
          {
            accounts: {
              userAuthority: provider.wallet.publicKey,
              participant: accts.ppt,
              pool: accts.pool,
              userToken: userToken,
              tokenVault: tokenVault,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          })
      );
      const signature = await provider.wallet.sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, 'processed');
      toast("Successfully claimed", {
        duration: 3000,
        position: 'bottom-left',
        style: {
          background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
          color: "#FFF"
        },
        className: 'success-toast',
        icon: '✓',
      });
      window.location.reload();
    } catch (e) {
      const errorMessage = getErrorMessage(e, idoProgram.idl)
      toast(errorMessage, errorToast);
    }
  }

  const showStandardClaim = allocationInfo?.allocation && participantInfo && participantInfo.bought.gt(0) && !participantInfo?.claimedUnlocked;
  const showVestedClaim = currentStage !== IDO_STAGE_REFUNDS_OPEN && allocationInfo.allocation && participantInfo && participantInfo?.bought.gt(0) && (new BigNumber(vestedAmount)).gt(0);

  const tokenQuantityToClaim =
    participantInfo?.initialUnlocked ?
      new BigNumber(participantInfo?.initialUnlocked).div(new BigNumber(1000000)).toFixed(3, 1) : 0;
  const vestedTokenQuantityToClaim = new BigNumber(vestedAmount).div(new BigNumber(1000000)).toFixed(3, 1);

  return (
    <div className={styles.tokenClaimTabContainer}>
      {allocationInfo.allocation && participantInfo && participantInfo.bought.gt(0) && !participantInfo.claimedUnlocked && <div className={styles.claimContainer}>
        <div className={styles.claimLabelRow}>
          <span>YOU HAVE</span>
          <span className={styles.claimValue}>{tokenQuantityToClaim + ' ' + idoInfo?.symbol}</span>
          <span>TO CLAIM</span>
        </div>
        <Button text="CLAIM TOKENS" onClick={claimTokens} />
      </div>}
      {currentStage !== "REFUNDS_OPEN" && allocationInfo.allocation && participantInfo && participantInfo.bought.gt(0) && (new BigNumber(vestedAmount)).gt(0) &&
        <div className={styles.claimContainer}>
          <div className={styles.claimLabelRow}>
            <span>YOU HAVE</span>
            <span className={styles.claimValue}>{new BigNumber(vestedAmount).div(new BigNumber(1000000)).toFixed(3, 1)} {idoInfo?.symbol}</span>
            <span>TO CLAIM</span>
          </div>
          <Button text="CLAIM TOKENS" onClick={claimVestedTokens}/>
        </div>}
    </div>
  )
}

export default TokenClaimTab;
