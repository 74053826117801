import React, {useCallback, useMemo} from "react";
import styles from "./TokenInformation.module.scss";
import Button, {BUTTON_TYPE_SECONDARY, ButtonClickAudio} from "../../Components/Button/Button";
import {
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_POLYGON,
  BLOCKCHAIN_SOLANA,
  calculateBlockchain,
  hasValue
} from "../../Helpers/helperFunctions";
import {useSelector} from "react-redux";
import BigNumber from "bignumber.js";
import {
  getIdoNodeTime,
  getIdoTime, getIdoTimesFromContract,
  IDO_STAGE_FCFS_SALE,
  IDO_STAGE_HYDRAZINE_CONTRIBUTION,
  IDO_STAGE_USDC_CONTRIBUTION
} from "../../Helpers/idoHelper";
import moment from "moment";
import {NODE_OG_SALE_STAGE} from "./IdoDetails";

const TokenInformation = (props) => {
  const {
    idoInfo,
    poolData
  } = props;

  const isMuted = useSelector((state) => state.sound.mute);

  const blockchain = calculateBlockchain(idoInfo);

  const openExplorer = () => {
    if (!isMuted) {
      ButtonClickAudio.play();
    }

    if(blockchain === BLOCKCHAIN_SOLANA) {
      if (poolData.token.toBase58() === "6bHmA5Jof95m3V4oekGaaxjqkmkneuBDuD1EZDXpGKnh" || poolData.token.toBase58() === "3sUJhpo7a9a2V5tFeC91JH5QNps63Lxi1eWabu8FTkK4") {
        window.open("https://solscan.io/token/" + idoInfo?.token);
      } else if (poolData.token.toBase58()) {
        window.open("https://solscan.io/token/" + poolData.token.toBase58());
      }
    } else if (blockchain === BLOCKCHAIN_POLYGON) {
      window.open("https://polygonscan.com/token/" + idoInfo?.token);
    } else if (blockchain === BLOCKCHAIN_ETH) {
      window.open("https://etherscan.io/token/" + idoInfo?.token);
    }
  }

  const vestingStartDate = useCallback(() => {
    if (!poolData?.poolInfo) {
      return '';
    }

    if (moment.unix(poolData?.poolInfo.idoTimes.vestingStart.toString()).isSameOrAfter(moment('2080'))) {
      return 'TBA';
    }

    return moment.unix(poolData?.poolInfo.idoTimes.vestingStart.toString()).utc().format("MMMM DD YYYY, h:mm:ss A") + ' UTC';
  }, [poolData])

  if (idoInfo.isNodeSale) {
    const nodeSaleStart = moment.unix(getIdoNodeTime(idoInfo, NODE_OG_SALE_STAGE).start).utc();

    return (
      <div className={styles.tokenInformationContainer}>
        <div className={styles.blockchainExplorerRow}>
          <div className={styles.contentSubTitle}>Click here to view the blockchain explorer
          </div>
          <Button buttonType={BUTTON_TYPE_SECONDARY} text="VIEW EXPLORER" onClick={openExplorer}/>
        </div>
        <div className={styles.tokenInformationValuesBox}>
          <div className={styles.tokenInformationLabelColumn}>
            {hasValue(idoInfo?.name) && <span>NAME</span>}
            {hasValue(idoInfo?.symbol) && <span>SYMBOL</span>}
            <span>BLOCKCHAIN</span>
            <span>NODE SALE DATE</span>
            <span>DISTRIBUTION</span>
            <span>NODE PRICE</span>
            <span>HYDRAZINE PRICE</span>
            {hasValue(poolData?.poolInfo?.individualLimit.toString()) &&  <span>MAX NODES PER WALLET</span>}
            {hasValue(poolData?.poolInfo?.nodesForSale) && <span>NODES FOR SALE</span>}
          </div>
          <div className={styles.tokenInformationValuesColumnDivider}/>
          <div className={styles.tokenInformationValuesColumn}>
            {hasValue(idoInfo?.name) && <span>{idoInfo?.name}</span>}
            {hasValue(idoInfo?.symbol) && <span>{idoInfo?.symbol}</span>}
            <span>{blockchain}</span>
            <span>{nodeSaleStart.format("MMMM Do YYYY, hh:mm UTC")}</span>
            <span>{idoInfo.distribution}</span>
            <span>{idoInfo.nodePrice}</span>
            <span>{idoInfo.hydrazinePrice}</span>
            {hasValue(poolData?.poolInfo?.individualLimit.toString()) && <span>{poolData.poolInfo.individualLimit.toString() + ' NODE(S)'}</span>}
            {hasValue(poolData?.poolInfo?.nodesForSale) && <span>{poolData?.poolInfo?.nodesForSale.toString()}</span>}
          </div>
        </div>
      </div>
    )
  }

  const hydrazineStartUnix = moment.unix(getIdoTimesFromContract(poolData?.poolInfo.idoTimes, IDO_STAGE_HYDRAZINE_CONTRIBUTION, idoInfo).start).utc();
  const hydrazineEndUnix = moment.unix(getIdoTimesFromContract(poolData?.poolInfo.idoTimes, IDO_STAGE_HYDRAZINE_CONTRIBUTION, idoInfo).end).utc();
  const usdcStartUnix = moment.unix(getIdoTimesFromContract(poolData?.poolInfo.idoTimes, IDO_STAGE_USDC_CONTRIBUTION, idoInfo).start).utc();
  const usdcEndUnix = moment.unix(getIdoTimesFromContract(poolData?.poolInfo.idoTimes, IDO_STAGE_USDC_CONTRIBUTION, idoInfo).end).utc();
  const fcfsStartUnix = moment.unix(getIdoTimesFromContract(poolData?.poolInfo.idoTimes, IDO_STAGE_FCFS_SALE, idoInfo).start).utc();

  const initialUnlock = () => {
    if (hasValue(idoInfo.initialUnlock)) {
      return idoInfo.initialUnlock;
    }

    return  poolData?.poolInfo ? poolData.poolInfo.unlocked.toString() + "%" : "";
  }
  const isVested = poolData?.poolInfo ? poolData?.poolInfo?.vested : false;
  const tranceDuration = isVested ? new BigNumber(moment.duration(poolData?.poolInfo.trancheLength.toNumber()).asDays()).times(new BigNumber(1000)).toFixed(0) + " days" : "";
  return (
    <div className={styles.tokenInformationContainer}>
      <div className={styles.blockchainExplorerRow}>
        <div className={styles.contentSubTitle}>Click here to view the blockchain explorer
        </div>
        <Button buttonType={BUTTON_TYPE_SECONDARY} text="VIEW EXPLORER" onClick={openExplorer}/>
      </div>
      <div className={styles.tokenInformationValuesBox}>
        <div className={styles.tokenInformationLabelColumn}>
          <span>NAME</span>
          <span>SYMBOL</span>
          <span>BLOCKCHAIN</span>
          {idoInfo?.supply && <span>SUPPLY</span>}
          {idoInfo?.launchCap && <span>LAUNCH MCAP</span>}
          <span>HYDRAZINE CONTRIBUTION START</span>
          <span>HYDRAZINE CONTRIBUTION CLOSED</span>
          <span>USDC CONTRIBUTION START</span>
          <span>USDC CONTRIBUTION CLOSED</span>
          <span>FCFS SALE STAGE</span>
          <span>INITAL UNLOCK</span>
          <span>POOL SIZE</span>
          {isVested && <span># OF TRANCHES</span>}
          {isVested && <span>VESTING START DATE</span>}
        </div>
        <div className={styles.tokenInformationValuesColumnDivider}/>
        <div className={styles.tokenInformationValuesColumn}>
          <span>{idoInfo?.name}</span>
          <span>{idoInfo?.symbol}</span>
          <span>{blockchain}</span>
          <span>{new BigNumber(idoInfo?.supply).toFormat(0)}</span>
          {idoInfo?.launchCap && <span>{idoInfo?.launchCap.toLocaleString()}</span>}
          <span>{hydrazineStartUnix.format("MMMM Do YYYY, hh:mm A UTC")}</span>
          <span>{hydrazineEndUnix.format("MMMM Do YYYY, hh:mm A UTC")}</span>
          <span>{usdcStartUnix.format("MMMM Do YYYY, hh:mm A UTC")}</span>
          <span>{usdcEndUnix.format("MMMM Do YYYY, hh:mm A UTC")}</span>
          <span>{fcfsStartUnix.format("MMMM Do YYYY, hh:mm A UTC")}</span>
          <span>{initialUnlock()}</span>
          <span>{poolData?.poolInfo ? new BigNumber(poolData.poolInfo.tokensForSale.toString()).div(new BigNumber(1000000)).toFormat(0) + " " + idoInfo?.symbol : ""}</span>
          {isVested && <span>{hasValue(idoInfo.numberOfTranches) ? idoInfo.numberOfTranches :  poolData.poolInfo.tranches.toString()}</span>}
          {isVested && <span>{vestingStartDate()}</span>}
        </div>
      </div>
    </div>
  )
}

export default TokenInformation;
