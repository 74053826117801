import { createSlice } from '@reduxjs/toolkit'

export const sound = createSlice({
  name: 'sound',
  initialState: {
    mute: false,
    videoOff: false,
  },
  reducers: {
    toggleMute: (state) => {
      state.mute = !state.mute
    },
    umute: (state) => {
      state.mute = false
    },
    mute: (state) => {
      state.mute = true
    },
    toggleVideo: (state) => {
      state.videoOff = !state.videoOff
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleMute, umute, mute, toggleVideo } = sound.actions

export default sound.reducer