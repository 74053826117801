import { useDispatch } from 'react-redux';
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY } from '../../Components/Button/Button';
import { toggleShowTutorial, toggleShowWelcomeModal } from '../../Components/Leftnav/modal';
import { UPSContent } from './HomeInformation';
import React from 'react';
import './HomeWelcomeModal.scss'

function HomeWelcomeModal(props) {
    const {
        setShowWelcomeModal,
    } = props;

    const dispatch = useDispatch();

    const startTutorial = () => {
        setShowWelcomeModal(false);
        localStorage.setItem('showWelcomeModal', false);
        dispatch(toggleShowTutorial());
    }

    const closeWelcomeModal = () => {
        setShowWelcomeModal(false);
        localStorage.setItem('showWelcomeModal', false);
    }

    return (
        <div className='welcomeModalOverlay'>
            <div className='welcomeModalTitle'>
                <span>WELCOME TO STARLAUNCH</span>
            </div>
            <div className='welcomeModalContainer'>
                <UPSContent />
            </div>
            <div className='welcomeModalButtonRow'>
                <Button buttonType={BUTTON_TYPE_SECONDARY} text="Skip" onClick={closeWelcomeModal} />
                <Button buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} text="START TUTORIAL" onClick={startTutorial} />
            </div>
        </div>
    );
}

export default HomeWelcomeModal;
