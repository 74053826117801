import React from 'react';
import CaretForward from '../../images/svg/caretForward.svg'
import CaretBackward from '../../images/svg/caretBackwards.svg'
import CloseIcon from '../../images/svg/closeIcon.svg'
import { motion } from "framer-motion"
import './TutorialPopup.scss';
import Button, { ButtonClickAudio, BUTTON_TYPE_PRIMARY_BRIGHT, ProgressButtonClickAudio } from '../Button/Button';
import useMobileDetect from 'use-mobile-detect-hook';
import { useSelector } from 'react-redux';

function TutorialPopup(props) {
    const {
        maxPages = 1,
        currentPage = 1,
        title,
        text,
        nextClick,
        closeTutorial,
        backClick,
        containerStyle,
        children
    } = props;

    const detectMobile = useMobileDetect();

    const isMuted = useSelector((state) => state.sound.mute);

    const buttonName = maxPages === currentPage ? 'CLOSE' : 'NEXT';
    const isMobile = detectMobile.isMobile();

    const _nextClick = () => {
        if (!isMuted) {
            ProgressButtonClickAudio.play();
        }
        nextClick();
    }

    const _backClick = () => {
        if (!isMuted) {
            ProgressButtonClickAudio.play();
        }
        backClick();
    }

    const _closeTutorial = () => {
        if (!isMuted) {
            ButtonClickAudio.play();
        }
        closeTutorial();
    }

    const animation = isMobile ? {} : { opacity: 1 };

    return (
        <motion.div 
        animate={animation}
        transition={{ delay: 0 }} 
        className='tutorialContainer' style={isMobile ? '' : containerStyle}>
           <div className='tutorialHeader'>
               <div className='tutorialHeaderPagesMobile'>{currentPage + '/' + maxPages}</div>
               {title}
               <div className='tutorialHeaderCloseButtonMobile' onClick={_closeTutorial}>
                   <CloseIcon />
                </div>
            </div> 
           <div className='tutorialBody'>
                <span>{text}</span>
                <div className='tutorialFooter'>
                    {currentPage !== 1 && <div className='tutorialButton' onClick={_backClick}>
                        <CaretBackward className='previousIcon' />
                        <span>BACK</span>
                    </div> }
                    <span>{currentPage + '/' + maxPages}</span>
                    <div className='tutorialButton' onClick={_nextClick}>
                        <span>{buttonName}</span>
                        <CaretForward className='nextIcon' />
                    </div>
                </div>
           </div>
            {isMobile && <div className='tutorialBottom'>
                {children}
                <div className='flex-divider' />
                <div className='tutorialFooterMobile' style={detectMobile.isIos() ? {marginBottom: 58} : {}}>
                    {currentPage === 1 && <div id="Filler" />}
                    {currentPage !== 1 && <Button text="PREVIOUS" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={_backClick} LeftIcon={CaretBackward}  />}
                    <Button text={buttonName} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={_nextClick} Icon={CaretForward} />
                </div>
            </div>}
        </motion.div>
    )

}

export default TutorialPopup;
