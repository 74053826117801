import React, { useRef, useState } from 'react';
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT } from '../../Components/Button/Button';
import PageSelector from '../../Components/PageSelector/PageSelector';
import TutorialPopup from '../../Components/TutorialPopup/TutorialPopup';
import useMobileDetect from 'use-mobile-detect-hook';
import { IDO_TYPE_COMPLETED, IDO_TYPE_ONGOING, IDO_TYPE_UPCOMING } from './IdoPage';
import HyperLinkIcon from '../../images/svg/hyperlinkIcon.svg'
import './IdoPageTutorial.scss';
import BuyAllocationForm from '../IdoDetails/BuyAllocationForm';

const options = [
    {
      key: IDO_TYPE_ONGOING,
      label: 'ONGOING IDOs',
      sideValue: 2,
    },
    {
      key: IDO_TYPE_UPCOMING,
      label: 'UPCOMING IDOs',
      sideValue: 0,
    },
    {
      key: IDO_TYPE_COMPLETED,
      label: 'COMPLETED IDOs',
      sideValue: 0,
    }
  ];

function IdoPageTutorial(props) {
    const {
        closeTutorial,
    } = props;
    
    const detectMobile = useMobileDetect();

    const hydrazineInputRef = useRef();

    const [page, setPage] = useState(1);
    const [idoType, setIdoType] = useState(IDO_TYPE_ONGOING);

    const isMobile = detectMobile.isMobile();

    const openURL = (url) => {
        window.open(url);
    }

    return (
        <div className='idoTutorialOverlay'>
            {page === 1 && <TutorialPopup
                title="Initial DEX Offering" 
                text="Welcome to the IDO Sale Page Commander! The IDO menu allows you to Burn Hydrazine in order to guarantee an allocation for an IDO"
                containerStyle={{position: 'relative'}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)} />}
            {page === 2 && <>
                {!isMobile && <PageSelector options={options} value={idoType} setValue={setIdoType} /> }
                <TutorialPopup
                title="IDO" 
                text="Information on Upcoming, ongoing, and past IDOs can be found on this page. The amount on each page is indicated on the selector."
                containerStyle={{position: 'relative', marginLeft: 20}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                    <div className='IDOTutorialChildren'>
                        <PageSelector options={options} value={idoType} setValue={setIdoType} />
                    </div>
                </TutorialPopup>
            </>}
            {page === 3 && <>
                {!isMobile && <Button 
                    buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} 
                    text="COMPLETE KYC"
                    Icon={HyperLinkIcon}
                    onClick={() => openURL('https://fractal.id/authorize?client_id=b5kR1YtTwLjdoWNkat9gxgj8HEKZIIj8pDBgnpCIVY0&redirect_uri=https%3A%2F%2Fapp.starlaunch.com%2F&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet-sol:read%20verification.wallet-sol.details:read%20verification.telegram:read%20verification.telegram.details:read')} /> }
                <TutorialPopup
                title="Know Your Customer" 
                text="In order to participate in an IDO, your Cadet ID linked Wallet must have a valid KYC through Fractal"
                containerStyle={{position: 'relative', marginLeft: 20}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                    <div className='IDOTutorialChildren'>
                    <Button 
                    buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} 
                    text="COMPLETE KYC"
                    Icon={HyperLinkIcon}
                    onClick={() => openURL('https://fractal.id/authorize?client_id=b5kR1YtTwLjdoWNkat9gxgj8HEKZIIj8pDBgnpCIVY0&redirect_uri=https%3A%2F%2Fapp.starlaunch.com%2F&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet-sol:read%20verification.wallet-sol.details:read%20verification.telegram:read%20verification.telegram.details:read')} />
                    </div>
                </TutorialPopup>
            </>}
            {page === 4 && <>
            {!isMobile && <BuyAllocationForm 
                inputRef={hydrazineInputRef}
                isBurn
                isTutorial />}
            
            <TutorialPopup
                title="Burn Hydrazine" 
                text="The first step when choosing to participate in an IDO is to Burn Hydrazine during the 48 hour Burn window."
                containerStyle={{position: 'relative', marginLeft: 20}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)}>
                    <BuyAllocationForm 
                        inputRef={hydrazineInputRef}
                        isBurn
                        isTutorial />
                </TutorialPopup>
                </>}
            {page === 5 && <TutorialPopup
                title="Allocation" 
                text="After the Burn window is over, you will be granted an Allocation to be purchased with USDC. This purchase window will be open for 24 hours."
                containerStyle={{position: 'relative'}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => setPage(page + 1)} />}
            {page === 6 && <TutorialPopup
                title="All set!" 
                text="After purchasing your allocation, you are all set! "
                containerStyle={{position: 'relative'}}
                maxPages={6}
                currentPage={page}
                closeTutorial={closeTutorial}
                backClick={() => setPage(page - 1)}
                nextClick={() => closeTutorial()} />}
        </div>
    )
}

export default IdoPageTutorial;
