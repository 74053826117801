import { createSlice } from '@reduxjs/toolkit'

export const modal = createSlice({
  name: 'modal',
  initialState: {
    showTutorial: false,
    informationState: {
      show: false,
      startPage: null,
    },
    showMobileMenu: false,
    showWelcomeModal: true,
    showWelcomeFusion: false,
    showApplyForm: false,
  },
  reducers: {
    toggleShowTutorial: (state) => {
      state.showTutorial = !state.showTutorial
    },
    toggleShowInformation: (state) => {
      state.informationState.show = !state.showInformation
    },
    setInformationModalState: (state, data) => {
      state.informationState = data.payload
    },
    toggleShowMobileMenu: (state) => {
      state.showMobileMenu = !state.showMobileMenu
    },
    toggleShowWelcomeModal: (state) => {
      state.showWelcomeModal = !state.showWelcomeModal
    },
    toggleShowWelcomeFusion: (state) => {
      state.showWelcomeFusion = !state.showWelcomeFusion
    },
    toggleShowApplyForm: (state) => {
      state.showApplyForm = !state.showApplyForm
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  toggleShowTutorial, 
  toggleShowInformation, 
  setInformationModalState, 
  toggleShowMobileMenu, 
  toggleShowWelcomeModal, 
  toggleShowWelcomeFusion, 
  toggleShowApplyForm } = modal.actions

export default modal.reducer