// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  background: rgba(196, 196, 196, 0.5) !important;
  border-radius: 2px;
  height: 6px !important; }

.progress.single {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
  margin-bottom: 1.5em; }

.progress-bar {
  background-color: #48CAE4 !important;
  border-radius: 2px;
  box-shadow: 0px 2px 10px rgba(72, 202, 228, 0.75) !important; }

.progress-bar.single {
  background-color: #ff6b00 !important; }
`, "",{"version":3,"sources":["webpack://./src/Components/ProgressBar/ProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,sBAAsB,EAAA;;AAGxB;EACE,qDAAqD;EACrD,gDAAgD;EAChD,oBAAoB,EAAA;;AAGtB;EACE,oCAAoC;EACpC,kBAAkB;EAClB,4DAA4D,EAAA;;AAG9D;EACE,oCAAoC,EAAA","sourcesContent":[".progress {\r\n  background: rgba(196, 196, 196, 0.5) !important;\r\n  border-radius: 2px;\r\n  height: 6px !important;\r\n}\r\n\r\n.progress.single {\r\n  background-color: rgba(255, 255, 255, 0.8) !important;\r\n  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);\r\n  margin-bottom: 1.5em;\r\n}\r\n\r\n.progress-bar {\r\n  background-color: #48CAE4 !important;\r\n  border-radius: 2px;\r\n  box-shadow: 0px 2px 10px rgba(72, 202, 228, 0.75) !important;\r\n}\r\n\r\n.progress-bar.single {\r\n  background-color: #ff6b00 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
