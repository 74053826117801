import React, {useState, useEffect } from 'react';
import _ from 'lodash';
import "./KycAuthentication.scss";
import IDOPageVideo from '../../images/idoPageBackground.webm';
import IDOPageVideoMobile from '../../images/idoPageBackgroundMobile.webm';
import IDOPageVideoMobileMp4 from '../../images/idoPageBackgroundMobile.mp4';
import useMobileDetect from 'use-mobile-detect-hook';
import { useWallet } from '@solana/wallet-adapter-react';
import * as helpers from "../../Helpers/helper";
import LoadingSpinner, {LOADING_SPINNER_MEDIUM} from '../../Components/LoadingSpinner/LoadingSpinner';

const displayKycMessage = (data) => {
  switch (data.status) {
    case 0 : return <LoadingSpinner size={LOADING_SPINNER_MEDIUM} local={true} />
    case 1 : 
      return <div className='kycMessage'>
      {data.message} 
      <LoadingSpinner size={LOADING_SPINNER_MEDIUM} local={true} />
  </div>
    case 2 :  
      return <div className='kycMessage'>
        {data.message}
        <LoadingSpinner size={LOADING_SPINNER_MEDIUM} local={true} />
    </div>
    case 3 :    return <div className='kycMessage'>
        {data.message}
    </div>
  }
}


function KycAuthentication() {

  const detectMobile = useMobileDetect();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const API_URL = helpers.getApiUrl();
  const wallet = useWallet();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  
  useEffect(() => {
    // Call backend URL using code
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(API_URL + `/kyc/check?code=${code}&wallet=${wallet?.publicKey?.toString()}`);
        const resJson = await response.json();
        
        // Process the data from the backend
        if(resJson.status === "success" && resJson.data.kyc) { // All success
          setData({
            message:  resJson.data.message + "Redirecting to home page ...",
            status: resJson.data.status,
          })

          // Redirection success
          setTimeout(() => { window.location.href = '/' }, 5000);

        } else if (resJson.status === "success" && resJson.data.tokenExists) { // Pending verification
          setData({
            message: resJson.data.message + " Please check after sometime.",
            status: resJson.data.status,
          })
        } else {
          setData({
            message: "Something went wrong!",
            status: 4
          })
        }
      } catch (error) {
        setData({ message: error, status: false });
      } finally {
        // setIsLoading(false);
      }
    };

    if(wallet?.publicKey) fetchData();
  }, [!_.isEmpty(wallet?.publicKey) && code]);

  return (
    <main
    className='idoPageMain'>
      <div className='idoOverlay' />
      <div className='idoPageContainer'>
        <div className='kycContainer'>
          { isLoading ?
            <div>
              <div className='kycMessage'> Verifying Kyc status ... </div>
              <LoadingSpinner size={LOADING_SPINNER_MEDIUM} local={true} />
            </div>
            : 
            data && displayKycMessage(data)
          }
        </div> 
      </div>
      {!detectMobile.isMobile() && 
      <video 
        className='backgroundVideo' 
        autoPlay 
        loop 
        muted
        playsInline
        onLoadedData={() => setIsLoading(false)}
        preload="auto" >
          <source src={IDOPageVideo} type='video/webm' />
      </video>}
      {detectMobile.isMobile() && !detectMobile.isIos() &&
      <video 
        className='backgroundVideo' 
        autoPlay 
        loop 
        muted
        playsInline 
        onLoadedData={() => setIsLoading(false)} >
          <source src={IDOPageVideoMobile} type='video/webm' />
          <source src={IDOPageVideoMobileMp4} type='video/mp4' />
      </video>}
      {detectMobile.isMobile() && detectMobile.isIos() &&
        <img className='backgroundVideo' src={IDOPageVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {isLoading && <LoadingSpinner />}
    </main>
  );
}

export default KycAuthentication;
