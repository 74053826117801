import React, {useCallback} from "react";
import styles from "./TextInput.module.scss";
import classNames from "classnames";
import {Tooltip} from "react-tooltip";

function TextInput(props) {
    const {
        id,
        name = "",
        tooltipContent,
        onBlur,
        value,
        setValue,
        label,
        placeholder,
        type="text",
        required = false,
        disabled = false,
        iconSrc,
        overrideContainerClassName,
        inputClassName,
    } = props;


    const handleChange = useCallback((event) => {
        if (setValue) {
            setValue(event.target.value);
        }
    }, []);

    const _onBlur = () => {
        if (onBlur) {
            onBlur();
        }
    }

    const renderLabel = () => {
        if (iconSrc && label) {
            return (
                <>
                    <img className={styles.inputPicture} src={iconSrc} data-tooltip-id={id}/>
                    <label htmlFor={id} className={styles.labelWithPicture} data-tooltip-id={id}>
                        {label}
                    </label>
                </>
            )
        }

        if (label) {
            return (
                <label htmlFor={id} className={styles.label} data-tooltip-id={id}>
                    {label}
                </label>
            )
        }

        return;
    }

    return (
        <div className={classNames(styles.inputContainer, overrideContainerClassName)}>
        <input id={id}
                className={classNames(styles.input, inputClassName)}
                value={value}
                name={name}
                required={required}
                autoComplete="off"
                onChange={handleChange}
                placeholder={placeholder}
                type={type}
                onBlur={_onBlur}
                disabled={disabled} />
            {renderLabel()}
            {tooltipContent && <Tooltip id={id}>
                {tooltipContent}
            </Tooltip>}
        </div>
    );
}

export default TextInput;
