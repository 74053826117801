import { configureStore } from '@reduxjs/toolkit'
import soundReducer from './Components/Leftnav/sound'
import modalReducer from './Components/Leftnav/modal'
import shipPartsReducer from './screens/Home/shipParts'
import statisticsReducer from './Components/Header/statistics'
import idoData from './Components/Header/idoData'

export default configureStore({
  reducer: {
      idoData: idoData,
      modal: modalReducer,
      sound: soundReducer,
      shipParts: shipPartsReducer,
      statistics: statisticsReducer,
  },
})