import { useState } from 'react';
import TextInput from '../../Components/TextInput/TextInput';
import TutorialPopup from '../../Components/TutorialPopup/TutorialPopup';
import  InformationIcon from "../../images/svg/information2.svg"
import styles from '../../Components/Vault/CreateVault.module.scss';
import './VaultTutorial.scss';
import RadioButtonGroup from '../../Components/RadioButton/RadioButtonGroup';
import { MODAL_TOOLTIP_ID } from '../../Components/Modal/Modal';
import VaultIcon from '../../images/svg/vaultCase.svg';
import { useEffect } from 'react';
import * as constants from '../../constants/constants';

function VaultTutorial(props) {
    const {
        closeTutorial,
    } = props;

    const [page, setPage] = useState(1);
    const [lock, setLock] = useState(null)

    useEffect(() => {
        if (page === 4) {
            setLock(null);
        }
        if (page === 5) {
            setLock('2');
        }
        if (page === 6) {
            setLock('1');
        }
    }, [page])


    return (
    <div className='vaultTutorialContainer'>
        {page === 1 && <TutorialPopup
            title="Fusion Vault" 
            text="Welcome to the Fusion Vault Commander! The Fusion Vault allows the staking of Stars in Fusion Boxes."
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            nextClick={() => setPage(page + 1)} />}
        {page === 2 && <TutorialPopup
            title="Fusion box" 
            text="Stars that are staked in Fusion Boxes produce Hydrazine"
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page === 3 && <TutorialPopup
            title="Hydrazine" 
            text="The purpose of Hydrazine is to be burned in order to guarantee an allocation in an IDO"
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page >= 4 && page <= 6 &&
           <div className='vaultBoxTutorial'>
                <h3 className={styles.modalHeader}>
                    create fusion box
                </h3>          
                <div className={styles.vaultCaseContainer}>
                            <VaultIcon className={styles.vaultCase} />                    
                        </div>                        
                        
                        <span className={styles.modalHelper}>
                            vault id
                            <InformationIcon className={styles.modalIcon} 
                                data-for={MODAL_TOOLTIP_ID}
                                data-tip={constants.VAULT_ID_TOOLTIP} />
                        </span>
                        <TextInput id="box" value={'BOX 0000'} disabled/>
                        <span className={styles.modalHelper}>
                            stars amount
                            <InformationIcon className={styles.modalIcon}
                                data-for={MODAL_TOOLTIP_ID} 
                                data-tip={constants.STARS_AMOUNT} />
                        </span>
                        <TextInput id="amount" placeholder="Enter amount" value={null} setValue={null} />

                        <RadioButtonGroup name="lock" 
                            value={lock}
                            setValue={setLock}
                            options={[
                                    {id: "1", label: "Stake without lock(decays stars 0.767% per day)", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "2", label: "Stake with 90 day lock", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "3", label: "Stake with 180 day lock", tooltip: constants.LOCKED_180BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "4", label: "Stake with 360 day lock", tooltip: constants.LOCKED_360BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                ]} />
           </div> }
           {page === 4 && <TutorialPopup
            title="Fusion Box" 
            text={
                <span>
                    You can select one of the two Staking options. 
                    <br />
                   <strong>Stake with 90 day lock </strong>
                    or 
                    <strong> Stake Unlocked</strong>
                </span>}
            containerStyle={{position: 'relative', marginLeft: '25px'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)}>
                <div className='vaultBoxTutorialContainerMobile'>
                    <RadioButtonGroup name="lock" 
                                value={lock}
                                setValue={setLock}
                                options={[
                                    {id: "1", label: "Stake without lock(decays stars 0.767% per day)", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "2", label: "Stake with 90 day lock", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "3", label: "Stake with 180 day lock", tooltip: constants.LOCKED_180BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "4", label: "Stake with 360 day lock", tooltip: constants.LOCKED_360BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                    ]} />
                </div>
            </TutorialPopup>
            } 
            {page === 5 && <TutorialPopup
            title="Locked Fusion Box" 
            text={<span>
                Creating a Locked Fusion Box will produce hydrazine while preserving your Stars, however your stars will be locked for 90 days
                <br />
                Unlocking a Locked Fusion Box early will incur a Penalty and decay a percentage of the Stars inside.
            </span>}
            containerStyle={{position: 'relative', marginLeft: '25px'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)}>
                <div className='vaultBoxTutorialContainerMobile'>
                    <RadioButtonGroup name="lock" 
                                value={lock}
                                setValue={setLock}
                                options={[
                                    {id: "1", label: "Stake without lock(decays stars 0.767% per day)", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "2", label: "Stake with 90 day lock", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "3", label: "Stake with 180 day lock", tooltip: constants.LOCKED_180BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                    {id: "4", label: "Stake with 360 day lock", tooltip: constants.LOCKED_360BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                    ]} />
                </div>
            </TutorialPopup>}
        {page === 6 && <TutorialPopup
            title="Unlocked Fusion Box" 
            text="Creating an Unlocked Fusion Box will create a box that can be opened at any time, However your stars will decay at a rate of .767% every day until removed."
            containerStyle={{position: 'relative', marginLeft: '25px'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)}>
                <div className='vaultBoxTutorialContainerMobile'>
                    <RadioButtonGroup name="lock" 
                                value={lock}
                                setValue={setLock}
                                options={[
                                    {id: "1", label: "Stake without lock(decays stars 0.767% per day)", tooltip: constants.UNLOCKED_BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "2", label: "Stake with 90 day lock", tooltip: constants.LOCKED_90BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "3", label: "Stake with 180 day lock", tooltip: constants.LOCKED_180BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID},
                                        {id: "4", label: "Stake with 360 day lock", tooltip: constants.LOCKED_360BOX_TOOLTIP, tooltipId: MODAL_TOOLTIP_ID}
                                    ]} />
                </div>
            </TutorialPopup>}

        
        {page === 7 && <TutorialPopup
            title="Unlimited Fusion boxes" 
            text="There is no limit to how many boxes one can create"
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page === 8 && <TutorialPopup
            title="Claim Hydrazine" 
            text="In order to claim Hydrazine produced by a box, click the “Claim” button on the Fusion Box"
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => setPage(page + 1)} />}
        {page === 9 && <TutorialPopup
            title="Start staking" 
            text="You are all set now commander, to start staking and claiming the hydrazine o7"
            containerStyle={{position: 'relative'}}
            maxPages={9}
            currentPage={page}
            closeTutorial={closeTutorial}
            backClick={() => setPage(page - 1)}
            nextClick={() => closeTutorial()} />}
    </div>
    )
};

export default VaultTutorial;