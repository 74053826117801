// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TVnkOeIx4gHtXx7BsGnP {
  display: flex;
  flex-direction: column;
  height: 50%;
  position: relative;
  margin-top: 14px;
  margin-bottom: 26px;
  width: 100%; }

.SO_ncBxfQ64QkJ3AC1vM {
  color: white;
  font-size: 14px;
  font-family: Chakra Petch;
  position: absolute;
  text-transform: uppercase;
  top: -16px;
  font-weight: bold; }

._83qSO8Q1GpPUqVt0gUF {
  color: white;
  font-size: 14px;
  font-family: Chakra Petch;
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  top: -26px;
  left: 32px; }

.phAQjy4p8r8v5Q8CQJSL {
  position: absolute;
  top: -28px;
  width: 24px;
  height: 24px; }

.WeiFLJTKPMALO2mdcSH_ {
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: Chakra Petch;
  background: transparent;
  border: initial;
  border-bottom: 2px solid #C4C4C4; }
  .WeiFLJTKPMALO2mdcSH_::placeholder {
    opacity: 0.5; }
  .WeiFLJTKPMALO2mdcSH_:focus {
    outline: none; }
    .WeiFLJTKPMALO2mdcSH_:focus + .SO_ncBxfQ64QkJ3AC1vM {
      color: white; }
  .WeiFLJTKPMALO2mdcSH_:disabled {
    color: #C4C4C4; }
`, "",{"version":3,"sources":["webpack://./src/Components/TextInput/TextInput.module.scss"],"names":[],"mappings":"AACA;EACC,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW,EAAA;;AAGZ;EACC,YAAY;EACT,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACb,iBAAiB,EAAA;;AAGlB;EACC,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,UAAU;EACV,UAAU,EAAA;;AAGX;EACC,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY,EAAA;;AAGb;EACC,YAAY;EACT,eAAe;EAClB,iBAAiB;EACd,yBAAyB;EACzB,uBAAuB;EACvB,eAAe;EACf,gCAAgC,EAAA;EAPpC;IASE,YAAY,EAAA;EATd;IAaE,aAAa,EAAA;IAbf;MAgBG,YAAY,EAAA;EAhBf;IAqBE,cAAa,EAAA","sourcesContent":["\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\theight: 50%;\r\n\tposition: relative;\r\n\tmargin-top: 14px;\r\n\tmargin-bottom: 26px;\r\n\twidth: 100%;\r\n}\r\n\r\n.label {\r\n\tcolor: white;\r\n    font-size: 14px;\r\n    font-family: Chakra Petch;\r\n    position: absolute;\r\n    text-transform: uppercase;\r\n    top: -16px;\r\n\tfont-weight: bold;\r\n}\r\n\r\n.labelWithPicture {\r\n\tcolor: white;\r\n\tfont-size: 14px;\r\n\tfont-family: Chakra Petch;\r\n\tposition: absolute;\r\n\ttext-transform: uppercase;\r\n\tfont-weight: bold;\r\n\ttop: -26px;\r\n\tleft: 32px;\r\n}\r\n\r\n.inputPicture {\r\n\tposition: absolute;\r\n\ttop: -28px;\r\n\twidth: 24px;\r\n\theight: 24px;\r\n}\r\n\r\n.input {\r\n\tcolor: white;\r\n    font-size: 16px;\r\n\tfont-weight: bold;\r\n    font-family: Chakra Petch;\r\n    background: transparent;\r\n    border: initial;\r\n    border-bottom: 2px solid #C4C4C4;\r\n\t&::placeholder {\r\n\t\topacity: 0.5;\r\n\t}\r\n\r\n\t&:focus {\r\n\t\toutline: none;\r\n\r\n\t\t& + .label {\r\n\t\t\tcolor: white;\r\n\t\t}\r\n\t}\r\n\r\n\t&:disabled {\r\n\t\tcolor:#C4C4C4; \r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `TVnkOeIx4gHtXx7BsGnP`,
	"label": `SO_ncBxfQ64QkJ3AC1vM`,
	"labelWithPicture": `_83qSO8Q1GpPUqVt0gUF`,
	"inputPicture": `phAQjy4p8r8v5Q8CQJSL`,
	"input": `WeiFLJTKPMALO2mdcSH_`
};
export default ___CSS_LOADER_EXPORT___;
