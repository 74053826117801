// Production constants
export const PROD_API_URL = "https://beta-api.starlaunch.com";
//export const PROD_RPC_NODE_URL = "https://thrumming-neat-sailboat.solana-mainnet.quiknode.pro/654c114337e8a48c107b58c32713b6f092ba3167";
export const PROD_RPC_NODE_URL = "https://chaotic-compatible-ensemble.solana-mainnet.quiknode.pro/9e5b0cfc7f0a5d6be776e009c2baa9b516d1e8aa/";
export const PRODUCTION = "PRODUCTION";
export const PROD_VAULT_PROGRAM_NAME = "star_vault";
export const PROD_IDO_PROGRAM_NAME = "ido_vault";
export const PROD_NODE_IDO_PROGRAM_NAME = "star_node3";
export const PROD_CADET_PROGRAM_ADDREESS = "F9zBS4xUUtGUfBddkAhXaiFxYkJbUpRVUoic4FxpnXR4";
export const PROD_STARS_MINT_ADDRESS = "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW";
export const PROD_HYDRAZINE_MINT_ADDRESS = "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda";
export const PROD_USDC_MINT_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
export const PROD_VAULT_PROGRAM_ADDREESS = "GZCipu6DQWQ1LTXuDg5yrvToz2LnUNxJzbVwy6x4sHC8";
export const PROD_IDO_PROGRAM_ADDREESS = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp"; //7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp
export const PROD_NODE_IDO_PROGRAM_ADDRESS = "A6VqF5jf9dgtpPso8a8ohmfewSyyFa4NdNHErYh3Q6YS";
export const PROD_CLAIMS_PROGRAM_ADDREESS = "jcamzkfhgokLg2oCRKH6mckg47t37ckHLyRMHN2si8s";
export const PROD_CADET_SEED = "Cadet_Reg";
export const PROD_REGISTER_CADET_SEED = "register_cadet";
export const PROD_VAULT_SEED = "vault_account";
export const PROD_POOL_USER_SEED = "pool_user";
export const PROD_FRACTAL_URL = "https://app.fractal.id/authorize?client_id=b5kR1YtTwLjdoWNkat9gxgj8HEKZIIj8pDBgnpCIVY0&redirect_uri=https%3A%2F%2Fapp.starlaunch.com%2F&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread";

// Old constants
export const API_URL = "https://api.starlaunch.com";
export const starsAdd = "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW";
export const hydrazineAdd = "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda";
export const usdcAdd = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
export const cadetProgramAdd = "F9zBS4xUUtGUfBddkAhXaiFxYkJbUpRVUoic4FxpnXR4";
export const vaultProgramAdd = "GZCipu6DQWQ1LTXuDg5yrvToz2LnUNxJzbVwy6x4sHC8";
export const idoProgramAdd = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp";
export const claimsProgramAdd = "jcamzkfhgokLg2oCRKH6mckg47t37ckHLyRMHN2si8s";
export const vaultProgramName = "star_vault"
export const idoProgramName = "ido_vault"
export const RPC_NODE_URL = "https://crimson-prettiest-choice.solana-mainnet.quiknode.pro/4bfe969c86c05d1209d6eaa4050ee741293be4c4/"

// Dev constants
export const DEV_API_URL = "https://dev-api.starlaunch.com";
//export const DEV_RPC_NODE_URL = "https://orbital-tame-violet.solana-devnet.quiknode.pro/5fb4c7df1e893f0029ea16ffb95e8eee4834ebe9";
export const DEV_RPC_NODE_URL = "https://wispy-virulent-sea.solana-devnet.quiknode.pro/20ad153fbe036422f00637bd27da0dd549ef17bd/";
export const DEVELOPMENT = "DEVELOPMENT";

export const DEV_STARS_MINT_ADDRESS = "HLujbavbSqnuWcEXpz9AdJfhu4oPJHXCzWkTqFQcsF6V";
export const DEV_HYDRAZINE_MINT_ADDRESS = "H8KpvSniaKpTF4VQxtj2igC5cpvvS3zJgawm3ehGrrPa";
export const DEV_USDC_MINT_ADDRESS = "CYoKH2gmndgTky3CEdrXhNRXjiHvUJ1UFkcjjjGWBqun"

export const DEV_IDO_PROGRAM_NAME = "ido_vault";
export const DEV_CLAIMS_PROGRAM_ADDREESS = "jcamzkfhgokLg2oCRKH6mckg47t37ckHLyRMHN2si8s";
export const DEV_CADET_PROGRAM_ADDREESS = "7Q2T2sGL8CHXETGa4TJEHRbqVSmGaxo1fssyNnf8XiKB";
export const DEV_IDO_PROGRAM_ADDREESS = "9zZJzETveeNmW8s7EHqSnMb6TJyofNpvbCUShQpiomVy";
export const DEV_NODE_IDO_PROGRAM_ADDRESS = "HooLKTEpSVFB5UspmTzc6FVgskNppZFd6Cx5BKCEX4dV";
export const DEV_NODE_IDO_PROGRAM_NAME = "star_node3";

export const DEV_VAULT_SEED = "vault_account";
export const DEV_VAULT_PROGRAM_NAME = "star_vau13";
export const DEV_VAULT_PROGRAM_ADDREESS = "4QzMs96NpMkeyfjaKqE3QcM3awToUbTzF5LECtdo2ase";

export const DEV_CADET_SEED = "Cadet_Reg3";
export const DEV_REGISTER_CADET_SEED = "register_cadet";
export const DEV_POOL_USER_SEED = "pool_user";

export const CLAIM_ALL_MAX_PER_TRANSACTION = 8;
export const TBA_UNIX_DATE = 944956800;
export const DEV_FRACTAL_URL = "https://app.next.fractal.id/authorize?client_id=aa9loKQ-wlFywJNA1FgH4V55TGIC4NOgI8-W7B_hjc0&redirect_uri=https%3A%2F%2Fdev-stars.starlaunch.com%2Fkyc&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread";

// export const cadetProgramAdd = "7Q2T2sGL8CHXETGa4TJEHRbqVSmGaxo1fssyNnf8XiKB";
// export const vaultProgramAdd = "4QzMs96NpMkeyfjaKqE3QcM3awToUbTzF5LECtdo2ase";
// export const idoProgramAdd = "9zZJzETveeNmW8s7EHqSnMb6TJyofNpvbCUShQpiomVy";
// export const claimsProgramAdd = "jcamzkfhgokLg2oCRKH6mckg47t37ckHLyRMHN2si8s";
// export const hydrazineAdd = "7M1kcRZU2WXwc3np2rgZ5Zx8J8zCzmHEj8JpdUGknuXV";
// export const idoProgramName = "ido_vault_test1"
// export const starsAdd = "HLujbavbSqnuWcEXpz9AdJfhu4oPJHXCzWkTqFQcsF6V";
// export const hydrazineAdd = "4uYvHeAyWozDfKjNuqn3PQzP1sQBLE8oL98a5MR3m4Sb";
// export const usdcAdd = "CYoKH2gmndgTky3CEdrXhNRXjiHvUJ1UFkcjjjGWBqun";
// export const cadetProgramAdd = "D28TWWwqMj8BAJ8v7Yfj45PxXzDNoGDMkJWuK75zU4v1"; // ?
// export const vaultProgramAdd = "DHjeqBzMPkMp8kGe9oSW3Z3eEDiBdZkuukxNBzMQbiJ6"; // ?
// export const idoProgramAdd = "7im9orzthW5w58Thf3d7RjhPCPpqGMGhgtZ11tQfgLxp"; // ?
// export const claimsProgramAdd = "5VNPPVs6JzmR9Hn4vnN1uk2y3JXZeDBjYx6X8ViNkP8V"; // ?

export const LOCKED_90BOX_TOOLTIP = "Meaning you will not lose stars that are staked in a Locked Fusion Box UNLESS you choose to unlock the box before the 90 day period has ended.";
export const LOCKED_180BOX_TOOLTIP = "Meaning you will not lose stars that are staked in a Locked Fusion Box UNLESS you choose to unlock the box before the 180 day period has ended.";
export const LOCKED_360BOX_TOOLTIP = "Meaning you will not lose stars that are staked in a Locked Fusion Box UNLESS you choose to unlock the box before the 360 day period has ended.";
export const UNLOCKED_BOX_TOOLTIP = "Meaning Stars that you place inside of a unlocked Fusion Box will be LOST slowly over time* however they can be removed from the Box at any time, The Unlocked Box Burn Rate is .67% each day";
export const VAULT_ID_TOOLTIP = "Use Fusion Boxes to Stake your Stars to start generating Hydrazine. You have the option to Stake Locked, or Unlocked";
export const STARS_AMOUNT = "Enter the amount of Stars you would like to stake here";

export const IDO_STATUS_COMPLETED = 'Completed';
export const IDO_STATUS_ONGOING = 'Ongoing';
export const IDO_STATUS_UPCOMING = 'Upcoming';
export const IDO_STATUS_REFUND = 'Refund';
