import React, {useState, useEffect} from 'react';

import { Program, Provider, web3, BN, Wallet } from '@project-serum/anchor';
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react';

import { Tooltip as ReactTooltip } from 'react-tooltip'
import toast from 'react-hot-toast';
import playerdashboardVideo from '../../images/playerDashboard.webm';
import playerdashboardVideoMobile from '../../images/playerDashboardMobile.webm';
import playerdashboardVideoMobileMp4 from '../../images/playerDashboardMobile.mp4';
import useMobileDetect from 'use-mobile-detect-hook';
import CaretForward from '../../images/svg/caretForward.svg';
import WalletNotConnectedIcon from '../../images/svg/walletNotConnected.svg';
import "./PlayerDashboard.scss";
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT } from '../../Components/Button/Button';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import { useHistory } from 'react-router-dom';
import PageSelector from '../../Components/PageSelector/PageSelector';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { setShowCreateAccount } from '../../Components/Header/statistics';
import { errorToast, successToast } from '../../Helpers/toastCSS';
import { connectWalletText, connectWalletTitle } from '../Vault/Vault';
import InfoCard from '../../Components/InfoCard/InfoCard';
import * as helpers from '../../Helpers/helper';
import CadetWalletInput from "../../Components/CadetWalletInput/CadetWalletInput";
import {walletFormatting} from "../../Helpers/helperFunctions";

const PAGE_DASHBOARD = 'PAGE_DASHBOARD';
const PAGE_WALLETS = 'PAGE_WALLETS';
const PAGE_LEADERBOARD = 'PAGE_LEADERBOARD';

const pageOptions = [
  {
    key: PAGE_DASHBOARD,
    label: 'DASHBOARD',
  },
  {
    key: PAGE_WALLETS,
    label: 'CADET WALLETS',
  },
  // {
  //   key: PAGE_LEADERBOARD,
  //   label: 'TOP OFFICERS',
  //   disabled: true,
  // },
];

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const TAB_PERSONAL_IDO_STATS = 'TAB_PERSONAL_IDO_STATS';
const TAB_GENERAL_IDO_STATS = 'TAB_GENERAL_IDO_STATS';

const tabOptions = [
  {
    key: TAB_PERSONAL_IDO_STATS,
    label: 'Personal IDO Stats',
  },
  {
    key: TAB_GENERAL_IDO_STATS,
    label: 'General IDO Stats',
  },
]


function Home(props) {
  const {
    provider,
  } = props;

  const { connection } = useConnection();
  const wallet = useWallet();
  const history = useHistory();
  const dispatch = useDispatch();
  const mobileDetect = useMobileDetect();
  const videoOff = useSelector((state) => state.sound.videoOff);
  const { SystemProgram, SYSVAR_RENT_PUBKEY } = web3;

  const walletStats = useSelector((state) => state.statistics.walletStats);
  const cadetStats = useSelector((state) => state.statistics.cadetStats);
  const personalCryptoStats = useSelector((state) => state.statistics.personalCryptoStats);
  const idoStats = useSelector((state) => state.statistics.idoStats);
  const hasCadetAccount = !useSelector((state) => state.statistics.showCreateAccount);
  const hasIdoAccount = !useSelector((state) => state.statistics.showCreateIdoAccount);

  const cadetId = cadetStats.cadetId;
  const cadetWallet = cadetStats.cadetWallet;
  const cadetRank = cadetStats.cadetRank;
  const cadetMp = cadetStats.cadetMP;
  const cadetLvl = cadetStats.cadetLvl;
  const cadetStars = walletStats.starsInWallet;
  const cadetN2h4 = walletStats.hydrazineInWallet;

  const ethereumWallet = cadetStats.cadetEthereumWallet;
  const polygonWallet = cadetStats.cadetPolygonWallet;
  const arbitrumWallet = cadetStats.cadetArbitrumWallet;
  const supraWallet = cadetStats.cadetSupraWallet;
  const suiWallet = cadetStats.cadetSUIWallet;
  const avalancheWallet = cadetStats.cadetAvalancheWallet;
  const dymensionWallet = cadetStats.cadetDymensionWallet;
  const skaleWallet = cadetStats.cadetSkaleWallet;
  const baseWallet = cadetStats.cadetBaseWallet;

  const [burnContribution, setBurnContribution] = useState();
  const DEFAULT_TIMEOUT = 31000;

  const [page, setPage] = useState(PAGE_DASHBOARD);
  const [tab, setTab] = useState(TAB_GENERAL_IDO_STATS);
  const [isLoading, setIsLoading] = useState(true);

async function createAccount() {
  try {
    const accts = await helpers.getAddys(provider);
    const cadetProgram = await helpers.getCadetProgram(provider);
    const transaction = new web3.Transaction();

    const [registrationAccount, registrationBump] = await web3.PublicKey.findProgramAddress(
      [
        helpers.getCadetSeedBuffer()
      ],
       helpers.getCadetProgramId()
     );

    transaction.add(
      cadetProgram.instruction.registerCadet({
        accounts: {
          userAuthority: provider.wallet.publicKey,
          cadetAccount: accts.cadetAcct,
          registrationAccount: registrationAccount,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Cadet Account Initiated", successToast);
    dispatch(setShowCreateAccount(false));
  
  } catch(e) {
    console.warn("Failed", e);
    toast(e.message, errorToast);
  }
}

async function closeAccount() {
  try {
    const accts = await helpers.getAddys(provider);
    const cadetProgram = await helpers.getCadetProgram(provider);
    const transaction = new web3.Transaction();
    transaction.add(
      cadetProgram.instruction.resignCadet({
        accounts: {
          userAuthority: provider.wallet.publicKey,
          cadetAccount: accts.cadetAcct,
          registrationAccount: accts.registrationAccount,
        },
      })
    );
    const signature = await provider.wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    toast("Cadet Account Closed", successToast);
    dispatch(setShowCreateAccount(true));
  } catch(e) {
    console.warn("Failed", e);
    toast(e.message, errorToast);
  }
}

const topOfficerContent = () => {
  let topOfficers = [1,2,3,4,5,6,7,8,9,10];
  return topOfficers.map(officer => {
    return <div className='topOfficerRow' key={officer}>
       <span>{officer + '.'}</span>
        <span>LOOOOONGNICKNAME</span>
        <span>93,674</span>
        <span>188</span>
    </div>
  })
}

// parseFloat(idoStats.totalHydrazineBurnt).toLocaleString()
  return (
    <main
    className='playerDashboardMain'>
      <div className='playerDashboardContainers'>
        <ReactTooltip id="playerDash" effect="solid" place="top" html={true} />
      {!wallet.publicKey && <InfoCard 
          text={connectWalletText()}
          title={connectWalletTitle()}
          icon={WalletNotConnectedIcon}/> }
       {wallet.publicKey && <PageSelector options={pageOptions} value={page} setValue={setPage} /> }
       
        {wallet.publicKey && !hasCadetAccount &&
        <div className='informationContainerNotConnected'>
           <div className='informationHeader'>Please create a Cadet ID to save your personal stats</div>
          <Button text="Create Cadet ID" onClick={() => createAccount()} /> 
        </div>}
        {page === PAGE_DASHBOARD && wallet.publicKey && hasCadetAccount &&
        <div className='informationContainer'>
            <div className='informationHeader'>Player Dashboard</div>
          <div className='personalInformationBody'>
            <div className='personalInformationBox'>
              <span className='greyTitle'>SOLANA WALLET</span>
              <span
                className='greyTitle'
                data-for='playerDash'>ETHEREUM WALLET</span>
              <span
                className='information-value'>{walletFormatting(cadetWallet)}</span>
              <span className='information-value'>{walletFormatting(ethereumWallet)}</span>
              <span
                className='greyTitle'
                data-tip="Performance on the Leaderboard determines your Rank"
                data-for="playerDash"></span>
            </div>
            <div className='personalInformationBox'>
              <span className='greyTitle'>POLYGON WALLET</span>
              <span
                className='greyTitle'>ARBITRUM WALLET</span>
              <span
                className='information-value'>{walletFormatting(polygonWallet)}</span>
              <span className='information-value'>{walletFormatting(arbitrumWallet)}</span>
              <span
                className='greyTitle'
                data-tip="Performance on the Leaderboard determines your Rank"
                data-for="playerDash"></span>
            </div>
            <div className='personalInformationBox'>
              <span className='greyTitle'>SUPRA WALLET</span>
              <span
                className='greyTitle'
                data-tip='Your Personal Cadet Number'
                data-for='playerDash'>SUI WALLET</span>
              <span
                className='information-value'>{walletFormatting(supraWallet)}</span>
              <span className='information-value'>{walletFormatting(suiWallet)}</span>
            </div>
            <div className='personalInformationBox'>
              <span className='greyTitle'>AVALANCHE WALLET</span>
              <span
                className='greyTitle'
                data-tip='Your Personal Cadet Number'
                data-for='playerDash'>DYMENSION WALLET</span>
              <span
                className='information-value'>{walletFormatting(avalancheWallet)}</span>
              <span className='information-value'>{walletFormatting(dymensionWallet)}</span>
            </div>
            <div className='personalInformationBox'>
              <span className='greyTitle'>SKALE WALLET</span>
              <span className='greyTitle'>BASE WALLET</span>
              <span className='information-value'>{walletFormatting(skaleWallet)}</span>
              <span className='information-value'>{walletFormatting(baseWallet)}</span>
            </div>
            {/* <span className='greyTitle' data-tip='Complete Missions from the Mission board to gain EXP' data-for='playerDash'>LVL</span>
             <span className='information-value'>{cadetLvl}</span> */}
            <ProgressBar percentage={parseInt(cadetMp)}/>
            <span className='starsTitle'>STARS</span>
            <div className='personalInformationBox'>
                <span
                  className='greyTitle'
                  data-tip='The amount of Unstaked stars in your Cadet Wallet'
                  data-for='playerDash'>IN WALLET
                </span>
              <span
                className='greyTitle'
                data-tip='The total amount of Stars staked in your Fusion Boxes'
                data-for='playerDash'>STAKED IN VAULT
                </span>
              <span className='information-value'>{parseFloat(cadetStars).toLocaleString()}</span>
              <span
                className='information-value'>{parseFloat(personalCryptoStats.personallyStakedStars).toLocaleString()}</span>
            </div>
            <div className='personalInformationBox'>
                <span
                  className='greyTitle'
                  data-tip='The amount of Stars you have lost from Early Unlock Penalty'
                  data-for='playerDash'>PENALIZED
                </span>
              <span
                className='greyTitle'
                data-tip='The amount of Stars you have lost from decay'
                data-for='playerDash'>DECAYED
                </span>
              <span
                className='information-value'>{parseFloat(personalCryptoStats.personallyPenalizedStars).toLocaleString()}</span>
              <span
                className='information-value'>{parseFloat(personalCryptoStats.personallyDecayedStars).toLocaleString()}</span>
            </div>
            <span className='hydrazineTitle'>HYDRAZINE</span>
            <div className='personalInformationBox'>
                <span
                  className='greyTitle'
                  data-tip='How much Hydrazine in your wallet'
                  data-for='playerDash'>IN WALLET
                </span>
              <span
                className='greyTitle'
                data-tip='The total amount of Hydrazine that is ready <br/> to be claimed from the Fusion Boxes'
                data-for='playerDash'>TO BE CLAIMED
                </span>
              <span className='information-value'>{parseFloat(cadetN2h4).toLocaleString()}</span>
              <span
                className='information-value'>{parseFloat(personalCryptoStats.personallyPendingHydrazine).toLocaleString()}</span>
            </div>

          </div>
        </div>}
        {page === PAGE_WALLETS && wallet.publicKey && hasCadetAccount &&
          <div className='informationContainer'>
            <div className='informationHeader'>CROSS-CHAIN WALLETS</div>
            <CadetWalletInput/>
          </div>}
        {page === PAGE_DASHBOARD && wallet.publicKey && hasCadetAccount &&
          <div className='tabDashboardContainer'>
            {!hasIdoAccount && <div className='informationContainer'>
              <div className='informationHeader'>View the IDO stats by creating a IDO account in the sales page</div>
              <div className='informationBody'>
                <Button buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} text='IDO PAGE' onClick={() => history.push('/sales')}
                          isProgressButton Icon={CaretForward}/>
              </div>
    
            </div>}
          {hasIdoAccount && <div className='informationContainer'>
            <div className='informationHeader'>IDO STATS</div>
            <div className='informationBody'>
              <div className='column'>
                <div className='valuePair'>
                  <span className='greyTitle'>TOTAL HYDRAZINE BURNT</span>
                  <span className='value'>{"-"}</span>
                </div>
               
              </div>
              <div className='column'>
                <div className='valuePair'>
                    <span className='greyTitle'>TOTAL RAISED</span>
                    <span className='value'>{parseFloat(idoStats.totalRaised).toLocaleString()}</span>
                </div>
              </div>
          </div>
           
        </div>}
        </div>
       }
        {page === PAGE_LEADERBOARD &&   
        <div className='leaderboardContainer'>
          <div className='topOfficerHeader'>
            <span>#</span>
            <span>NICKNAME</span>
            <span>MP</span>
            <span>LVL</span>
          </div>
          {topOfficerContent()}
        </div>}
      </div>

      {!mobileDetect.isMobile() && 
      <video 
      className='backgroundVideo' 
      autoPlay 
      loop
      playsInline
      onLoadedData={() => setIsLoading(false)}
      muted >
          <source src={playerdashboardVideo} type='video/webm' />
      </video>}
      {mobileDetect.isMobile() && !mobileDetect.isIos() &&
      <video 
      className='backgroundVideo' 
      autoPlay 
      loop 
      playsInline
      onLoadedData={() => setIsLoading(false)}
      muted >
          <source src={playerdashboardVideoMobile} type='video/webm' />
          <source src={playerdashboardVideoMobileMp4} type='video/mp4' />
      </video>}
      {mobileDetect.isMobile() && mobileDetect.isIos() && 
      <img className='backgroundVideo' src={playerdashboardVideoMobileMp4} alt="" onLoad={() => setIsLoading(false)} />}
      {isLoading && <LoadingSpinner />}
     {/*  {videoOff && <img className='backgroundVideo' src={playerdashboardImage} alt="" />} */}
    </main>
  );
}

export default Home;
