import {useDispatch, useSelector} from 'react-redux';
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY } from '../../Components/Button/Button';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './IdoOngoingModal.module.scss';
import {calculateBlockchain, hasValue, redirectToIdoDetails} from "../../Helpers/helperFunctions";
import IdoCard from "../IdoCard/IdoCard";
import BigNumber from "bignumber.js";
import {useHistory} from "react-router-dom";

function IdoOngoingModal() {
    const history = useHistory();

    const [open, setOpen] = useState(false);

    const containerRef = useRef();

    const ongoingIDOs = useSelector((state) => state.idoData.ongoingIDOs);

    const alreadyViewedIdoNames = useMemo(() => {
      const jsonNames = localStorage.getItem('idoOngoingModalNames');
      if (hasValue(jsonNames)) {
        return JSON.parse(jsonNames)
      }

      return [];
    })

    const saveIDONames = () => {
      const idoNames = ongoingIDOs.map(ido => ido.name);

      setOpen(false);
      localStorage.setItem('idoOngoingModalNames', JSON.stringify(idoNames));
    }

    const closeIdoOngoingModal = (event) => {
      if (containerRef.current && containerRef.current.contains(event.target)) return;

      saveIDONames();
    }

    const openIDO = (ido) => {
      saveIDONames()
      redirectToIdoDetails(ido, history);
    }

    useEffect(() => {
      if (open === false && ongoingIDOs.length > 0) {

        let alreadyViewed = true;
        ongoingIDOs.forEach(ido => {
          if (!alreadyViewedIdoNames.includes(ido.name)) {
            alreadyViewed = false;
          }
        })

        if (!window.location.pathname.includes('sale') && !alreadyViewed) {
          setOpen(true);
        }
      }
    }, [ongoingIDOs]);

    const mappedIDOs = useCallback((idoList) => {

      return idoList.map((ido, index) => {
        return (
          <IdoCard
            idoInfo={ido}
            key={index}
            onClick={() => openIDO(ido)}
          />
        )})
    }, [])

    if (open === false) {
      return;
    }

    const mappedOngoingIdos = mappedIDOs(ongoingIDOs);

    return (
        <div className={styles.idoOngoingModalOverlay} onClick={closeIdoOngoingModal}>
            <div className={styles.idoOngoingModalTitle}>
                <span>{(ongoingIDOs.length > 1 ? 'SALES' : 'SALE') + ' CURRENTLY ONGOING!'} </span>
            </div>
            <div className={styles.idoOngoingModalContainer} ref={containerRef}>
              {mappedOngoingIdos}
            </div>
        </div>
    );
}

export default IdoOngoingModal;
