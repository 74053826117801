import {PublicKey} from "@solana/web3.js";

export const availableFeeMints = [
  'So11111111111111111111111111111111111111112',
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
  'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
  '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
  'KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS',
  'SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd',
  '4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda',
  'ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs',
  'PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x',
  'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
  '3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug',
  'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx'
]

export const solanaDefaultToken = {
  label: 'Wrapped SOL',
  name: 'Solana',
  key: 'SOL',
  value: 'So11111111111111111111111111111111111111112',
  icon: 'https://img.raydium.io/icon/So11111111111111111111111111111111111111112.png',
  decimals: 6,
}

export const starsDefaultToken = {
  label: 'StarLaunch',
  name: 'Starlaunch',
  key: 'STARS',
  value: 'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
  icon: 'https://img.raydium.io/icon/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW.png',
  decimals: 6,
}

export const usdcDefaultToken = {
  label: 'USD Coin',
  name: 'USD Coin',
  key: 'USDC',
  value: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  icon: 'https://img.raydium.io/icon/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v.png',
  decimals: 6,
}

export const referralAccountPubkey = new PublicKey('GbG7uysPBt4EwdQyaTD8kaQR4EerKv4zG8vPvCZLz8yB');
export const SWAP_TOOLTIP_ID = 'SWAP_TOOLTIP_ID'
export const NO_TOKENS_RESPONSE = 'NO_TOKENS_FOUND';

export const officalTokens = [
  {
    "name": "Myro",
    "key": "$MYRO",
    "icon": "https://img.raydium.io/icon/HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4.png",
    "value": "HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4",
    "decimals": 9
  },
  {
    "name": "dogwifhat",
    "key": "$WIF",
    "icon": "https://img.raydium.io/icon/EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm.png",
    "value": "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    "decimals": 6
  },
  {
    "name": "Winerz",
    "key": "$WNZ",
    "icon": "https://img.raydium.io/icon/WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA.png",
    "value": "WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA",
    "decimals": 4
  },
  {
    "name": "AART",
    "key": "AART",
    "icon": "https://img.raydium.io/icon/F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B.png",
    "value": "F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B",
    "decimals": 6
  },
  {
    "name": "Allbridge",
    "key": "ABR",
    "icon": "https://img.raydium.io/icon/a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp.png",
    "value": "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
    "decimals": 9
  },
  {
    "name": "APEX",
    "key": "APEX",
    "icon": "https://img.raydium.io/icon/51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg.png",
    "value": "51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg",
    "decimals": 9
  },
  {
    "name": "APT",
    "key": "APT",
    "icon": "https://img.raydium.io/icon/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt.png",
    "value": "APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt",
    "decimals": 6
  },
  {
    "name": "Star Atlas",
    "key": "ATLAS",
    "icon": "https://img.raydium.io/icon/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx.png",
    "value": "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
    "decimals": 8
  },
  {
    "name": "Atlas Dex",
    "key": "ATS",
    "icon": "https://img.raydium.io/icon/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv.png",
    "value": "HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv",
    "decimals": 9
  },
  {
    "name": "Aurory",
    "key": "AURY",
    "icon": "https://img.raydium.io/icon/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP.png",
    "value": "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
    "decimals": 9
  },
  {
    "name": "Blockasset",
    "key": "BLOCK",
    "icon": "https://img.raydium.io/icon/NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk.png",
    "value": "NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk",
    "decimals": 6
  },
  {
    "name": "BOKU",
    "key": "BOKU",
    "icon": "https://img.raydium.io/icon/CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS.png",
    "value": "CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS",
    "decimals": 9
  },
  {
    "name": "Bonk",
    "key": "Bonk",
    "icon": "https://img.raydium.io/icon/DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263.png",
    "value": "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    "decimals": 5
  },
  {
    "name": "Boring Protocol",
    "key": "BOP",
    "icon": "https://img.raydium.io/icon/BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3.png",
    "value": "BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3",
    "decimals": 8
  },
  {
    "name": "Crypto Cavemen",
    "key": "CAVE",
    "icon": "https://img.raydium.io/icon/4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t.png",
    "value": "4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t",
    "decimals": 6
  },
  {
    "name": "DeFi Land",
    "key": "DFL",
    "icon": "https://img.raydium.io/icon/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh.png",
    "value": "DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh",
    "decimals": 9
  },
  {
    "name": "Dual DAO Token",
    "key": "DUAL",
    "icon": "https://img.raydium.io/icon/DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ.png",
    "value": "DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ",
    "decimals": 6
  },
  {
    "name": "DUST Protocol",
    "key": "DUST",
    "icon": "https://img.raydium.io/icon/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ.png",
    "value": "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    "decimals": 9
  },
  {
    "name": "Dexlab",
    "key": "DXL",
    "icon": "https://img.raydium.io/icon/GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z.png",
    "value": "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z",
    "decimals": 6
  },
  {
    "name": "Enrex",
    "key": "ENRX",
    "icon": "https://img.raydium.io/icon/5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N.png",
    "value": "5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N",
    "decimals": 2
  },
  {
    "name": "EURC",
    "key": "EURC",
    "icon": "https://img.raydium.io/icon/HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr.png",
    "value": "HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr",
    "decimals": 6
  },
  {
    "name": "EUROe Stablecoin",
    "key": "EUROe",
    "icon": "https://img.raydium.io/icon/2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg.png",
    "value": "2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg",
    "decimals": 6
  },
  {
    "name": "FABRIC",
    "key": "FAB",
    "icon": "https://img.raydium.io/icon/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96.png",
    "value": "EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96",
    "decimals": 9
  },
  {
    "name": "Space Falcon",
    "key": "FCON",
    "icon": "https://img.raydium.io/icon/HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj.png",
    "value": "HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj",
    "decimals": 4
  },
  {
    "name": "Bonfida",
    "key": "FIDA",
    "icon": "https://img.raydium.io/icon/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp.png",
    "value": "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
    "decimals": 6
  },
  {
    "name": "Gari",
    "key": "GARI",
    "icon": "https://img.raydium.io/icon/CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks.png",
    "value": "CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks",
    "decimals": 9
  },
  {
    "name": "Grape",
    "key": "GRAPE",
    "icon": "https://img.raydium.io/icon/8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA.png",
    "value": "8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA",
    "decimals": 6
  },
  {
    "name": "GST",
    "key": "GST",
    "icon": "https://img.raydium.io/icon/AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB.png",
    "value": "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
    "decimals": 9
  },
  {
    "name": "Guacamole",
    "key": "GUAC",
    "icon": "https://img.raydium.io/icon/AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR.png",
    "value": "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
    "decimals": 5
  },
  {
    "name": "Hawksight",
    "key": "HAWK",
    "icon": "https://img.raydium.io/icon/BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE.png",
    "value": "BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE",
    "decimals": 6
  },
  {
    "name": "Hubble Protocol Token",
    "key": "HBB",
    "icon": "https://img.raydium.io/icon/HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6.png",
    "value": "HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6",
    "decimals": 6
  },
  {
    "name": "Helium Network Token",
    "key": "HNT",
    "icon": "https://img.raydium.io/icon/hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux.png",
    "value": "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
    "decimals": 8
  },
  {
    "name": "HTO",
    "key": "HTO",
    "icon": "https://img.raydium.io/icon/htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD.png",
    "value": "htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD",
    "decimals": 9
  },
  {
    "name": "Honeyland",
    "key": "HXD",
    "icon": "https://img.raydium.io/icon/3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug.png",
    "value": "3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug",
    "decimals": 9
  },
  {
    "name": "Helium IOT",
    "key": "IOT",
    "icon": "https://img.raydium.io/icon/iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns.png",
    "value": "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns",
    "decimals": 6
  },
  {
    "name": "JITO",
    "key": "JTO",
    "icon": "https://img.raydium.io/icon/jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL.png",
    "value": "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
    "decimals": 9
  },
  {
    "name": "Jupiter",
    "key": "JUP",
    "icon": "https://img.raydium.io/icon/JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN.png",
    "value": "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN",
    "decimals": 6
  },
  {
    "name": "KIN",
    "key": "KIN",
    "icon": "https://img.raydium.io/icon/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6.png",
    "value": "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
    "decimals": 5
  },
  {
    "name": "Kamino",
    "key": "KMNO",
    "icon": "https://img.raydium.io/icon/KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS.png",
    "value": "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS",
    "decimals": 6
  },
  {
    "name": "Larix",
    "key": "LARIX",
    "icon": "https://img.raydium.io/icon/Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC.png",
    "value": "Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC",
    "decimals": 6
  },
  {
    "name": "MEAN",
    "key": "MEAN",
    "icon": "https://img.raydium.io/icon/MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD.png",
    "value": "MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD",
    "decimals": 6
  },
  {
    "name": "Media Network",
    "key": "MEDIA",
    "icon": "https://img.raydium.io/icon/ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs.png",
    "value": "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
    "decimals": 6
  },
  {
    "name": "Mango",
    "key": "MNGO",
    "icon": "https://img.raydium.io/icon/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac.png",
    "value": "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
    "decimals": 6
  },
  {
    "name": "Metaplex Token",
    "key": "MPLX",
    "icon": "https://img.raydium.io/icon/METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m.png",
    "value": "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m",
    "decimals": 6
  },
  {
    "name": "MXM",
    "key": "MXM",
    "icon": "https://img.raydium.io/icon/H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav.png",
    "value": "H53UGEyBrB9easo9ego8yYk7o4Zq1G5cCtkxD3E3hZav",
    "decimals": 6
  },
  {
    "name": "Nest Arcade",
    "key": "NESTA",
    "icon": "https://img.raydium.io/icon/Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV.png",
    "value": "Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV",
    "decimals": 9
  },
  {
    "name": "OOGI",
    "key": "OOGI",
    "icon": "https://img.raydium.io/icon/H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A.png",
    "value": "H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A",
    "decimals": 9
  },
  {
    "name": "Orca",
    "key": "ORCA",
    "icon": "https://img.raydium.io/icon/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE.png",
    "value": "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
    "decimals": 6
  },
  {
    "name": "Octokn",
    "key": "OTK",
    "icon": "https://img.raydium.io/icon/octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ.png",
    "value": "octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ",
    "decimals": 9
  },
  {
    "name": "Star Atlas DAO",
    "key": "POLIS",
    "icon": "https://img.raydium.io/icon/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk.png",
    "value": "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
    "decimals": 8
  },
  {
    "name": "PRISM",
    "key": "PRISM",
    "icon": "https://img.raydium.io/icon/PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x.png",
    "value": "PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x",
    "decimals": 6
  },
  {
    "name": "Pyth Network",
    "key": "PYTH",
    "icon": "https://img.raydium.io/icon/HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3.png",
    "value": "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
    "decimals": 6
  },
  {
    "name": "Raydium",
    "key": "RAY",
    "icon": "https://img.raydium.io/icon/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R.png",
    "value": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
    "decimals": 6
  },
  {
    "name": "Republic Credits",
    "key": "RPC",
    "icon": "https://img.raydium.io/icon/EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3.png",
    "value": "EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3",
    "decimals": 6
  },
  {
    "name": "Samoyed Coin",
    "key": "SAMO",
    "icon": "https://img.raydium.io/icon/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU.png",
    "value": "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
    "decimals": 9
  },
  {
    "name": "Saber Protocol Token",
    "key": "SBR",
    "icon": "https://img.raydium.io/icon/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1.png",
    "value": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
    "decimals": 6
  },
  {
    "name": "Seeded Network",
    "key": "SEEDED",
    "icon": "https://img.raydium.io/icon/seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs.png",
    "value": "seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs",
    "decimals": 9
  },
  {
    "name": "Project SEED Token",
    "key": "SHILL",
    "icon": "https://img.raydium.io/icon/6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd.png",
    "value": "6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd",
    "decimals": 6
  },
  {
    "name": "Solanium",
    "key": "SLIM",
    "icon": "https://img.raydium.io/icon/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW.png",
    "value": "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
    "decimals": 6
  },
  {
    "name": "Solrise Finance",
    "key": "SLRS",
    "icon": "https://img.raydium.io/icon/SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr.png",
    "value": "SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr",
    "decimals": 6
  },
  {
    "name": "SynesisOne",
    "key": "SNS",
    "icon": "https://img.raydium.io/icon/SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd.png",
    "value": "SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd",
    "decimals": 9
  },
  {
    "name": "Synthetify",
    "key": "SNY",
    "icon": "https://img.raydium.io/icon/4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y.png",
    "value": "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
    "decimals": 6
  },
  {
    "name": "Solcubator",
    "key": "SOLC",
    "icon": "https://img.raydium.io/icon/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT.png",
    "value": "Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT",
    "decimals": 9
  },
  {
    "name": "SONAR",
    "key": "SONAR",
    "icon": "https://img.raydium.io/icon/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE.png",
    "value": "sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE",
    "decimals": 9
  },
  {
    "name": "Serum",
    "key": "SRM",
    "icon": "https://img.raydium.io/icon/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt.png",
    "value": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
    "decimals": 6
  },
  {
    "name": "STARS",
    "key": "STARS",
    "icon": "https://img.raydium.io/icon/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW.png",
    "value": "HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW",
    "decimals": 6
  },
  {
    "name": "Step",
    "key": "STEP",
    "icon": "https://img.raydium.io/icon/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT.png",
    "value": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
    "decimals": 9
  },
  {
    "name": "Tulip",
    "key": "TULIP",
    "icon": "https://img.raydium.io/icon/TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs.png",
    "value": "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
    "decimals": 6
  },
  {
    "name": "USD Coin",
    "key": "USDC",
    "icon": "https://img.raydium.io/icon/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v.png",
    "value": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    "decimals": 6
  },
  {
    "name": "USDT",
    "key": "USDT",
    "icon": "https://img.raydium.io/icon/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB.png",
    "value": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    "decimals": 6
  },
  {
    "name": "Ondo US Dollar Yield",
    "key": "USDY",
    "icon": "https://img.raydium.io/icon/A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6.png",
    "value": "A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6",
    "decimals": 6
  },
  {
    "name": "VNX Swiss Franc",
    "key": "VCHF",
    "icon": "https://img.raydium.io/icon/AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch.png",
    "value": "AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch",
    "decimals": 9
  },
  {
    "name": "VNX Euro",
    "key": "VEUR",
    "icon": "https://img.raydium.io/icon/C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519.png",
    "value": "C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519",
    "decimals": 9
  },
  {
    "name": "VNX Gold",
    "key": "VNXAU",
    "icon": "https://img.raydium.io/icon/9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R.png",
    "value": "9TPL8droGJ7jThsq4momaoz6uhTcvX2SeMqipoPmNa8R",
    "decimals": 9
  },
  {
    "name": "WOOFENOMICS",
    "key": "WOOF",
    "icon": "https://img.raydium.io/icon/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE.png",
    "value": "9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE",
    "decimals": 6
  },
  {
    "name": "Wrapped Solana",
    "key": "WSOL",
    "icon": "https://img.raydium.io/icon/So11111111111111111111111111111111111111112.png",
    "value": "So11111111111111111111111111111111111111112",
    "decimals": 9
  },
]
