import React from "react";
import "./InfoCard.scss";
import Button, { BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY } from "../Button/Button";

function InfoCard(props) {
    const {
        text,
        title,
        buttonIcon,
        icon,
        buttonText,
        secondaryButtonText,
        secondaryButtonClick,
        onConfirm,
    } = props;

    const renderIcon = () => {
        return React.createElement(icon, {className: "icon"});
    }

  return (
      <div className="infoCardContainer">
            <div className="textContainer">
                {renderIcon()}
                {title}
                {text}
            </div>
            <div className="buttonRow">
                {secondaryButtonText && <Button text={secondaryButtonText} buttonType={BUTTON_TYPE_SECONDARY} onClick={secondaryButtonClick} /> }
                {buttonText && <Button text={buttonText} Icon={buttonIcon} buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={onConfirm} isProgressButton /> }
            </div>
      </div>
  );
}

export default InfoCard;
