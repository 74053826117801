import React from "react";
import { useLocation } from "react-router-dom";
import './MobileMenuOption.scss';
import Icon from "../Icon/Icon";

function MobileMenuOption(props) {
    const {
        path,
        label,
        OptionIcon,
        OptionActiveIcon,
        useClassName = false,
        onClick,
        disabled,
        optionKey,
    } = props;

    const location = useLocation();

    const optionClassname = () => {
        let className = 'mobileMenuOption';

        if (location.pathname === path) {
            return className + 'Active';
        }
        if (disabled) {
            return className + 'Disabled'
        }
        return className;
    }

    const iconClassname = () => {
        if (!useClassName) {
            return '';
        }

        let className = 'menuOptionIcon';

        if (disabled) {
            return className + 'Disabled'
        }
        return className;
    }

    return (
    <div className={optionClassname()} onClick={onClick} key={optionKey}>
        <div className='menuOptionCorner'>
            {location.pathname === path && OptionActiveIcon &&
                <Icon Icon={OptionActiveIcon} className={iconClassname()} />}
            {OptionIcon && location.pathname !== path && <Icon Icon={OptionIcon} className={iconClassname()}  />}
        </div>
        <span className='menuOptionText'>{label}</span>
        <div className='menuOptionCorner' />
    </div>
    )
}

export default MobileMenuOption;
