import React from "react";
import styles from "./TextAreaInput.module.scss";

function TextAreaInput(props) {
    const {
        id,
        name = "",
        value,
        setValue,
        label,
        placeholder,
        type="text",
        rows = 1,
        required = false,
        disabled = false,
    } = props;


    const handleChange = (event) => {
        if (setValue) {
            setValue(event.target.value);
        }
    };

    return (
        <div className={styles.inputContainer}>            
            <textarea id={id}
                className={styles.input} 
                value={value}
                name={name}
                required={required}
                autoComplete="off"
                onChange={handleChange}
                placeholder={placeholder}
                rows={rows}
                type={type}
                disabled={disabled} />
            {label && <label htmlFor={id} className={styles.label}>
                {label}
            </label> }
        </div>
    );
}

export default TextAreaInput;
