import {
    PATH_CLAIMS_PAGE,
    PATH_HOME,
    PATH_IDO_PAGE,
    PATH_MISSION_DASHBOARD, PATH_NODE_SALE_DETAILS,
    PATH_PLAYER_DASHBOARD,
    PATH_SWAP_PAGE,
    PATH_VAULT
} from '../../App';
import RocketIcon from '../../images/svg/rocket.svg'
import RocketActiveIcon from '../../images/svg/rocket-active.svg'
import FusionReactorIcon from '../../images/svg/fusionReactor.svg'
import FusionReactorActiveIcon from '../../images/svg/fusionReactor-active.svg'
import PlayerDashboardIcon from '../../images/svg/playerDashboard.svg'
import PlayerDashboardActiveIcon from '../../images/svg/playerDashboard-active.svg'
import ClaimsPageIcon from '../../images/svg/claimsPage.svg'
import ClaimsPageActiveIcon from '../../images/svg/claimsPage-active.svg'
import IdoPageIcon from '../../images/svg/idoPage.svg'
import IdoPageActiveIcon from '../../images/svg/idoPage-active.svg'
import SwapIcon from '../../images/svg/swap-icon.svg'
import SwapActiveIcon from '../../images/svg/swap-icon-active.svg'
import InformationIcon from '../../images/svg/information-outline.svg'
import HelpIcon from '../../images/svg/help-outline.svg'
import TelegramIcon from '../../images/svg/telegram.svg'
import MediumIcon from '../../images/svg/medium.svg'
import TwitterIcon from '../../images/svg/twitter.svg'
import DiscordIcon from '../../images/svg/discord.svg'
import './MobileMenu.scss';
import MobileMenuOption from './MobileMenuOption';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { LeftNavClickAudio } from '../Leftnav/Leftnav';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ButtonClickAudio } from "../Button/Button";
import { toggleShowInformation, toggleShowTutorial } from "../Leftnav/modal.js";
import StarsDeck from '../../docs/StarLaunchDeck.pdf';
import {doesURLInclude} from "../../Helpers/helper";

function MobileMenu(props) {
    const {
        onClose,
        kycStatus,
        kycCountry
    } = props;

    const history = useHistory();
    const dispatch = useDispatch()
    const isMuted = useSelector((state) => state.sound.mute);

    const redirectTo = useCallback((page) => {
        if (!isMuted) {
            LeftNavClickAudio.play();
        }

        history.push(page);
        onClose();
    }, [history])

    const openURL = (url) => {
        window.open(url);
    }

    const _toggleShowInformation = useCallback(() => {
        if (!isMuted) {
            ButtonClickAudio.play();
        }
        dispatch(toggleShowInformation())
        onClose();
    }, [isMuted, ButtonClickAudio, dispatch]);

    const _toggleShowTutorial = useCallback(() => {
        if (!isMuted) {
            ButtonClickAudio.play();
        }
        onClose();
        dispatch(toggleShowTutorial());
    }, [isMuted, ButtonClickAudio, dispatch]);

    return (
        <nav
            className="mobileMenuContainer">
            <WalletMultiButton />
            <MobileMenuOption path={PATH_HOME} label="HOME" OptionIcon={RocketIcon} OptionActiveIcon={RocketActiveIcon} onClick={() => redirectTo(PATH_HOME)} />
            <MobileMenuOption path={PATH_VAULT} label="FUSION REACTOR" OptionIcon={FusionReactorIcon} OptionActiveIcon={FusionReactorActiveIcon} onClick={() => redirectTo(PATH_VAULT)} />
            <MobileMenuOption path={PATH_PLAYER_DASHBOARD} label="PLAYER DASHBOARD" OptionIcon={PlayerDashboardIcon} OptionActiveIcon={PlayerDashboardActiveIcon} onClick={() => redirectTo(PATH_PLAYER_DASHBOARD)} />
            <MobileMenuOption path={PATH_IDO_PAGE} label="IDO PAGE" OptionIcon={IdoPageIcon} OptionActiveIcon={IdoPageActiveIcon} onClick={() => redirectTo(PATH_IDO_PAGE)} />
            <MobileMenuOption path={PATH_CLAIMS_PAGE} label="CLAIMS PAGE" OptionIcon={ClaimsPageIcon} OptionActiveIcon={ClaimsPageActiveIcon} onClick={() => redirectTo(PATH_CLAIMS_PAGE)} />
           {/* <MobileMenuOption path={PATH_NODE_SALE_DETAILS} label="AETHIR NODE SALE" OptionIcon={AethirLogo} OptionActiveIcon={AethirLogo} onClick={() => redirectTo(PATH_NODE_SALE_DETAILS)} useClassName />*/}
            <MobileMenuOption path={PATH_SWAP_PAGE} label="SWAP PAGE" OptionIcon={SwapIcon} OptionActiveIcon={SwapActiveIcon} onClick={() => redirectTo(PATH_SWAP_PAGE)} useClassName />
            <div className='mobileMenuMiscOption' onClick={_toggleShowInformation} >
                <div className='menuOptionCorner'>
                    <InformationIcon  />
                </div>
                <span className='menuMiscOptionText'>Information</span>
                <div className='menuOptionCorner' />
            </div>
            <div className='mobileMenuMiscOption' onClick={_toggleShowTutorial} >
                <div className='menuOptionCorner'>
                    <HelpIcon />
                </div>
                <span className='menuMiscOptionText'>Tutorial</span>
                <div className='menuOptionCorner' />
            </div>
            <div className='mobileMenuMiscOption' onClick={() => openURL(StarsDeck)} >
                <div className='menuOptionCorner'>
                </div>
                <span className='menuMiscOptionText'>Deck {kycStatus}</span>
                <div className='menuOptionCorner' />
            </div>
            {
                kycStatus === 0 ?
                    <div className='mobileMenuMiscOption' onClick={() => {}} >
                        <div className='menuOptionCorner'>
                        </div>
                        <span className='menuMiscOptionText'>FETCHING KYC STATUS ...</span>
                        <div className='menuOptionCorner' />
                    </div>
                    :
                    kycStatus === 1 ?
                        <div className='mobileMenuMiscOption mobileMenuMiscOptionSuccess' onClick={() => {}} >
                            <div className='menuOptionCorner'>
                            </div>
                            <span className='menuMiscOptionText'>KYC IS APPROVED ({kycCountry})</span>
                            <div className='menuOptionCorner' />
                        </div>
                        :
                        <div className='mobileMenuMiscOption mobileMenuMiscOptionError' onClick={() => {}} >
                            <div className='menuOptionCorner'>
                            </div>
                            <span className='menuMiscOptionText'>KYC NOT APPROVED</span>
                            <div className='menuOptionCorner' />
                        </div>
            }
            <div className='flex-divider' />
            <div className='mobileSocialMediaRow'>
                <div className="socialMediaBoxMobile" onClick={() => openURL('https://medium.com/@StarLaunch')}>
                    <MediumIcon className="socialMediaIcon" />
                </div>
                <div className="socialMediaBoxMobile" onClick={() => openURL('https://twitter.com/StarLaunchSOL')}>
                    <TwitterIcon className="socialMediaIcon" />
                </div>
                <div className="socialMediaBoxMobile" onClick={() => openURL('https://discord.gg/invite/StarlaunchSOL')}>
                    <DiscordIcon className="socialMediaIcon" />
                </div>
            </div>
        </nav>
    )
}

export default MobileMenu;
