import React from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Button from "../Button/Button";
import Property from "../Property/Property";
import styles from './HeaderVault.module.scss';
import ClaimIcon from '../../images/svg/claimVault.svg';

export const HEADER_VAULT_ID = 'HEADER_VAULT_ID';

function HeaderVault(props) {
    const {
        totalStaked = 0,
        totalDecayed = 0,
        totalPenalized = 0,
        personallyStakedStars = 0,
        personallyPendingHydrazine = 0,
        totalHydrazine = 0,
        claimAllHydrazine,
        claimingHydrazine,
    } = props;    

    // This should be based on the vault Type. Generation Rate = 0.3 per sec *  60 * 60 = 1080 per hour
    const hydrazinePerHour = (personallyStakedStars / totalStaked) * (540); 
    // parseFloat(personallyPendingHydrazine).toLocaleString(2)
    return (
        <div className={styles.container}>
            <div className={styles.firstCard}>
                <ReactTooltip id={HEADER_VAULT_ID} effect="solid" place="top" />
                <h3 className={styles.header}>Stars</h3>
                <div className={styles.propertyContainerFirst}>
                    <Property 
                        title="Staked/Total" 
                        value={parseFloat(totalStaked).toLocaleString(1)} 
                        helpTooltip="Total Stars in Ecosystem Staked"
                        dataTip={HEADER_VAULT_ID} />
                    <Property 
                        title="Decayed/Total" 
                        value={parseFloat(totalDecayed).toLocaleString(1)}
                        className={styles.alignContent}
                        helpTooltip={(
                        <span>
                            Total Stars in Ecosystem Decayed*
                            <br />
                            <br />
                            Meaning Total Stars lost to Early Unlock Penalty and Daily Unlocked Fusion Box Burn
                            <br/>
                            Stars lost in this way are permanently removed from the total supply
                        </span>)}
                        dataTip={HEADER_VAULT_ID} />    
                    <Property 
                        title="Staked/Personally" 
                        value={parseFloat(personallyStakedStars).toLocaleString(1)}
                        helpTooltip="The amount of staked stars you have across all Fusion Boxes"
                        dataTip={HEADER_VAULT_ID} />     
                    <Property 
                        title="Penalized/Total" 
                        className={styles.alignContent}
                        value={parseFloat(totalPenalized).toLocaleString(1)}
                        helpTooltip="The total amount of Stars lost from Early Unlock Penalty"
                        dataTip={HEADER_VAULT_ID} />                     
                </div>                
            </div>
            <div className={styles.secondCard}>
                <h3 className={styles.header}>Hydrazine</h3>
                <div className={styles.propertyContainer}>
                    <Property 
                        title="To be Claimed" 
                        value={parseFloat(personallyPendingHydrazine).toLocaleString(2)}
                        helpTooltip="The amount of Hydrazine you can claim across all Fusion Boxes"
                        dataTip={HEADER_VAULT_ID} />
                    <Property 
                        title="Total Hydrazine" 
                        value={parseFloat(totalHydrazine).toLocaleString(2)}
                        helpTooltip="This is the total circulating supply of Hydrazine"
                        className={styles.alignContent}
                        dataTip={HEADER_VAULT_ID} />
                    <Property 
                        title="Currently Generating" 
                        value={parseFloat(hydrazinePerHour).toLocaleString(2)}
                        helpTooltip="Amount of Hydrazine you are generating per hour"
                        dataTip={HEADER_VAULT_ID} /> 
                    <div className={styles.claimAllContainer}>
                        <Button 
                            text="Claim ALL" 
                            onClick={claimAllHydrazine} 
                            Icon={ClaimIcon}
                            dataTip={HEADER_VAULT_ID}
                            tooltip="Claim all of your Fusion boxes at once. Every 8 boxes will be a seperate transaction due to constraints." />
                        {claimingHydrazine && <span className={styles.claimAllInfoText}>{claimingHydrazine}</span>}
                    </div>
                </div>                
            </div>

        </div>           
    );
}

export default HeaderVault;
