import React, {useCallback, useEffect, useMemo, useState} from "react";
import './BuyAllocationForm.scss';
import {
  getEstimatedAllocation, getIdoNodeTime,
  getIdoTime,
  getProgressBarInformation,
  IDO_NODE_SALE,
} from "../../Helpers/idoHelper";
import styles from "./IdoSalesStage.module.scss";
import Countdown from "react-countdown";
import moment from "moment";
import sl_logo from '../../images/sl-icon-colored.png'
import IdoPurchaseAllocation from "./IdoPurchaseAllocation";
import {
  hasValue
} from "../../Helpers/helperFunctions";
import {NODE_FCFS_SALE_STAGE, NODE_OG_SALE_STAGE, NODE_STAKERS_SALE_STAGE} from "./IdoDetails";
import {TBA_UNIX_DATE} from "../../constants/constants";
import {useWallet} from "@solana/wallet-adapter-react";
import * as helpers from "../../Helpers/helper";

const IdoNodeSaleStage = (props) => {
  const {
    stage,
    currentStage,
    poolData,
    idoInfo,
    participantInfo,
    setParticipantInfo,
    allocationInfo,
    provider,
    walletBalance,
    setIsSendingTransaction,
  } = props;

  const wallet = useWallet();

  const [merkelProof, setMerkelProof] = useState()

  const idoTimes = getIdoNodeTime(idoInfo, stage);
  const API_URL = helpers.getApiUrl()

  const timeNow = moment().unix();

  const startTimeTBA = TBA_UNIX_DATE > idoTimes.start;
  const endTimeTBA = TBA_UNIX_DATE > idoTimes.end;

  const startTimeUnix = moment.unix(idoTimes.start);
  const endTimeUnix = moment.unix(idoTimes.end);

  const userNodePurchased = hasValue(participantInfo?.bought) ? Number(participantInfo?.bought?.toString()) : 0;
  const nodesForSale = hasValue(poolData?.poolInfo?.nodesForSale) ? Number(poolData.poolInfo?.nodesForSale.toString()) : 0;
  const nodesSold = hasValue(poolData?.poolInfo?.nodesSold) ? Number(poolData.poolInfo?.nodesSold.toString()) : 0;

  const hasStarted = () => {
    if (startTimeTBA || endTimeTBA) {
      return false;
    }

    return idoTimes.start < timeNow;
  }

  const hasEnded = () => {
    if (startTimeTBA || endTimeTBA) {
      return false;
    }

    if (idoTimes.end < timeNow) {
      return true;
    }

    return nodesForSale === nodesSold && nodesSold !== 0;
  };

  useEffect(() => {
    const getMerkleProof = async () => {
      const name = idoInfo?.name.replace(/\s/g,'').toLowerCase();
      const data = await fetch(API_URL + `/node/allocation?wallet=${wallet.publicKey.toString()}&sale=${name}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "accept": "*"
        },
      });


      if (!(await data).ok) return []
      const result = await data.json();
      setMerkelProof(result.data);
    }

    if (hasStarted() && !hasEnded() && stage === NODE_OG_SALE_STAGE && !hasValue(merkelProof)) {
      getMerkleProof();
    }
  }, [idoTimes]);

  const disablePurchaseAllocation = useMemo(() => {
    if (stage === NODE_OG_SALE_STAGE) {
      if (merkelProof?.allocation !== true) {
        return {
          disabled: true,
          reason: 'YOU ARE NOT ELIGIBLE FOR THIS ROUND'
        }
      }
    }

    return {
      disabled: false,
    }
  }, [merkelProof, stage]);

  const saleTitleAndText = useMemo(() => {
    if (stage === NODE_OG_SALE_STAGE) {
      return { title: NODE_OG_SALE_STAGE, description: 'SALE ROUND FOR ALL OUR LOYAL OG CADETS'}
    }
    if (stage === NODE_STAKERS_SALE_STAGE) {
      return { title: NODE_STAKERS_SALE_STAGE, description: 'SALE ROUND WITH VARYING PRICE DEPENDING ON YOUR CURRENT STAKED STARS'}
    }
    if (stage === NODE_FCFS_SALE_STAGE) {
      return { title: 'FCFS ROUND', description: 'This is your chance to secure some extra tokens' }
    }

    return { title: 'NODE SALE', description: 'PURCHASE NODES BASED ON YOUR CURRENT TIER'}

  }, [stage])

  const contributionRowContent = () => {
    return (
      <div className={styles.saleContributionRow}>
        <div className={styles.saleContributionBoxColumn}>
          <span className={styles.saleContributionValueColumnLabel}>
            {'YOUR ALLOCATION:'}
          </span>
          <span className={styles.saleContributionValueColumnValue}>{poolData?.poolInfo?.individualLimit.toString() + ' NODE(S)'}</span>
        </div>
        <div className={styles.saleContributionDivider}/>
        <div className={styles.saleContributionBoxColumn}>
          <span className={styles.saleContributionValueColumnLabel}>
            {'YOUR NODE PRICE'}
          </span>
          <span className={styles.saleContributionValueColumnValue}>
            {idoInfo?.hydrazinePrice + ' HYDRAZINE + ' + idoInfo?.nodePrice}
          </span>
        </div>
      </div>
    )
  }

  const allocationEndedRowContent = () => {
    if (userNodePurchased === 0) {
      return(
        <div className={styles.saleAllocationContainer}>
         <span className={styles.saleNotParticipatedText}>
           YOU DID NOT PARTICIPATE IN THIS SALE
          </span>
        </div>)
    }

    return (
      <div className={styles.saleAllocationContainer}>
        <span className={styles.saleFundedAllocationText}>
          ✅ {'YOU BOUGHT ' + userNodePurchased + ' NODE(S)'}
        </span>
      </div>
    )
  }

  const currentlyClosedTitle = (customText) => {
    return (
      <div className={styles.saleClosedContainer}>
        {!hasValue(customText) && <span className={styles.saleClosedTitle}>{saleTitleAndText.title + ' IS UPCOMING'}</span>}
        {hasValue(customText) && <span className={styles.saleClosedTitle}>{customText}</span>}
        <img className={styles.saleClosedPicture} src={sl_logo} alt="Starlaunch Logo"/>
      </div>
    )
  }

  const saleEndedContent = () => {
    return (
      <div className={styles.saleEndContainer}>
        <div className={styles.titleRow}>
          <div className={styles.titleBox}>
            <span className={styles.saleStageTitle}>{saleTitleAndText.title}</span>
            <span className={styles.saleStageDescription}>{saleTitleAndText.description}</span>
          </div>
          {!hasEnded() && <div className={styles.saleStageWindowCountdownContainer}>
            {startTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW OPEN</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>TO BE ANNOUNCED</span>
                <br/>
              </div>
            </div>}
            {!startTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW OPEN</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment(startTimeUnix).utc().format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={idoTimes.start * 1000} renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>}
            {endTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>TO BE ANNOUNCED</span>
              </div>
            </div>}
            {!endTimeTBA && <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment(endTimeUnix).utc().format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={idoTimes.end * 1000} renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>}
          </div> }
          {hasEnded() && <div className={styles.titleBoxLeft}>
            <span className={styles.saleStageWindowTitle}>WINDOW CURRENTLY CLOSED</span>
            <span className={styles.saleStageFundedDescription}>
              {`${nodesSold} NODES SOLD OF ${nodesForSale} NODES AVAILABLE`}
            </span>
          </div>}
        </div>
        {!hasEnded() && currentlyClosedTitle()}
        {hasEnded() && <div className={styles.saleCompletedContainer}>
          {userNodePurchased > 0 &&contributionRowContent()}
          {allocationEndedRowContent()}
        </div>}
      </div>
    )
  }

  const style = stage === currentStage || idoInfo.isNodeSale ? styles.idoSaleStageContainerActive : styles.idoSaleStageContainer;
  return (
    <div className={style}>
      {(hasEnded() || !hasStarted()) && saleEndedContent()}
      {hasStarted() && !hasEnded() && <div className={styles.saleEndContainer}>
        <div className={styles.titleRowOpen}>
          <div className={styles.titleBox}>
            <span className={styles.saleStageTitle}>{saleTitleAndText.title}</span>
            <span className={styles.saleStageDescription}>{saleTitleAndText.description}</span>
          </div>
          <div className={styles.saleStageOpenCountdownAndTitleCont}>
            <span className={styles.saleStageStatusTitle}>WINDOW CURRENTLY OPEN</span>
            <div className={styles.saleStageCountdownBox}>
              <div className={styles.saleStageCountdownTitleBox}>
                <span className={styles.saleStageCountdownTitle}>WINDOW CLOSES</span>
                <span
                  className={styles.saleStageCountdownTimestamp}>{moment(endTimeUnix).utc().format("MMMM Do YYYY, h:mm:ss A") + '  UTC'}</span>
              </div>
              <Countdown date={endTimeUnix.toDate()}  renderer={props => <div
                className={styles.saleStageCountdown}>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.days}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>DAYS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.hours}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>HOURS</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.minutes}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>MIN</span>
                </div>
                <div className={styles.countdownColumn}>
                  <div className={styles.idoCountdownBox}>
                    {props.seconds}
                  </div>
                  <span className={styles.salesCardCountdownPrefixText}>SEC</span>
                </div>
              </div>}/>
            </div>
          </div>
        </div>
        {!disablePurchaseAllocation.disabled && <IdoPurchaseAllocation
          stage={stage}
          merkelProof={merkelProof}
          currentStage={currentStage}
          poolData={poolData}
          participantInfo={participantInfo}
          allocationInfo={allocationInfo}
          walletBalance={walletBalance}
          setParticipantInfo={setParticipantInfo}
          setIsSendingTransaction={setIsSendingTransaction}
          provider={provider}
          idoInfo={idoInfo} />}
        {disablePurchaseAllocation.disabled && currentlyClosedTitle(disablePurchaseAllocation.reason)}

      </div>}
    </div>
  )
}

export default IdoNodeSaleStage;
