import React, {useCallback, useMemo, useState} from "react";
import styles from "./Icon.module.scss";

function Icon(props) {
    const {
        Icon,
        IconActive,
        className,
        classNameActive = null,
        onClick,
    } = props;

    const [hover, setHover] = useState(false);

    const _onClick = () => {
        if (onClick) {
            onClick();
        }
    }

    const renderIcon = useMemo(() => {
        if (hover) {
            return <IconActive className={classNameActive !== null ? classNameActive : className } />
        }

        return <Icon className={className} />;
    }, [hover, className, classNameActive])
   return (
       <span
           onClick={_onClick}
           onMouseEnter={() => setHover(true)}
           onMouseLeave={() => setHover(false)}>
            {renderIcon}
       </span>
   )
}

export default Icon;
