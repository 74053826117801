import React, {useEffect, useMemo, useState} from "react";

import checkmark from '../../images/checkmark.png'
import greenProgressbar from '../../images/green-progressbar.png'
import styles from "./IdoSalesCard.module.scss";
import BigNumber from "bignumber.js";
import moment from "moment/moment";
import {calculateBlockchain, convertUnixToLocalDate, getBlockchainLogo, hasValue} from "../../Helpers/helperFunctions";
import Countdown from "react-countdown";
import {
  getAllocationPerHydrazine, getIdoNodeTime,
  getIdoTime,
  getMaxContributedUSDC,
  getMaxHydrazine, getNodeSaleEndUnix,
  getNodeSaleStartEndUnix,
  getNodeSaleStartTimeUnix,
  getTotalContributedUSDC,
  getTotalHydrazineContributed,
  IDO_STAGE_COMPLETED,
  IDO_STAGE_FCFS_SALE,
  IDO_STAGE_HYDRAZINE_CONTRIBUTION,
  IDO_STAGE_USDC_CONTRIBUTION,
  isSaleStageOpen, SALE_TYPE_LAUNCH_VOTE, SALE_TYPE_NFT, SALE_TYPE_PRIVATE, SALE_TYPE_PUBLIC
} from "../../Helpers/idoHelper";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import {TBA_UNIX_DATE} from "../../constants/constants";
import Button, {BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../Helpers/toastCSS";
import {setCadetWallets} from "../../Components/Header/statistics";
import {getApiUrl} from "../../Helpers/helper";
import {useWallet} from "@solana/wallet-adapter-react";
import * as helpers from "../../Helpers/helper";
import TextInput from "../../Components/TextInput/TextInput";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";

const IdoSalesCard = (props) => {
  const {
    idoInfo,
    poolData,
    fetchContractData,
    currentStage,
  } = props;

  const wallet = useWallet();

  const [projectInterest, setProjectInterest] = useState();
  const [projectInterestAmount, setProjectInterestAmount] = useState(1);
  const [loadingInterest, setLoadingInterest] = useState(true);

  const totalHydrazineSpent =  getTotalHydrazineContributed(poolData?.poolInfo, idoInfo);
  const allocationPerHydrazine = getAllocationPerHydrazine(poolData?.poolInfo, idoInfo);
  const apiUrl = getApiUrl();

  const pricePerToken = Number(idoInfo?.swaprate) !== 0 ? `${new BigNumber(1).div(new BigNumber(idoInfo?.swaprate)).toFormat(4)} ${idoInfo?.pair}` : 0;

  const curTime = moment().unix();

  const saleEndNum = idoInfo.salesEndTime;

  const saleEndUnix = moment.unix(saleEndNum)

  const registrationStartNum = hasValue(poolData?.poolInfo?.idoTimes?.registrationStart) ?
    poolData?.poolInfo.idoTimes.registrationStart.toNumber() :
    idoInfo.registrationStartTime;

  const registrationStartUnix = moment.unix(registrationStartNum);
  const registrationStart = moment(registrationStartUnix).utc().format("MMMM Do YYYY, h:mm:ss A UTC");

  const saleStartNum = hasValue(poolData?.poolInfo.idoTimes?.saleStart) ?
    poolData?.poolInfo.idoTimes.saleStart.toNumber() :
    idoInfo.salesStartTime;

  const nodeSaleStartNum = getNodeSaleStartTimeUnix(idoInfo);
  const nodeSaleStartUnix = moment.unix(nodeSaleStartNum);
  const nodeSaleStart = moment(nodeSaleStartUnix).utc().format("MMMM Do YYYY, h:mm:ss A UTC");

  const nodeSaleEndNum = getNodeSaleEndUnix(idoInfo);
  const nodeSaleEndUnix = moment.unix(nodeSaleEndNum);

  const nodesForSale = hasValue(poolData?.poolInfo?.nodesForSale) ? Number(poolData.poolInfo?.nodesForSale.toString()) : 0;
  const nodesSold = hasValue(poolData?.poolInfo?.nodesSold) ? Number(poolData.poolInfo?.nodesSold.toString()) : 0;

  const nodesSoldOut = nodesForSale === nodesSold && nodesSold !== 0;

  const saleStartTBA =  TBA_UNIX_DATE > (idoInfo.isNodeSale ? nodeSaleStartNum : saleStartNum);
  const saleEndTBA = TBA_UNIX_DATE > (idoInfo.isNodeSale ? nodeSaleEndNum : saleEndNum);

  useEffect(() => {
    if (idoInfo.saleType?.toUpperCase() === SALE_TYPE_LAUNCH_VOTE && hasValue(wallet.publicKey)) {
      fetchProjectInterestInfo();
    }
  }, [idoInfo, wallet.publicKey]);

  const fetchProjectInterestInfo = async () => {
   try {
     const data = await fetch(helpers.getApiUrl() + '/cadetProjectInterest/' + wallet.publicKey.toString(), {
       method: "GET",
       headers: {
         "content-type": "application/json",
         "accept": "*",
         'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
       },
     });


     if (!(await data).ok) return []
     const result = await data.json();
     if (result.data.length === 0) return;
     const cadetInterests = result.data[0];
     const projName = idoInfo.name.replace(/\s/g,'');
     const project = cadetInterests.projects[projName];
     if (project && project.name) {
       setProjectInterest({ interest: project.interested, amount: project.amount })
     }
   } catch (e) {
     console.error(e)
     toast('Failed to load project interest: ' + e)
   } finally {
     setLoadingInterest(false);
   }
  }

  const hasSaleStarted = () => {
    if (saleStartTBA || saleEndTBA) {
      return false;
    }
    if (idoInfo?.isNodeSale) {
      return curTime > nodeSaleStartNum;
    }
    return curTime > registrationStartNum;
  }
  const hasSaleEnded = () => {
    if (saleStartTBA || saleEndTBA) {
      return false;
    }

    if (idoInfo.isNodeSale) {
      if (curTime > nodeSaleEndNum) {
        return true;
      }

      return nodesSoldOut;
    }

    if (curTime > idoInfo.openSaleEndTime) {

      return true;
    }

    return false;
  };

  const initialUnlock = () => {
    if (hasValue(idoInfo.initialUnlock)) {
      return idoInfo.initialUnlock;
    }

    return  poolData?.poolInfo ? poolData.poolInfo.unlocked.toString() + "%" : "";
  }

  const progressBarRender = () => {
    if (currentStage === IDO_STAGE_HYDRAZINE_CONTRIBUTION) {
      const totalContributedHydrazine = getTotalHydrazineContributed(poolData.poolInfo, idoInfo);
      const maxHydrazine = getMaxHydrazine(poolData.poolInfo);

      return (
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBarLabel}>{`${totalContributedHydrazine.toLocaleString()} HYDRAZINE CONTRIBUTED OF ${maxHydrazine.toLocaleString()} MAX`}</div>
          <ProgressBar percentage={(totalContributedHydrazine / maxHydrazine) * 100} className={styles.progressBar}/>
        </div>
      )
    }
    if (currentStage !== IDO_STAGE_USDC_CONTRIBUTION && currentStage !== IDO_STAGE_FCFS_SALE) {
      return;
    }

    const maxValue = getMaxContributedUSDC(poolData.poolInfo);
    const currentValue = getTotalContributedUSDC(poolData.poolInfo);

    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBarLabel}>{`${currentValue.toLocaleString()} USDC CONTRIBUTED OF ${maxValue.toLocaleString()} MAX`}</div>
      <ProgressBar percentage={(currentValue / maxValue) * 100} className={styles.progressBar}/>
    </div>);
  }

  const blockchain = calculateBlockchain(idoInfo);
  const blockchainLogo = getBlockchainLogo({blockchain});

  const saveProjectInterest = async (value) => {
    const resp = await fetch(apiUrl + '/cadetProjectInterest/createOrEdit', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        solanaWallet: wallet.publicKey.toString(),
        projectName: idoInfo.name,
        interest: value,
        amount: value === true ? projectInterestAmount : 0,
      }),
    });

    if (resp.ok) {
      toast('Interest Saved!', successToast);
      setProjectInterest({ interest: value, amount: projectInterestAmount})
    } else {
      toast('Error saving interest', errorToast);
    }
  }
  const informationRender = () => {
    const saleTimes = getIdoTime(currentStage, idoInfo);
    const saleStageEndTimeUnix = moment.unix(saleTimes.end);

    if (hasSaleEnded()) {
      return (
        <div className={styles.idoInformationContainer}>
          <div className={styles.orangeDivider}/>
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>TOTAL HYDRAZINE CONTRIBUTED</span>
            <span className={styles.idoInformationValue}>{totalHydrazineSpent.toLocaleString()}</span>
          </div>
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>ALLOCATION PER HYDRAZINE</span>
            <span
              className={styles.idoInformationValue}>{'$' + allocationPerHydrazine.toFixed(2).toLocaleString() + ' USDC'}</span>
          </div>
          {idoInfo.distribution && <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>DISTRIBUTION METHOD</span>
            <span
              className={styles.idoInformationValue}>{idoInfo.distribution}</span>
          </div>}
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>DISTRIBUTION CHAIN</span>
            <span
              className={styles.idoInformationValue}>{blockchain}</span>
          </div>
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>CONTRIBUTION CHAIN</span>
            <span
              className={styles.idoInformationValue}>SOLANA</span>
          </div>
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>SALE PARTICIPANT</span>
            <span
              className={styles.idoInformationValue}>{poolData?.poolInfo ? poolData?.poolInfo.totalParticipants.toString() : 0}</span>
          </div>
          <div className={styles.orangeDivider}/>
          <div className={styles.idoInformationRow}>
            <span className={styles.idoInformationLabel}>PRICE PER TOKEN</span>
            <span className={styles.idoInformationValue}>{pricePerToken}</span>
          </div>
          <div className={styles.idoInformationSaleClosed}>
            SALE CLOSED ON {saleEndUnix.format("MMMM Do YYYY")}
          </div>
        </div>
      )
    }
    return (
      <div className={styles.idoInformationContainer}>
        <div className={styles.orangeDivider}/>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>PRICE PER TOKEN</span>
          <span className={styles.idoInformationValue}>{pricePerToken}</span>
        </div>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>UNLOCK AT TGE</span>
          <span
            className={styles.idoInformationValue}>{initialUnlock()}</span>
        </div>
        <div className={styles.idoInformationRow}>
        <span className={styles.idoInformationLabel}>CATEGORY</span>
          <span
            className={styles.idoInformationValue}>{idoInfo.category}</span>
        </div>
        {idoInfo.distribution && <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>DISTRIBUTION METHOD</span>
          <span
            className={styles.idoInformationValue}>{idoInfo.distribution}</span>
        </div>}
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>DISTRIBUTION CHAIN</span>
          <span
            className={styles.idoInformationValue}>{blockchain}</span>
        </div>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>CONTRIBUTION CHAIN</span>
          <span
            className={styles.idoInformationValue}>SOLANA</span>
        </div>
        <div className={styles.orangeDivider}/>

        {hasSaleStarted() ?
          <div className={styles.idoInformationCurrentlySaleOpen}>
            <span className={styles.idoInformationSaleCurrentlyOpenLabel}>CURRENT STAGE</span>
            <span className={styles.idoInformationSaleCurrentlyOpenValue}>{currentStage}</span>
            {progressBarRender()}
            <div className={styles.idoSalesCountdownTimerRow}>
              <span className={styles.idoInformationLabel}>CLOSING IN</span>
              <Countdown date={saleStageEndTimeUnix.toDate()} onComplete={() => fetchContractData()}
                         renderer={props => <div
                           className={styles.idoCountdownContainer}>
                           <div className={styles.idoCountdownBox}>
                             {props.days}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.hours}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.minutes}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.seconds}
                           </div>
                         </div>}/>
            </div>
            <span
              className={styles.idoInformationSaleOpenTimestamp}>{moment.utc(saleStageEndTimeUnix).format("MMMM Do YYYY, h:mm:ss A UTC")}</span>
          </div>
          :
          <>
            {saleStartTBA && <div className={styles.idoInformationSaleOpen}>
              <span className={styles.idoInformationSaleOpenLabel}>SALE OPENING IN</span>

              <span className={styles.idoInformationSaleOpenTimeTBA}>TO BE ANNOUNCED</span>
            </div>}
            {!saleStartTBA && <div className={styles.idoInformationSaleOpen}>

              <span className={styles.idoInformationSaleOpenLabel}>SALE OPENING IN</span>
              <Countdown date={registrationStartNum * 1000} onComplete={() => fetchContractData()}
                         renderer={props => <div
                           className={styles.idoCountdownContainer}>
                           <div className={styles.idoCountdownBox}>
                             {props.days}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.hours}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.minutes}
                           </div>
                           <div className={styles.idoCountdownBox}>
                             {props.seconds}
                           </div>
                         </div>}/>
              <span className={styles.idoInformationSaleOpenTimestamp}>{registrationStart}</span>
            </div>}
          </>
        }
      </div>
    )
  }
  const nodeInformationRender = () => {
    const numberOfNodes = hasValue(poolData?.poolInfo?.nodesForSale) ? Number(poolData?.poolInfo?.nodesForSale.toString()) : idoInfo.nodeQuantity;

    const nodeTimes = getIdoNodeTime(idoInfo, currentStage);
    const nodeStageEndTimeUnix = moment.unix(nodeTimes.end);

    const suffixName = idoInfo.saleType?.toUpperCase() === SALE_TYPE_NFT ? 'NFT' : 'NODE';

    return (
      <div className={styles.idoInformationContainer}>
        <div className={styles.orangeDivider}/>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>TOTAL NUMBER OF {suffixName}</span>
          <span className={styles.idoInformationValue}>{numberOfNodes}</span>
        </div>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>DISTRIBUTION METHOD</span>
          <span
            className={styles.idoInformationValue}>{idoInfo.distribution}</span>
        </div>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>DISTRIBUTION CHAIN</span>
          <span
            className={styles.idoInformationValue}>{blockchain}</span>
        </div>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>CONTRIBUTION CHAIN</span>
          <span
            className={styles.idoInformationValue}>SOLANA</span>
        </div>
        <div className={styles.orangeDivider}/>
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>{suffixName} PRICE</span>
          <span
            className={styles.idoInformationValue}>{idoInfo.nodePrice}</span>
        </div>
        {hasSaleStarted() && wallet.publicKey && <div className={styles.progressBarContainer}>
          <div className={styles.progressBarLabel}>{`${nodesSold} ${suffixName} SOLD OF ${nodesForSale} AVAILABLE`}</div>
          <ProgressBar percentage={(nodesSold / nodesForSale) * 100} className={styles.progressBar} />
        </div>}
        {hasSaleStarted() && !wallet.publicKey && <div className={styles.idoInformationSaleOpen}>
          <span className={styles.idoInformationSaleOpenLabel}>CONNECT WALLET</span>
          <span className={styles.idoInformationSaleOpenTimeTBA}>FOR ADDITIONAL SALES INFORMATION</span>
        </div>}
        {!hasSaleEnded() && !hasSaleStarted() && !saleStartTBA && <div className={styles.idoInformationSaleOpen}>
          <span className={styles.idoInformationSaleOpenLabel}>SALE OPENING IN</span>
          {nodeSaleStartNum !== 0 && <Countdown date={Number(nodeSaleStartNum) * 1000 } onComplete={() => fetchContractData()} renderer={props => <div
            className={styles.idoCountdownContainer}>
            <div className={styles.idoCountdownBox}>
              {props.days}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.hours}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.minutes}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.seconds}
            </div>
          </div>}/>}
          <span className={styles.idoInformationSaleOpenTimestamp}>{nodeSaleStart}</span>
        </div>}
        {saleStartTBA && <div className={styles.idoInformationSaleOpen}>
          <span className={styles.idoInformationSaleOpenLabel}>SALE OPENING IN</span>
          <span className={styles.idoInformationSaleOpenTimeTBA}>TO BE ANNOUNCED</span>
        </div>}
        {!hasSaleEnded() && hasSaleStarted() && <div className={styles.idoInformationCurrentlySaleOpen}>
          <span className={styles.idoInformationSaleCurrentlyOpenLabel}>CURRENT STAGE</span>
          <span className={styles.idoInformationSaleCurrentlyOpenValue}>{currentStage}</span>
          <span className={styles.idoInformationSaleOpenLabel}>CLOSING IN</span>
          {nodeTimes.end !== 0 && <Countdown date={Number(nodeTimes.end) * 1000} onComplete={() => fetchContractData()} renderer={props => <div
            className={styles.idoCountdownContainer}>
            <div className={styles.idoCountdownBox}>
              {props.days}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.hours}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.minutes}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.seconds}
            </div>
          </div>}/>}
          <span className={styles.idoInformationSaleOpenTimestamp}>{moment.utc(nodeStageEndTimeUnix).format("MMMM Do YYYY, h:mm:SS A UTC")}</span>
        </div>}
        {hasSaleEnded() && !nodesSoldOut && <div className={styles.idoInformationSaleClosed}>
          SALE CLOSED ON {moment(nodeSaleEndUnix).utc().format("MMMM Do YYYY")}
        </div>}
        {hasSaleEnded() && nodesSoldOut && <div className={styles.idoInformationSaleClosed}>
          SALE CLOSED, ALL {suffixName} HAVE BEEN SOLD
        </div>}
      </div>
    )
  }

  const launchVoteInformationRender = () => {
    const numberOfNodes = hasValue(poolData?.poolInfo?.nodesForSale) ? Number(poolData?.poolInfo?.nodesForSale.toString()) : idoInfo.nodeQuantity;

    const nodeTimes = getIdoNodeTime(idoInfo, currentStage);

    const nodeStageEndTimeUnix = moment.unix(nodeTimes.end);

    return (
      <div className={styles.idoInformationContainer}>
        <div className={styles.orangeDivider}/>
        {hasValue(numberOfNodes) &&  <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>TOTAL NUMBER OF NODES</span>
          <span className={styles.idoInformationValue}>{numberOfNodes}</span>
        </div>}
        {hasValue(blockchain) &&  <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>DISTRIBUTION CHAIN</span>
          <span
            className={styles.idoInformationValue}>{blockchain}</span>
        </div>}
        <div className={styles.idoInformationRow}>
          <span className={styles.idoInformationLabel}>NODE PRICE</span>
          <span
            className={styles.idoInformationValue}>{idoInfo.nodePrice}</span>
        </div>
        <div className={styles.orangeDivider}/>
        <span className={styles.idoInformationLaunchVoteLabel}>WOULD YOU BE INTERESTED IN THIS NODE SALE?</span>
        {hasValue(wallet.publicKey) ? <>
        {loadingInterest && <LoadingSpinner local />}
        {projectInterest?.interest === true && !loadingInterest && <span className={styles.idoInformationLaunchVoteResultTextYES}>You have submitted YES for {projectInterest.amount} {idoInfo.isNodeSale ? ' NODES' : ' TOKENS'}</span>}
        {projectInterest?.interest === false && !loadingInterest && <span className={styles.idoInformationLaunchVoteResultTextNO}>You have submitted NO</span>}
        {!projectInterest && !loadingInterest && <div className={styles.idoInformationLaunchVoteButtonRow}>
          <TextInput
            overrideContainerClassName={styles.projectInterestAmountInput}
            label={'Amount'}
            type={'number'}
            value={projectInterestAmount}
            setValue={setProjectInterestAmount} />
          <Button text="YES" buttonType={BUTTON_TYPE_PRIMARY_BRIGHT} onClick={() => saveProjectInterest(true)} />
          <Button text="NO"  onClick={() => saveProjectInterest(false)} />
        </div>}
          </> : <>
          <span className={styles.idoInformationLaunchVoteLabelWallet}>PLEASE CONNECT YOUR WALLET TO VOTE</span>
          <div className='walletButton' >
            <WalletMultiButton/>
          </div>
        < />}
        {!hasSaleEnded() && !hasSaleStarted() && !saleStartTBA && <div className={styles.idoInformationSaleOpen}>
          <span className={styles.idoInformationSaleOpenLabel}>SALE START</span>
          <span className={styles.idoInformationSaleOpenTimestamp}>{nodeSaleStart}</span>
        </div>}
        {saleStartTBA && <div className={styles.idoInformationSaleOpen}>
          <span className={styles.idoInformationSaleOpenLabel}>SALE OPENING IN</span>

          <span className={styles.idoInformationSaleOpenTimeTBA}>TO BE ANNOUNCED</span>
        </div>}
        {!hasSaleEnded() && hasSaleStarted() && <div className={styles.idoInformationCurrentlySaleOpen}>
          <span className={styles.idoInformationSaleCurrentlyOpenLabel}>CURRENT STAGE</span>
          <span className={styles.idoInformationSaleCurrentlyOpenValue}>{currentStage}</span>
          <span className={styles.idoInformationSaleOpenLabel}>CLOSING IN</span>
          <Countdown date={Number(nodeTimes.end) * 1000} onComplete={() => fetchContractData()} renderer={props => <div
            className={styles.idoCountdownContainer}>
            <div className={styles.idoCountdownBox}>
              {props.days}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.hours}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.minutes}
            </div>
            <div className={styles.idoCountdownBox}>
              {props.seconds}
            </div>
          </div>}/>
          <span
            className={styles.idoInformationSaleOpenTimestamp}>{moment.utc(nodeStageEndTimeUnix).format("MMMM Do YYYY, h:mm:ss A UTC")}</span>
        </div>}
        {hasSaleEnded() && !nodesSoldOut && <div className={styles.idoInformationSaleClosed}>
          SALE CLOSED ON {moment(nodeSaleEndUnix).utc().format("MMMM Do YYYY")}
        </div>}
        {hasSaleEnded() && nodesSoldOut && <div className={styles.idoInformationSaleClosed}>
          SALE CLOSED, ALL NODES HAVE BEEN SOLD
        </div>}
      </div>
    )
  }

  const informationRenderResult = () => {
    if (idoInfo.saleType?.toUpperCase() === SALE_TYPE_LAUNCH_VOTE) {
      return launchVoteInformationRender();
    }

    if (idoInfo.isNodeSale) {
      return nodeInformationRender();
    }

    return informationRender();
  }

  const footerBannerRender = () => {
    if (idoInfo?.isVotedProject === true) {
      return (<div className={styles.launchVoteSaleBox}>COMMUNITY VOTED SALE</div>)
    }
    if (idoInfo.saleType?.toUpperCase() === SALE_TYPE_PUBLIC) {
      return (<div className={styles.publicSaleBox}>PUBLIC ROUND</div>)
    }
    if (idoInfo.saleType?.toUpperCase() === SALE_TYPE_PRIVATE) {
      return (<div className={styles.privateSaleBox}>PRIVATE ROUND</div>)
    }
    if (idoInfo.saleType?.toUpperCase() === SALE_TYPE_LAUNCH_VOTE) {
      return (<div className={styles.launchVoteSaleBox}>PROJECT LAUNCH VOTE</div>)
    }
  }

  return (
    <div className={styles.idoSalesCard}>
      <div className={styles.idoRaiseAmountRow}>
        {idoInfo.dollarAmount}
        <img src={blockchainLogo} className={styles.blockchainLogo}/>
      </div>
     {idoInfo.soldOut && <div className={styles.soldOutCard}>
        <img src={checkmark} alt="Checkmark" className={styles.checkmarkPic}/>
        SOLD OUT
      </div>}
      {informationRenderResult() }
      {footerBannerRender()}
    </div>
  )
}

export default IdoSalesCard;
