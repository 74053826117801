import React from "react";
import styles from "./Property.module.scss";
import InformationIcon from "../../images/svg/information2.svg"
import classNames from "classnames";
import {Tooltip} from "react-tooltip";

function Property(props) {
    const {
        value,
        title,
        Icon,
        helpTooltip,
        dataTip,
        className
    } = props;
 
    return (
    <div className={classNames(styles.propertyInnerContainer, className)}>
        {Icon != null && <Icon className={styles.valueIcon} />}
        <div className={styles.property}>
            <span className={styles.title}
                  >
                {title}
                {helpTooltip && 
                <InformationIcon
                  data-tooltip-id={title}
                    className={styles.informationIcon}
                     />}
                {helpTooltip && <Tooltip id={title}  >{helpTooltip}</Tooltip> }
            </span>
            <span className={styles.value}>
                {value}
            </span>                        
        </div>
    </div>
    );
}

export default Property;
